import {EventEmitter} from 'events'
import Dispatcher from '../dispatcher'
import Cookies from 'universal-cookie'
class SessionStore extends EventEmitter {
  constructor(props) {
    super(props)
    this.authenticated = false
    this.token = ''
    this.verifySession = this.verifySession.bind(this)
    this.doLogin = this.doLogin.bind(this)
    this.Cookies = new Cookies()
  }
  doLogin = (data) => {
    this.authenticated = true
    this.Cookies.set('token', data.token, {path: "/"})
    this.token = data.token
    this.emit('update')
  }
  doLogout = () => {
    this.authenticated = false
    this.Cookies.remove('token')
    // this.Cookies.remove('user')
    this.Cookies.remove('company')
    this.emit('update')
  }
  getToken = () => {
    const token = this.Cookies.get('token')
    return token
  }
  setToken = (token) => {
    this.Cookies.remove('token')
    this.Cookies.set('token', token, {path: "/"})
    this.token = token
  }
  verifySession = () => {
    const token = this.Cookies.get('token')
    if (token) {
      this.token = token
      this.authenticated = true
      this.emit('update')
    }
  }
  handleSession = (action) => {
    switch (action.type) {
      case 'LOGIN':
        this.doLogin(action.data)
        break
      case 'LOGOUT':
        this.doLogout()
        break
      default:
        return null
    }
  }
}
const Session = new SessionStore();
Dispatcher.register(Session.handleSession.bind(Session));
export default Session;
