import api from './api'


class Controller {
  async getAgencies() {
    try {
      const request = await api.request('get', '/admin/autocom/agencies/get/all')
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarBrands() {
    try {
      const request = await api.request('get', '/admin/autocom/car/brands/get/all')
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarBrandsClicar() {
    try {
      const request = await api.request('get', '/admin/autocom/car/brandsclicar/get/all')
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarModels(id) {
    try {
      const request = await api.request('get', '/admin/autocom/car/models/get/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarModelsClicar(id) {
    try {
      const request = await api.request('get', '/admin/autocom/car/models/getclicar/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarYears(id) {
    try {
      const request = await api.request('get', '/admin/autocom/car/years/get/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getCarYearsClicar(id) {
    try {
      const request = await api.request('get', '/admin/autocom/car/years/getclicar/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async getVimData(data) {
    try {
      const request = await api.request('post', '/admin/valuations/vimData',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfValuacionDinamico(data) {
    try {
      const request = await api.requestPdf('post', '/admin/valuations/pdfTest',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfCreateFromValuation(id) {
    try {
      const request = await api.requestPdf('get', '/pdf-create-from-valuation/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfCreateFromIntervention(id) {
    try {
      const request = await api.requestPdf('get', '/pdf-create-from-intervention/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async pdfContratoCompraVenta(data) {
    try {
      const request = await api.request('post', '/admin/valuations/pdfContratoCompraVenta',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfSolicitudExpedicionCFDI(data) {
    try {
      const request = await api.request('post', '/admin/valuations/pdfSolicitudExpedicionCFDI',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfMandato(data) {
    try {
      const request = await api.request('post', '/admin/valuations/pdfMandato',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async pdfNoRetencionIsr(data) {
    try {
      const request = await api.request('post', '/admin/valuations/pdfNoRetencionIsr',data )
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  
}
const AutocomController = (new Controller())
export default AutocomController
