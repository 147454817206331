import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function avaluoFiles ({ images, bdeo, category, handlePictureCategory, handleBdeoPictureCategory}) {
  const FormImageCategory = formImageCategory

  return (
    <>
      {/* Se mapean imagenes proveniendes de la BD */}
      {images ? images.map((item, key) => <FormImageCategory
        category={category}
        handlePicture={handlePictureCategory}
        item={item}
        image={item.uri}
        key={key}
        uuid={key}
      />
      ) : null}
      {/* Se mapean imagenes proveniendes del servicio de BDO */}
      {bdeo.images ? bdeo.images.map((item, key) => {
        if (images) {
          if (images.filter((existing) => parseInt(existing.bdeo_id) === parseInt(item.id)).length > 0) {
            return null
          }
        }
        return <FormImageCategory
          category={category}
          handlePicture={handleBdeoPictureCategory}
          item={item}
          image={item.image}
          key={key}
          uuid={key}
        />
      }) : null}
    </>
  )
}

function formImageCategory({ handlePicture, category, image, item, uuid}) {
  return (
    <form id={'image-bdeo-' + uuid} className="flex pL-10 pR-10 mB-20" style={{ position: 'relative', minWidth: '33%', maxWidth: '33%' }}>
      <img alt={item.id} src={image} style={{ width: "100%", height: "auto" }} />
      <div className="form-row">
        <div className="select-wrapper">
          <select
            id={'image-bdeo-category-' + uuid}
            defaultValue={item.category}
            onChange={(e) => handlePicture(e, item)}
          >
            <option value="">Asignar categoría</option>
            <option value="Documentación">Documentación</option>
            <option value="Carrocería">Carrocería</option>
            {/* En caso de ser intervención de toma de daños mostrar opcion "Daños" en lugar de "Habitaculo" y "Mecánica" */}
            {
              category !== "tomadanos"
                ? <>
                  <option value="Habitáculo">Habitáculo</option>
                  <option value="Mecánica">Mecánica</option>
                </>
                : <option value="Daños">Daños</option>

            }
          </select>
          <FontAwesomeIcon icon="chevron-down" />
        </div>
      </div>
    </form>
  )
}
