import api from './api'
class Controller {
  async getById(id) {
    try {
      console.log("getById")
      const request = await api.request('get', '/admin/interventions/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async create(data) {
    try {
      const request = await api.request('post', '/admin/interventions/create', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async start(id) {
    try {
      const request = await api.request('post', '/admin/interventions/start', {intervention: id})
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async complete(id) {
    try {
      const request = await api.request('post', '/admin/interventions/complete', {id})
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async close(id) {
    try {
      const request = await api.request('post', '/admin/interventions/close', {intervention: id})
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async updateForm(data) {
    try {
      const request = await api.request('post', '/admin/interventions/form/update', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async updateResult(data) {
    try {
      const request = await api.request('post', '/admin/interventions/result/update', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async addImage(data) {
    try {
      const request = await api.request('post', '/admin/interventions/images/add', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async updateImage(data) {
    try {
      const request = await api.request('post', '/admin/interventions/images/update', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async getImages(id) {
    try {
      const request = await api.request('get', '/admin/interventions/images/get/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async addFile(id, data) {
    try {
      const request = await api.request('post', '/admin/interventions/add/file/' + id, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async commentFile(data) {
    try {
      const request = await api.request('post', '/admin/interventions/comment/file', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async dropFile(id) {
    try {
      const request = await api.request('get', '/admin/interventions/drop/file/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async addProduct(data) {
    try {
      const request = await api.request('post', '/admin/interventions/add/product', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async approveProduct(id) {
    try {
      const request = await api.request('get', '/admin/interventions/product/approve/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async disapproveProduct(id) {
    try {
      const request = await api.request('get', '/admin/interventions/product/disapprove/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async addProductFile(id, data) {
    try {
      const request = await api.request('post', '/admin/interventions/product/add/file/' + id, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async dropProduct(id) {
    try {
      const request = await api.request('post', '/admin/interventions/drop/product', {id: id})
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async toggleSetting(id, setting, value) {
    try {
      const request = await api.request('post', '/admin/interventions/settings/toggle', {
        id: id.toString(), setting: setting, value: value
      })
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
const InterventionsController = (new Controller())
export default InterventionsController
