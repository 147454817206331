import React, { useState, useEffect } from 'react'
import Grader, { GraderHeader } from '../grader'

export default function ChecklistClicar({ intervention, settings, checklistData, handleChange }) {
  console.log("settings");
  console.log(settings);
  const data = Object.keys(checklistData).length
    ? { ...checklistData }
    : {
      valuation_car_airbags_front: 2,
      valuation_car_airbags_lat: 2,
      valuation_car_airbag_copiloto: 2,
      valuation_car_airbag_volante: 2,
      valuation_car_aire_acondicionado: 2,
      valuation_car_alzavidrios_elect: 2,
      valuation_car_asiento_copiloto: 2,
      valuation_car_cabina_general: 2,
      valuation_car_cierre_central: 2,
      valuation_car_cinturon_seguridad: 2,
      valuation_car_climatizador: 2,
      valuation_car_comandos: 2,
      valuation_car_consola_central: 2,
      valuation_car_control_crucero: 2,
      valuation_car_espejo_ret_int: 2,
      valuation_car_motor: 2,
      valuation_car_navegador_gps: 2,
      valuation_car_palanca_cambios: 2,
      valuation_car_parabrisas_ext: 2,
      valuation_car_piso_maleta: 2,
      valuation_car_radio_original: 2,
      valuation_car_rueda_respuesto: 2,
      valuation_car_sunroof: 2,
      valuation_car_tablero_completo: 2,
      valuation_car_tablero_encendido: 2,
      valuation_car_tapamaleta: 2,
      valuation_car_tapiz_asiento_tras_der: 2,
      valuation_car_tapiz_asiento_tras_izq: 2,
      valuation_car_tapiz_puerta_del_der: 2,
      valuation_car_tapiz_puerta_del_izq: 2,
      valuation_car_tapiz_puerta_tras_der: 2,
      valuation_car_tapiz_puerta_tras_izq: 2,
      valuation_car_tapiz_techo: 2,
      valuation_car_techo_pan: 2,
      valuation_car_vin: 2,
      valuation_car_volante_c: 2,
      valuation_car_capot: 2,
      valuation_car_carcaza_espejo_lat_der: 2,
      valuation_car_carcaza_espejo_lat_izq: 2,
      valuation_car_costado_tras_der: 2,
      valuation_car_costado_tras_izq: 2,
      valuation_car_foco_tras_der: 2,
      valuation_car_foco_tras_izq: 2,
      valuation_car_frontal_lat_der: 2,
      valuation_car_frontal_lat_izq: 2,
      valuation_car_llanta_del_der: 2,
      valuation_car_llanta_del_izq: 2,
      valuation_car_llanta_tras_der: 2,
      valuation_car_llanta_tras_izq: 2,
      valuation_car_llanta_espejo_lat_der: 2,
      valuation_car_llanta_espejo_lat_izq: 2,
      valuation_car_luneta: 2,
      valuation_car_mascara: 2,
      valuation_car_neblibero_der: 2,
      valuation_car_neblibero_izq: 2,
      valuation_car_optico_der: 2,
      valuation_car_optico_izq: 2,
      valuation_car_parabrisas_int: 2,
      valuation_car_parachoque_del: 2,
      valuation_car_parachoque_tras: 2,
      valuation_car_pisadera_izq: 2,
      valuation_car_pisadera_der: 2,
      valuation_car_portalon: 2,
      valuation_car_puerta_del_der: 2,
      valuation_car_puerta_del_izq: 2,
      valuation_car_puerta_tras_der: 2,
      valuation_car_puerta_tras_izq: 2,
      valuation_car_rejilla_parach_del: 2,
      valuation_car_taparrabo_del_der: 2,
      valuation_car_taparrabo_del_izq: 2,
      valuation_car_techo_ext: 2,
      valuation_car_trasera_lat_der: 2,
      valuation_car_trasera_lat_izq: 2,
      valuation_car_vidrio_aleta_tras_der: 2,
      valuation_car_vidrio_aleta_tras_izq: 2,
      valuation_car_vidrio_puerta_del_der: 2,
      valuation_car_vidrio_puerta_del_izq: 2,
      valuation_car_vidrio_puerta_tras_der: 2,
      valuation_car_vidrio_puerta_tras_izq: 2,
      valuation_car_zocalo_der: 2,
      valuation_car_zocalo_izq: 2,
      valuation_car_cedula: 2,
      valuation_car_factura: 2,
      valuation_car_manual_servicio: 2,
      valuation_car_manual_propietario: 2,
      valuation_car_permiso_circulacion: 2,
      valuation_car_revision_tecnica: 2,
      valuation_car_seguro_obligatorio: 2
    }
  const [checklist, setChecklist] = useState({ ...data })

  // useEffect(() => {
  //   console.log(checklistData)
  //   Object.keys(checklistData).length && handleChange({ id: 'checklistData', value: checklist })
  // }, [])

  useEffect(() => {
    handleChange({ id: 'checklistData', value: checklist })
  }, [checklist])

  const handleChecklistChange = ({ id, value }) => {
    setChecklist({ ...checklist, [id]: value })
  }

  const calcValue = (items, total, difValue) => {
    if (intervention) {
      if (intervention.status !== "Completada") {
        return null
      } else {
        var count = 0
        for (var i = 0; i < items.length; i++) {
          count = count + items[i]
        }
        if (difValue) {
          count = count - difValue
        }
        var result = count * (100 / total)
        return <span className="tag">{result.toFixed(2) + '%'}</span>
      }
    } else {
      return null
    }
  }
  return (
    <React.Fragment>
      {settings.documents ?
        <React.Fragment>
          <h4>Documentación: {calcValue([
            checklist.valuation_car_cedula,
            checklist.valuation_car_factura,
            checklist.valuation_car_manual_servicio,
            checklist.valuation_car_manual_propietario,
            checklist.valuation_car_permiso_circulacion,
            checklist.valuation_car_revision_tecnica,
            checklist.valuation_car_seguro_obligatorio], 14)}</h4>
          <div className="form-group">
            <div className="item">
              <GraderHeader label="I. Documentación" />
              <Grader
                id="valuation_car_cedula"
                label="Padrón"
                value={checklist.valuation_car_cedula}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_factura"
                label="Factura"
                value={checklist.valuation_car_factura}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_manual_servicio"
                label="Manual de servicio"
                value={checklist.valuation_car_manual_servicio}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_manual_propietario"
                label="Manual del propietario"
                value={checklist.valuation_car_manual_propietario}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_permiso_circulacion"
                label="Permiso de circulación"
                value={checklist.valuation_car_permiso_circulacion}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_revision_tecnica"
                label="Revisión técnica y homologación de gases"
                value={checklist.valuation_car_revision_tecnica}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_seguro_obligatorio"
                label="Seguro obligatorio de accidentes"
                value={checklist.valuation_car_seguro_obligatorio}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
            <div className="item"></div>
            <div className="item"></div>
          </div>
        </React.Fragment>
        : null}
      {settings.interior ?
        <>
          <h4>Interior: {calcValue([
            checklist.valuation_car_airbags_front,
            checklist.valuation_car_airbags_lat,
            checklist.valuation_car_airbag_copiloto,
            checklist.valuation_car_airbag_volante,
            checklist.valuation_car_aire_acondicionado,
            checklist.valuation_car_alzavidrios_elect,
            checklist.valuation_car_asiento_copiloto,
            checklist.valuation_car_cabina_general,
            checklist.valuation_car_cierre_central,
            checklist.valuation_car_cinturon_seguridad,
            checklist.valuation_car_climatizador,
            checklist.valuation_car_comandos,
            checklist.valuation_car_consola_central,
            checklist.valuation_car_control_crucero,
            checklist.valuation_car_espejo_ret_int,
            checklist.valuation_car_motor,
            checklist.valuation_car_navegador_gps,
            checklist.valuation_car_palanca_cambios,
            // checklist.valuation_car_parabrisas_int,
            checklist.valuation_car_piso_maleta,
            checklist.valuation_car_radio_original,
            checklist.valuation_car_rueda_respuesto,
            checklist.valuation_car_sunroof,
            checklist.valuation_car_tablero_completo,
            checklist.valuation_car_tablero_encendido,
            checklist.valuation_car_tapamaleta,
            checklist.valuation_car_tapiz_techo,
            checklist.valuation_car_techo_pan,
            checklist.valuation_car_vin,
            checklist.valuation_car_volante_c], 72)}</h4>
          <div className="form-group">
            <div className="item">
              <Grader
                id="valuation_car_airbags_front"
                label="Air-bags frontales"
                value={checklist.valuation_car_airbags_front}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_airbags_lat"
                label="Air-bags laterales"
                value={checklist.valuation_car_airbags_lat}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_airbag_copiloto"
                label="Air-bag copiloto"
                value={checklist.valuation_car_airbag_copiloto}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_airbag_volante"
                label="Air-bag volante"
                value={checklist.valuation_car_airbag_volante}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_aire_acondicionado"
                label="Aire acondicionado"
                value={checklist.valuation_car_aire_acondicionado}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_alzavidrios_elect"
                label="Alzavidrios eléctricos"
                value={checklist.valuation_car_alzavidrios_elect}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_asiento_copiloto"
                label="Asiento del cópiloto"
                value={checklist.valuation_car_asiento_copiloto}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_cabina_general"
                label="Cabina general"
                value={checklist.valuation_car_cabina_general}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_cierre_central"
                label="Cierre central"
                value={checklist.valuation_car_cierre_central}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_cinturon_seguridad"
                label="Cinturón de seguridad"
                value={checklist.valuation_car_cinturon_seguridad}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_climatizador"
                label="Climatizador"
                value={checklist.valuation_car_climatizador}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_comandos"
                label="Comandos"
                value={checklist.valuation_car_comandos}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
            <div className="item">
              <Grader
                id="valuation_car_consola_central"
                label="Consola central"
                value={checklist.valuation_car_consola_central}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_control_crucero"
                label="Control crucero"
                value={checklist.valuation_car_control_crucero}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_espejo_ret_int"
                label="Espejo retrovisor interior"
                value={checklist.valuation_car_espejo_ret_int}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_motor"
                label="Motor"
                value={checklist.valuation_car_motor}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_navegador_gps"
                label="Navegador GPS"
                value={checklist.valuation_car_navegador_gps}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_palanca_cambios"
                label="Palanca de cambios"
                value={checklist.valuation_car_palanca_cambios}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              {/* <Grader
                id="valuation_car_parabrisas_int"
                label="Parabrisas"
                value={checklist.valuation_car_parabrisas_int}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              /> */}
              <Grader
                id="valuation_car_piso_maleta"
                label="Piso maleta y panel trasero"
                value={checklist.valuation_car_piso_maleta}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_radio_original"
                label="Radio original"
                value={checklist.valuation_car_radio_original}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_rueda_respuesto"
                label="Rueda de repuesto"
                value={checklist.valuation_car_rueda_respuesto}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_sunroof"
                label="Sunroof"
                value={checklist.valuation_car_sunroof}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tablero_completo"
                label="Tablero completo"
                value={checklist.valuation_car_tablero_completo}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
            <div className="item">
              <Grader
                id="valuation_car_tablero_encendido"
                label="Tablero encendido"
                value={checklist.valuation_car_tablero_encendido}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapamaleta"
                label="Tapamaleta o portalon"
                value={checklist.valuation_car_tapamaleta}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapiz_techo"
                label="Tapiz techo"
                value={checklist.valuation_car_tapiz_techo}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_techo_pan"
                label="Techo panóramico"
                value={checklist.valuation_car_techo_pan}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vin"
                label="Vin"
                value={checklist.valuation_car_vin}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_volante_c"
                label="Volante"
                value={checklist.valuation_car_volante_c}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
          </div>
        </>
        :null}
      {settings.exterior ?
        <>
          <h4>Exterior: {calcValue([
            checklist.valuation_car_capot,
            checklist.valuation_car_carcaza_espejo_lat_der,
            checklist.valuation_car_carcaza_espejo_lat_izq,
            checklist.valuation_car_costado_tras_der,
            checklist.valuation_car_costado_tras_izq,
            checklist.valuation_car_foco_tras_der,
            checklist.valuation_car_foco_tras_izq,
            checklist.valuation_car_frontal_lat_der,
            checklist.valuation_car_frontal_lat_izq,
            checklist.valuation_car_llanta_del_der,
            checklist.valuation_car_llanta_del_izq,
            checklist.valuation_car_llanta_tras_der,
            checklist.valuation_car_llanta_tras_izq,
            checklist.valuation_car_llanta_espejo_lat_der,
            checklist.valuation_car_llanta_espejo_lat_izq,
            checklist.valuation_car_luneta,
            checklist.valuation_car_mascara,
            checklist.valuation_car_neblibero_der,
            checklist.valuation_car_neblibero_izq,
            checklist.valuation_car_optico_der,
            checklist.valuation_car_optico_izq,
            checklist.valuation_car_parabrisas_ext,
            checklist.valuation_car_parachoque_del,
            checklist.valuation_car_parachoque_tras,
            checklist.valuation_car_pisadera_izq,
            checklist.valuation_car_pisadera_der,
            checklist.valuation_car_portalon,
            checklist.valuation_car_puerta_del_der,
            checklist.valuation_car_puerta_del_izq,
            checklist.valuation_car_puerta_tras_der,
            checklist.valuation_car_puerta_tras_izq,
            checklist.valuation_car_rejilla_parach_del,
            checklist.valuation_car_tapiz_puerta_del_der,
            checklist.valuation_car_tapiz_puerta_del_izq,
            checklist.valuation_car_tapiz_puerta_tras_der,
            checklist.valuation_car_tapiz_puerta_tras_izq,
            checklist.valuation_car_taparrabo_del_der,
            checklist.valuation_car_taparrabo_del_izq,
            checklist.valuation_car_techo_ext,
            checklist.valuation_car_trasera_lat_der,
            checklist.valuation_car_trasera_lat_izq,
            checklist.valuation_car_vidrio_aleta_tras_der,
            checklist.valuation_car_vidrio_aleta_tras_izq,
            checklist.valuation_car_vidrio_puerta_del_der,
            checklist.valuation_car_vidrio_puerta_del_izq,
            checklist.valuation_car_vidrio_puerta_tras_der,
            checklist.valuation_car_vidrio_puerta_tras_izq,
            checklist.valuation_car_zocalo_der,
            checklist.valuation_car_zocalo_izq], 90)}</h4>
          <div className="form-group">
            <div className="item">
              <Grader
                id="valuation_car_capot"
                label="Capot"
                value={checklist.valuation_car_capot}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_carcaza_espejo_lat_der"
                label="Carcaza espejo lat. der."
                value={checklist.valuation_car_carcaza_espejo_lat_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_carcaza_espejo_lat_izq"
                label="Carcaza espejo lat. izq."
                value={checklist.valuation_car_carcaza_espejo_lat_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_costado_tras_der"
                label="Costado tras. der."
                value={checklist.valuation_car_costado_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_costado_tras_izq"
                label="Costado tras. izq."
                value={checklist.valuation_car_costado_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_foco_tras_der"
                label="Foco tras. der."
                value={checklist.valuation_car_foco_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_foco_tras_izq"
                label="Foco tras. izq."
                value={checklist.valuation_car_foco_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_frontal_lat_der"
                label="Frontal lateral der."
                value={checklist.valuation_car_frontal_lat_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_frontal_lat_izq"
                label="Frontal lateral izq."
                value={checklist.valuation_car_frontal_lat_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_del_der"
                label="Llanta del. der."
                value={checklist.valuation_car_llanta_del_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_del_izq"
                label="Llanta del. izq."
                value={checklist.valuation_car_llanta_del_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_tras_der"
                label="Llanta tras. der."
                value={checklist.valuation_car_llanta_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_tras_izq"
                label="Llanta tras. izq."
                value={checklist.valuation_car_llanta_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_espejo_lat_der"
                label="Llanta espejo lat. der."
                value={checklist.valuation_car_llanta_espejo_lat_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_llanta_espejo_lat_izq"
                label="Llanta espejo lat. izq."
                value={checklist.valuation_car_llanta_espejo_lat_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
            <div className="item">
              <Grader
                id="valuation_car_luneta"
                label="Luneta"
                value={checklist.valuation_car_luneta}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_mascara"
                label="Mascara"
                value={checklist.valuation_car_mascara}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_neblibero_der"
                label="Neblibero der."
                value={checklist.valuation_car_neblibero_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_neblibero_izq"
                label="Neblibero izq."
                value={checklist.valuation_car_neblibero_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_optico_der"
                label="Optico der."
                value={checklist.valuation_car_optico_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_optico_izq"
                label="Optico izq."
                value={checklist.valuation_car_optico_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_parabrisas_ext"
                label="Parabrisas"
                value={checklist.valuation_car_parabrisas_ext}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_parachoque_del"
                label="Parachoque del."
                value={checklist.valuation_car_parachoque_del}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_parachoque_tras"
                label="Parachoque tras."
                value={checklist.valuation_car_parachoque_tras}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_pisadera_izq"
                label="Pisadera izquierda"
                value={checklist.valuation_car_pisadera_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_pisadera_der"
                label="Pisadera derecha"
                value={checklist.valuation_car_pisadera_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_portalon"
                label="Portalón o tapamaleta"
                value={checklist.valuation_car_portalon}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_puerta_del_der"
                label="Puerta del. der."
                value={checklist.valuation_car_puerta_del_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_puerta_del_izq"
                label="Puerta del. izq."
                value={checklist.valuation_car_puerta_del_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_puerta_tras_der"
                label="Puerta tras. der."
                value={checklist.valuation_car_puerta_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
            <div className="item">
              <Grader
                id="valuation_car_puerta_tras_izq"
                label="Puerta tras. izq."
                value={checklist.valuation_car_puerta_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_rejilla_parach_del"
                label="Rejilla parach. del."
                value={checklist.valuation_car_rejilla_parach_del}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapiz_puerta_del_der"
                label="Tapiz puerta del. der."
                value={checklist.valuation_car_tapiz_puerta_del_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapiz_puerta_del_izq"
                label="Tapiz puerta del. izq."
                value={checklist.valuation_car_tapiz_puerta_del_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapiz_puerta_tras_der"
                label="Tapiz puerta tras. der."
                value={checklist.valuation_car_tapiz_puerta_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_tapiz_puerta_tras_izq"
                label="Tapiz puerta tras. izq."
                value={checklist.valuation_car_tapiz_puerta_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_taparrabo_del_der"
                label="Taparrabo del. der."
                value={checklist.valuation_car_taparrabo_del_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_taparrabo_del_izq"
                label="Taparrabo del. izq."
                value={checklist.valuation_car_taparrabo_del_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_techo_ext"
                label="Techo"
                value={checklist.valuation_car_techo_ext}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_trasera_lat_der"
                label="Trasera lat. der."
                value={checklist.valuation_car_trasera_lat_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_trasera_lat_izq"
                label="Trasera lat. izq."
                value={checklist.valuation_car_trasera_lat_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_aleta_tras_der"
                label="Vidrio aleta tras. der."
                value={checklist.valuation_car_vidrio_aleta_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_aleta_tras_izq"
                label="Vidrio aleta tras. izq."
                value={checklist.valuation_car_vidrio_aleta_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_puerta_del_der"
                label="Vidrio puerta del. der."
                value={checklist.valuation_car_vidrio_puerta_del_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_puerta_del_izq"
                label="Vidrio puerta del. izq."
                value={checklist.valuation_car_vidrio_puerta_del_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_puerta_tras_der"
                label="Vidrio puerta tras. der."
                value={checklist.valuation_car_vidrio_puerta_tras_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_vidrio_puerta_tras_izq"
                label="Vidrio puerta tras. izq."
                value={checklist.valuation_car_vidrio_puerta_tras_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_zocalo_der"
                label="Zócalo der."
                value={checklist.valuation_car_zocalo_der}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
              <Grader
                id="valuation_car_zocalo_izq"
                label="Zócalo izq."
                value={checklist.valuation_car_zocalo_izq}
                maxValue={2}
                onChange={(id, value) => handleChecklistChange({ id, value })}
              />
            </div>
          </div>
        </>
        : null}
    </React.Fragment>
  )
}