import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function CheckButton({action, className, active, label}) {
  return(
    <span onClick={action} className={'button ' + (className ? (className + ' ') : '') + (active ? 'active' : '')}>
      <FontAwesomeIcon icon={['far', 'circle']} />
      <FontAwesomeIcon className="checked" icon={['fas', 'check-circle']} />
      <span>{label}</span>
    </span>
  )
}
