import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default class CollapsibleHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      id: props.item.id,
      name: props.item.client.name,
      vehicle: props.item.vehicle,
      status: props.item.status_text
    }
    this.toggleIcon = this.toggleIcon.bind(this)
  }
  toggleIcon(){
    this.setState({ open: !this.state.open })
  }
  render() {
    return (
      <div className="Collapsible-table__header" onClick={this.toggleIcon}>
        <div className="mB-6 flexbox justify-space-between">
          <div>
            <small className="id">#{this.state.id}</small>
            <span className="name">{this.state.name}</span>
          </div>
          <FontAwesomeIcon icon={this.state.open ? 'chevron-up' : 'chevron-down'} />
        </div>
        <div className="flexbox justify-space-between align-center">
          <span className="vin">{this.state.vehicle ? this.state.vehicle.car_vin : <p className="tag">{'S/D'}</p>}</span>
          <small>{this.state.status}</small>
        </div>
      </div>
    )
  }
}
