import api from './api'
class Controller {
  async getAll(page, data) {
    try {
      const request = await api.request('post', '/admin/assignations/get/all?page=' + page, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/assignations/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async create(data) {
    try {
      const request = await api.request('post', '/admin/assignations/create', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async update(data) {
    try {
      const request = await api.request('post', '/admin/assignations/update', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async sendResponsiveCard(data, rfc) {
    console.log(data)
    try {
      const request = await api.request('post', '/admin/assignations/send/responsiveCard/' + rfc, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
const AssignationsController = (new Controller())
export default AssignationsController
