import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountStore from '../../../store/user';
import DestinantionsServiceController from '../../../services/destinations';
import { Loading, Empty } from '../../../components/splash';
export default class Destinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinations: null,
      // page: 1,
      // maxPages: 1,
      search: '',
      list: [],
      sortedKey: '',
      loading: false,
      redirect: false
    };
  }
  componentDidMount() {
    this.loadDestinations();
    AccountStore.on('update_company', () => this.setState({ redirect: true }));
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () =>
      this.setState({ redirect: true })
    );
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle('open');
  };
  loadDestinations = async () => {
    this.setState({ loading: true });
    try {
      const destinations = await DestinantionsServiceController.getAll();
      // console.log(destinations.result);
      const list = destinations.result.data ? destinations.result.data : [];
      this.setState({
        loading: false,
        // page: destinations.result.current_page,
        destinations: destinations.result.data,
        list: list
        // maxPages: destinations.result.last_page
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  // handlePrevPage = async () => {
  //   let { page, search } = this.state;
  //   if (page > 1) {
  //     if (search.length > 0) {
  //       await this.handleSearch(null, page - 1);
  //     } else {
  //       await this.loadUsers(page - 1);
  //     }
  //   }
  // };
  // handleNextPage = async () => {
  //   let { page, maxPages, search } = this.state;
  //   if (page < maxPages) {
  //     if (search.length > 0) {
  //       await this.handleSearch(null, page + 1);
  //     } else {
  //       await this.loadUsers(page + 1);
  //     }
  //   }
  // };
  handleSearch = async (e, page) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ searching: true });
    const { search } = this.state;
    try {
      if (search.length > 0) {
        const destinations = await DestinantionsServiceController.search(
          search
        );
        const list = destinations.result.data ? destinations.result.data : [];
        this.setState({
          list: list,
          searching: false
        });
      } else {
        this.setState({ searching: false });
        this.loadDestinations();
      }
    } catch (e) {
      this.setState({ searching: false, search: search });
    }
  };
  handleSort = (key) => {
    const { list, sortedKey } = this.state;
    if (list.length > 0) {
      let sorted = list.sort((a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
        if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
        return 0;
      });
      let newKey = key;
      if (sortedKey === key) {
        newKey = '';
        sorted = sorted.reverse();
      }
      this.setState({ list: sorted, sortedKey: newKey });
    } else {
      return;
    }
  };
  render() {
    const { redirect, list, loading, searching } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="scene contact">
        {!loading && list ? (
          <div className="container">
            <div className="content">
              <div className="header top">
                <button className="btn toggle" onClick={this.toggleSidebar}>
                  <FontAwesomeIcon icon="bars" />
                </button>
                <div className="title-w-search">
                  <form onSubmit={this.handleSearch} className="searchbar">
                    <input
                      id="search"
                      type="text"
                      placeholder="Buscar por ciudad o estado"
                      onChange={this.handleChange}
                    />
                    <button className="btn success" type="submit">
                      {searching ? (
                        <FontAwesomeIcon icon="spinner" spin />
                      ) : (
                        <FontAwesomeIcon icon="search" />
                      )}
                    </button>
                  </form>
                  <h2 className="uppercase title">Destinos</h2>
                </div>
              </div>
              {/* {list.length > 0 ? (
                <div className="pagination flexbox mB-20">
                  <div className="flex flexbox align-end">
                    {`Página ${page} de ${maxPages}`}
                  </div>
                  <div className="flex flexbox justify-end">
                    <button
                      className="btn"
                      disabled={page === 1}
                      onClick={this.handlePrevPage}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </button>
                    <button
                      className="btn"
                      disabled={
                        page === maxPages ||
                        maxPages === 1
                      }
                      onClick={this.handleNextPage}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </button>
                  </div>
                </div>
              ) : null} */}

              <table className="bordered">
                <thead>
                  <tr>
                    <th>
                      Ciudad
                      <button
                        className="btn sort"
                        onClick={() => this.handleSort('ciudad')}
                      >
                        <FontAwesomeIcon icon="sort" />
                      </button>
                    </th>
                    <th>
                      Estado
                      <button
                        className="btn sort"
                        onClick={() => this.handleSort('estado')}
                      >
                        <FontAwesomeIcon icon="sort" />
                      </button>
                    </th>
                    <th>
                      <Link to={'/destination/'} className="btn">
                        Agregar
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, key) => (
                    <tr key={key}>
                      <td>
                        <Link to={'/destination/' + item.id}>
                          {item.ciudad}
                        </Link>
                      </td>
                      <td>
                        <Link to={'/destination/' + item.id}>
                          {item.estado}
                        </Link>
                      </td>
                      <td className="action">
                        <Link
                          to={'/destination/' + item.id}
                          className="btn reference"
                        >
                          Ver más
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <Empty
            title="valuaciones"
            toggleSidebar={this.toggleSidebar}
            uri="/destination/"
            label="Agregar destino"
          />
        )}
      </div>
    );
  }
}
