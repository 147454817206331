import api from './api'
class Controller {
  async getAll(page, data) {
    try {
      const request = await api.request('post', '/admin/valuations/get/all?page=' + page, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async getById(id) {
    try {
      const request = await api.request('get', '/admin/valuations/get/id/' + id)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async addFile(id, data) {
    try {
      const request = await api.request('post', '/admin/valuations/add/file/' + id, data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async updateFile(data) {
    try {
      const request = await api.request('post', '/admin/valuations/update/file', data)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async dropFile(id) {
    try {
      const request = await api.request('get', '/admin/valuations/drop/file/' + id)
      return request.data
    } catch (error) {
      throw new Error(error)
    }
  }
  async create(data) {
    try {
      const request = await api.request('post', '/admin/valuations/create', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async update(data) {
    try {
      const request = await api.request('post', '/admin/valuations/update', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async assignResponsable(data) {
    try {
      const request = await api.request('post', '/admin/valuations/assign/responsable', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async verifyCar(data) {
    try {
      const request = await api.request('post', '/admin/valuations/car/verify', data)
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async toggleSetting(id, setting, value) {
    try {
      const request = await api.request('post', '/admin/valuations/settings/toggle', {
        id: id.toString(), setting: setting, value: value
      })
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  async changeStatus(id, status) {
    try {
      const request = await api.request('post', '/admin/valuations/status/change', {
        id: id.toString(), status
      })
      return request.data
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
const ValuationsController = (new Controller())
export default ValuationsController
