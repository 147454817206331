import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment-timezone'
import * as html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import Cleave from 'cleave.js/react'
import Player from 'react-player'
import AccountStore from '../../../store/user'
import AutocomController from '../../../services/autocom'
import Invarat from '../../../assets/images/logo.png'
import InterventionsController from '../../../services/selfInterventions'
import UsersController from '../../../services/users'
import Grader, {GraderHeader} from '../../../components/grader'
import {Loading} from '../../../components/splash'
export default class SelfIntervention extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      company: AccountStore.getCompany(),
      images: null,
      form_id: null,
      client: null,
      vehicle: null,
      intervention: null,
      intervention_offer: '',
      agents: null,
      saving: false,
      loading: false,
      redirect: false,
      agency_state: 'Aguascalientes',
      asignation_status: 'Asignado',
      agency_city: '',
      place: 'Agencia',
      client_address: '',
      client_postal_code: '',
      client_state: '',
      client_city: '',
      car_cilinders: '',
      car_plates: '',
      car_energy: '',
      car_invoice_date: '',
      valuation_docs_invoice: 6,
      valuation_docs_circulation_card: 4,
      valuation_docs_payments: 2,
      valuation_docs_verification: 2,
      valuation_docs_manual: 2,
      valuation_car_cofre: 4,
      valuation_car_defensa_delantera: 2,
      valuation_car_salpicadera_delantera_izquierda: 4,
      valuation_car_puerta_delantera_izquierda: 4,
      valuation_car_puerta_trasera_izquierda: 4,
      valuation_car_estribo_izquierdo: 4,
      valuation_car_costado_trasero_izquierdo: 4,
      valuation_car_cajuela_tapa: 2,
      valuation_car_defensa_trasera: 2,
      valuation_car_costado_trasero_derecha: 4,
      valuation_car_puerta_trasera_derecha: 4,
      valuation_car_puerta_delantera_derecha: 4,
      valuation_car_estribo_derecho: 4,
      valuation_car_salpicadera_delantera_derecha: 4,
      valuation_car_techo: 6,
      valuation_car_faro_delantero_derecho: 2,
      valuation_car_faro_antiniebla_derecho: 2,
      valuation_car_faro_delantero_izquierdo: 2,
      valuation_car_faro_antiniebla_izquierdo: 2,
      valuation_car_calavera_trasera_izquierda: 2,
      valuation_car_calavera_trasera_derecha: 2,
      valuation_car_stop_central_trasero: 2,
      valuation_car_intermitentes: 2,
      valuation_car_luz_reversa: 2,
      valuation_car_parabrisas: 6,
      valuation_car_cristal_delantero_izquierdo: 4,
      valuation_car_cristal_trasero_izquierdo: 4,
      valuation_car_medallon_trasero: 6,
      valuation_car_cristal_trasero_derecho: 4,
      valuation_car_cristal_delantero_derecho: 4,
      valuation_car_retrovisores: 4,
      valuation_car_neumatico_delantero_izquierdo: 2,
      valuation_car_neumatico_trasero_izquierdo: 2,
      valuation_car_neumatico_trasero_derecho: 2,
      valuation_car_neumatico_delatero_derecho: 2,
      valuation_car_neumatico_repuesto: 2,
      valuation_car_rin_delantero_izquierdo: 2,
      valuation_car_rin_trasero_izquierdo: 2,
      valuation_car_rin_trasero_derecho: 2,
      valuation_car_rin_delantero_derecho: 2,
      valuation_car_rin_repuesto: 2,
      valuation_car_tapa_vestidura_puerta_delantera_izquierda: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha: 2,
      valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha: 2,
      valuation_car_tapa_vestidura_cajuela: 6,
      valuation_car_vestidura_cielo: 4,
      valuation_car_alfombra: 6,
      valuation_car_asiento_delantero_izquierdo: 2,
      valuation_car_asiento_delantero_derecho: 2,
      valuation_car_asientos_traseros: 4,
      valuation_car_vestidura_asiento_delantero_izquierdo: 2,
      valuation_car_vestidura_asiento_delantero_derecho: 2,
      valuation_car_vestidura_asientos_traseros: 4,
      valuation_car_cierre_centralizado: 6,
      valuation_car_elevador_delantero_izquierdo: 6,
      valuation_car_elevador_trasero_izquierdo: 6,
      valuation_car_elevador_trasero_derecho: 6,
      valuation_car_elevador_delantero_derecho: 6,
      valuation_car_tablero: 6,
      valuation_car_volante: 6,
      valuation_car_consola: 4,
      valuation_car_guantera: 4,
      valuation_car_cenicero: 2,
      valuation_car_radio: 6,
      valuation_car_navegador: 6,
      valuation_car_aceite_motor_filtros: 6,
      valuation_car_soportes_motor: 6,
      valuation_car_bandas: 6,
      valuation_car_banda_distribucion: 6,
      valuation_car_testigos_averia: 6,
      valuation_car_fugas_motor: 6,
      valuation_car_turbo: 6,
      valuation_car_caja_cambios: 6,
      valuation_car_fugas_transmision: 6,
      valuation_car_soportes_transmision: 6,
      valuation_car_juntas_homocineticas: 6,
      valuation_car_fugas_bomba_bombin: 6,
      valuation_car_trepidacion: 6,
      valuation_car_ruido_collarin: 6,
      valuation_car_accionamiento_embrague: 6,
      valuation_car_fugas_aceite_retenes: 6,
      valuation_car_ruidos_internos: 6,
      valuation_car_holguras: 6,
      valuation_car_ruidos_bomba: 6,
      valuation_car_estanqueidad: 6,
      valuation_car_freno_nivel_liquido: 6,
      valuation_car_testigo_abs: 6,
      valuation_car_bomba_agua: 6,
      valuation_car_motor_ventilador: 6,
      valuation_car_testigo_regrigeracion: 6,
      valuation_car_liquido_refrigeracion: 6,
      valuation_car_funcionamiento_electrico: 6,
      valuation_car_alternador: 6,
      valuation_car_testigo_electrico: 6,
      valuation_car_radio_electrico: 6,
      valuation_car_motores_electricos: 6,
      valuation_car_funcionamiento_aire: 6,
      valuation_car_motor_ventilador_aire: 6,
      valuation_car_compresor: 6,
      valuation_car_testigo_airbag: 6,
      assignation_warranty: 4,
      assignation_circulation_card: 4,
      assignation_assistance: 4,
      assignation_insurance: 4,
      assignation_key: 4,
      assignation_control: 4,
      assignation_alarm: 4,
      assignation_cat: 4,
      assignation_tools: 4,
      assignation_triangles: 4,
      assignation_secondary_tires: 4,
      assignation_extintor: 4,
      bdeo: null,
      exporting: false,
      agencies: null,
      closing: false,
      valuationId: null
    }
    this.interventionForm = React.createRef()
    this.interventionMedia = React.createRef()
    this.interventionVideos = React.createRef()
    this.interventionComments = React.createRef()
    this.interventionResults = React.createRef()
    this.requestDocument = React.createRef()
    this.documentPage1 = React.createRef()
    this.documentPage2 = React.createRef()
    this.documentPage3 = React.createRef()
    this.documentPage4 = React.createRef()
    this.documentPage5 = React.createRef()
    this.documentPage6 = React.createRef()
  }
  componentDidMount() {
    const {params} = this.props.match
    this.loadValuation(params.id)
    AccountStore.on('update_company', () => this.setState({redirect: true}))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({redirect: true}))
  }
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }
  handleCleaveChange = (e) => {
    this.setState({[e.target.id]: e.target.rawValue})
  }
  toggleExpandible = (ref) => {
    ref.current.classList.toggle('active')
  }
  loadValuation = async (id) => {
    this.setState({loading: true})
    try {
      if (id) {
        console.log("loadValuation")
        //const request = await InterventionsController.getById(id)
        const request = await InterventionsController.getByIdAvatar(id) 
        
        const agents = await UsersController.getAgents()
        const valuation = request.result.valuation
        if (request.result.bdeo) {
          this.setState({bdeo: request.result.bdeo})
        }
        if (request.result.form) {
          const form = JSON.parse(request.result.form)
          this.setState({
            agency_state: form.agency_state,
            asignation_status: form.asignation_status ? form.asignation_status : 'Asignado',
            agency_city: form.agency_city,
            place: form.place,
            client_address: form.client_address,
            client_postal_code: form.client_postal_code,
            client_state: form.client_state,
            client_city: form.client_city,
            car_cilinders: form.car_cilinders,
            car_energy: form.car_energy,
            car_invoice_date: new Date(form.car_invoice_date),
            valuation_docs_invoice: form.valuation_docs_invoice,
            valuation_docs_circulation_card: form.valuation_docs_circulation_card,
            valuation_docs_payments: form.valuation_docs_payments,
            valuation_docs_verification: form.valuation_docs_verification,
            valuation_docs_manual: form.valuation_docs_manual,
            valuation_car_cofre: form.valuation_car_cofre,
            valuation_car_defensa_delantera: form.valuation_car_defensa_delantera,
            valuation_car_salpicadera_delantera_izquierda: form.valuation_car_salpicadera_delantera_izquierda,
            valuation_car_puerta_delantera_izquierda: form.valuation_car_puerta_delantera_izquierda,
            valuation_car_puerta_trasera_izquierda: form.valuation_car_puerta_trasera_izquierda,
            valuation_car_estribo_izquierdo: form.valuation_car_estribo_izquierdo,
            valuation_car_costado_trasero_izquierdo: form.valuation_car_costado_trasero_izquierdo,
            valuation_car_cajuela_tapa: form.valuation_car_cajuela_tapa,
            valuation_car_defensa_trasera: form.valuation_car_defensa_trasera,
            valuation_car_costado_trasero_derecha: form.valuation_car_costado_trasero_derecha,
            valuation_car_puerta_trasera_derecha: form.valuation_car_puerta_trasera_derecha,
            valuation_car_puerta_delantera_derecha: form.valuation_car_puerta_delantera_derecha,
            valuation_car_estribo_derecho: form.valuation_car_estribo_derecho,
            valuation_car_salpicadera_delantera_derecha: form.valuation_car_salpicadera_delantera_derecha,
            valuation_car_techo: form.valuation_car_techo,
            valuation_car_faro_delantero_derecho: form.valuation_car_faro_delantero_derecho,
            valuation_car_faro_antiniebla_derecho: form.valuation_car_faro_antiniebla_derecho,
            valuation_car_faro_delantero_izquierdo: form.valuation_car_faro_delantero_izquierdo,
            valuation_car_faro_antiniebla_izquierdo: form.valuation_car_faro_antiniebla_izquierdo,
            valuation_car_calavera_trasera_izquierda: form.valuation_car_calavera_trasera_izquierda,
            valuation_car_calavera_trasera_derecha: form.valuation_car_calavera_trasera_derecha,
            valuation_car_stop_central_trasero: form.valuation_car_stop_central_trasero,
            valuation_car_intermitentes: form.valuation_car_intermitentes,
            valuation_car_luz_reversa: form.valuation_car_luz_reversa,
            valuation_car_parabrisas: form.valuation_car_parabrisas,
            valuation_car_cristal_delantero_izquierdo: form.valuation_car_cristal_delantero_izquierdo,
            valuation_car_cristal_trasero_izquierdo: form.valuation_car_cristal_trasero_izquierdo,
            valuation_car_medallon_trasero: form.valuation_car_medallon_trasero,
            valuation_car_cristal_trasero_derecho: form.valuation_car_cristal_trasero_derecho,
            valuation_car_cristal_delantero_derecho: form.valuation_car_cristal_delantero_derecho,
            valuation_car_retrovisores: form.valuation_car_retrovisores,
            valuation_car_neumatico_delantero_izquierdo: form.valuation_car_neumatico_delantero_izquierdo,
            valuation_car_neumatico_trasero_izquierdo: form.valuation_car_neumatico_trasero_izquierdo,
            valuation_car_neumatico_trasero_derecho: form.valuation_car_neumatico_trasero_derecho,
            valuation_car_neumatico_delatero_derecho: form.valuation_car_neumatico_delatero_derecho,
            valuation_car_neumatico_repuesto: form.valuation_car_neumatico_repuesto,
            valuation_car_rin_delantero_izquierdo: form.valuation_car_rin_delantero_izquierdo,
            valuation_car_rin_trasero_izquierdo: form.valuation_car_rin_trasero_izquierdo,
            valuation_car_rin_trasero_derecho: form.valuation_car_rin_trasero_derecho,
            valuation_car_rin_delantero_derecho: form.valuation_car_rin_delantero_derecho,
            valuation_car_rin_repuesto: form.valuation_car_rin_repuesto,
            valuation_car_tapa_vestidura_puerta_delantera_izquierda: form.valuation_car_tapa_vestidura_puerta_delantera_izquierda,
            valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda: form.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
            valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha: form.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
            valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha: form.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
            valuation_car_tapa_vestidura_cajuela: form.valuation_car_tapa_vestidura_cajuela,
            valuation_car_vestidura_cielo: form.valuation_car_vestidura_cielo,
            valuation_car_alfombra: form.valuation_car_alfombra,
            valuation_car_asiento_delantero_izquierdo: form.valuation_car_asiento_delantero_izquierdo,
            valuation_car_asiento_delantero_derecho: form.valuation_car_asiento_delantero_derecho,
            valuation_car_asientos_traseros: form.valuation_car_asientos_traseros,
            valuation_car_vestidura_asiento_delantero_izquierdo: form.valuation_car_vestidura_asiento_delantero_izquierdo,
            valuation_car_vestidura_asiento_delantero_derecho: form.valuation_car_vestidura_asiento_delantero_derecho,
            valuation_car_vestidura_asientos_traseros: form.valuation_car_vestidura_asientos_traseros,
            valuation_car_cierre_centralizado: form.valuation_car_cierre_centralizado,
            valuation_car_elevador_delantero_izquierdo: form.valuation_car_elevador_delantero_izquierdo,
            valuation_car_elevador_trasero_izquierdo: form.valuation_car_elevador_trasero_izquierdo,
            valuation_car_elevador_trasero_derecho: form.valuation_car_elevador_trasero_derecho,
            valuation_car_elevador_delantero_derecho: form.valuation_car_elevador_delantero_derecho,
            valuation_car_tablero: form.valuation_car_tablero,
            valuation_car_volante: form.valuation_car_volante,
            valuation_car_consola: form.valuation_car_consola,
            valuation_car_guantera: form.valuation_car_guantera,
            valuation_car_cenicero: form.valuation_car_cenicero,
            valuation_car_radio: form.valuation_car_radio,
            valuation_car_navegador: form.valuation_car_navegador,
            valuation_car_aceite_motor_filtros: form.valuation_car_aceite_motor_filtros,
            valuation_car_soportes_motor: form.valuation_car_soportes_motor,
            valuation_car_bandas: form.valuation_car_bandas,
            valuation_car_banda_distribucion: form.valuation_car_banda_distribucion,
            valuation_car_testigos_averia: form.valuation_car_testigos_averia,
            valuation_car_fugas_motor: form.valuation_car_fugas_motor,
            valuation_car_turbo: form.valuation_car_turbo,
            valuation_car_caja_cambios: form.valuation_car_caja_cambios,
            valuation_car_fugas_transmision: form.valuation_car_fugas_transmision,
            valuation_car_soportes_transmision: form.valuation_car_soportes_transmision,
            valuation_car_juntas_homocineticas: form.valuation_car_juntas_homocineticas,
            valuation_car_fugas_bomba_bombin: form.valuation_car_fugas_bomba_bombin,
            valuation_car_trepidacion: form.valuation_car_trepidacion,
            valuation_car_ruido_collarin: form.valuation_car_ruido_collarin,
            valuation_car_accionamiento_embrague: form.valuation_car_accionamiento_embrague,
            valuation_car_fugas_aceite_retenes: form.valuation_car_fugas_aceite_retenes,
            valuation_car_ruidos_internos: form.valuation_car_ruidos_internos,
            valuation_car_holguras: form.valuation_car_holguras,
            valuation_car_ruidos_bomba: form.valuation_car_ruidos_bomba,
            valuation_car_estanqueidad: form.valuation_car_estanqueidad,
            valuation_car_freno_nivel_liquido: form.valuation_car_freno_nivel_liquido,
            valuation_car_testigo_abs: form.valuation_car_testigo_abs,
            valuation_car_bomba_agua: form.valuation_car_bomba_agua,
            valuation_car_motor_ventilador: form.valuation_car_motor_ventilador,
            valuation_car_testigo_regrigeracion: form.valuation_car_testigo_regrigeracion,
            valuation_car_liquido_refrigeracion: form.valuation_car_liquido_refrigeracion,
            valuation_car_funcionamiento_electrico: form.valuation_car_funcionamiento_electrico,
            valuation_car_alternador: form.valuation_car_alternador,
            valuation_car_testigo_electrico: form.valuation_car_testigo_electrico,
            valuation_car_radio_electrico: form.valuation_car_radio_electrico,
            valuation_car_motores_electricos: form.valuation_car_motores_electricos,
            valuation_car_funcionamiento_aire: form.valuation_car_funcionamiento_aire,
            valuation_car_motor_ventilador_aire: form.valuation_car_motor_ventilador_aire,
            valuation_car_compresor: form.valuation_car_compresor,
            valuation_car_testigo_airbag: form.valuation_car_testigo_airbag,
            assignation_warranty: form.assignation_warranty,
            assignation_circulation_card: form.assignation_circulation_card,
            assignation_assistance: form.assignation_assistance,
            assignation_insurance: form.assignation_insurance,
            assignation_key: form.assignation_key,
            assignation_control: form.assignation_control,
            assignation_alarm: form.assignation_alarm,
            assignation_cat: form.assignation_cat,
            assignation_tools: form.assignation_tools,
            assignation_triangles: form.assignation_triangles,
            assignation_secondary_tires: form.assignation_secondary_tires,
            assignation_extintor: form.assignation_extintor
          })
        }
        if (request.images) {
          this.setState({images: request.images})
        } else {
          this.setState({images: null})
        }
        console.log(request)
        this.setState({
          id: id,
          intervention_offer: request.result.offer ? request.result.offer : '',
          intervention: request.result,
          valuationId: valuation.id,
          employee: valuation.employee,
          vehicle: valuation.vehicle,
          car_plates: valuation.vehicle.car_plates,
          agents: agents.result
        })
      }
      const agencies = await AutocomController.getAgencies()
      this.setState({agencies:agencies.result, loading: false})
    } catch (e) {
      console.log(e)
      this.setState({loading: false})
    }
  }
  toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            console.log(reader.result);
          }
          reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
  }
  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({saving: true})
    try {
      const data = {...this.state}
      await InterventionsController.updateForm(data)
      alert("¡Listo! Se han guardado los cambios.")
      this.setState({saving: false})
      this.loadValuation(this.state.id)
    } catch (e) {
      this.setState({saving: false})
    }
  }
  handleResult = async (e) => {
    e.preventDefault()
    this.setState({saving: true})
    try {
      const data = { intervention: this.state.id, offer: this.state.intervention_offer }
      await InterventionsController.updateResult(data)
      alert("¡Listo! Se han guardado los cambios.")
      this.setState({saving: false})
      this.loadValuation(this.state.id)
    } catch (e) {
      this.setState({saving: false})
    }
  }
  handlePictureCategory = async (e, id) => {
    this.setState({saving: true})
    try {
      const data = { image: id, category: e.target.value }
      await InterventionsController.updateImage(data)
      await this.reloadImages(this.state.id)
      alert("¡Listo! Se han guardado los cambios.")
      this.setState({saving: false})
    } catch (e) {
      this.setState({saving: false})
    }
  }
  handleBdeoPictureCategory = async (e, item) => {
    this.setState({saving: true})
    try {
      const data = { intervention: this.state.id, image: item, category: e.target.value }
      await InterventionsController.addImage(data)
      await this.reloadImages(this.state.id)
      alert("¡Listo! Se han guardado los cambios.")
      this.setState({saving: false})
    } catch (e) {
      this.setState({saving: false})
    }
  }
  reloadImages = async (id) => {
    try {
      const request = await InterventionsController.getImages(id)
      this.setState({images: request.result})
    } catch (e) {
      console.log(e)
    }
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  handleExport = async () => {
    this.setState({exporting: true})
    try {
      let inputOne = this.documentPage1.current
      let inputTwo = this.documentPage2.current
      let inputThree = this.documentPage3.current
      var inputOneHeight = inputOne.clientHeight
      var inputOneWidth = inputOne.clientWidth
      var inputTwoHeight = inputTwo.clientHeight
      var inputTwoWidth = inputTwo.clientWidth
      var inputThreeHeight = inputThree.clientHeight
      var inputThreeWidth = inputThree.clientWidth
      const pdf = new jsPDF('p', 'px', 'a4')
      var documentWidth = pdf.internal.pageSize.getWidth()
      let pageOne = await html2canvas(inputOne)
      let pageTwo = await html2canvas(inputTwo)
      let pageThree = await html2canvas(inputThree)
      var newOneHeight = inputOneHeight / (inputOneWidth / (documentWidth - 65))
      var newTwoHeight = inputTwoHeight / (inputTwoWidth / (documentWidth - 65))
      var newThreeHeight = inputThreeHeight / (inputThreeWidth / (documentWidth - 65))
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageOne.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newOneHeight)
      pdf.addPage()
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageTwo.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newTwoHeight)
      pdf.addPage()
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(pageThree.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newThreeHeight)
      pdf.save(`intervencion_${this.state.id}_${moment().format('DD/MM/y')}.pdf`)
      this.setState({exporting: false})
    } catch (e) {
      console.log(e)
      this.setState({exporting: false})
    }
  }
  calcValue = (items, total) => {
    if (this.state.intervention) {
      if (this.state.intervention.status !== "Completada") {
        return null
      } else {
        var count = 0
        for (var i = 0; i < items.length; i++) {
          count = count + items[i]
        }
        var result = count * (100 / total)
        return <span className="tag">{result.toFixed(2) + '%'}</span>
      }
    } else {
      return null
    }
  }
  handleClose = async () => {
    this.setState({closing: true})
    try {
      await InterventionsController.close(this.state.id)
      this.setState({closing: false, redirect: true})
    } catch (e) {
      this.setState({closing: false})
    }
  }
  render() {
    const {
      id,
      images,
      intervention_offer,
      employee,
      vehicle,
      intervention,
      saving,
      loading,
      redirect,
      agency_state,
      asignation_status,
      agency_city,
      place,
      client_address,
      client_postal_code,
      client_state,
      client_city,
      car_cilinders,
      car_energy,
      car_invoice_date,
      valuation_docs_invoice,
      valuation_docs_circulation_card,
      valuation_docs_payments,
      valuation_docs_verification,
      valuation_docs_manual,
      valuation_car_cofre,
      valuation_car_defensa_delantera,
      valuation_car_salpicadera_delantera_izquierda,
      valuation_car_puerta_delantera_izquierda,
      valuation_car_puerta_trasera_izquierda,
      valuation_car_estribo_izquierdo,
      valuation_car_costado_trasero_izquierdo,
      valuation_car_cajuela_tapa,
      valuation_car_defensa_trasera,
      valuation_car_costado_trasero_derecha,
      valuation_car_puerta_trasera_derecha,
      valuation_car_puerta_delantera_derecha,
      valuation_car_estribo_derecho,
      valuation_car_salpicadera_delantera_derecha,
      valuation_car_techo,
      valuation_car_faro_delantero_derecho,
      valuation_car_faro_antiniebla_derecho,
      valuation_car_faro_delantero_izquierdo,
      valuation_car_faro_antiniebla_izquierdo,
      valuation_car_calavera_trasera_izquierda,
      valuation_car_calavera_trasera_derecha,
      valuation_car_stop_central_trasero,
      valuation_car_intermitentes,
      valuation_car_luz_reversa,
      valuation_car_parabrisas,
      valuation_car_cristal_delantero_izquierdo,
      valuation_car_cristal_trasero_izquierdo,
      valuation_car_medallon_trasero,
      valuation_car_cristal_trasero_derecho,
      valuation_car_cristal_delantero_derecho,
      valuation_car_retrovisores,
      valuation_car_neumatico_delantero_izquierdo,
      valuation_car_neumatico_trasero_izquierdo,
      valuation_car_neumatico_trasero_derecho,
      valuation_car_neumatico_delatero_derecho,
      valuation_car_neumatico_repuesto,
      valuation_car_rin_delantero_izquierdo,
      valuation_car_rin_trasero_izquierdo,
      valuation_car_rin_trasero_derecho,
      valuation_car_rin_delantero_derecho,
      valuation_car_rin_repuesto,
      valuation_car_tapa_vestidura_puerta_delantera_izquierda,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
      valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
      valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
      valuation_car_tapa_vestidura_cajuela,
      valuation_car_vestidura_cielo,
      valuation_car_alfombra,
      valuation_car_asiento_delantero_izquierdo,
      valuation_car_asiento_delantero_derecho,
      valuation_car_asientos_traseros,
      valuation_car_vestidura_asiento_delantero_izquierdo,
      valuation_car_vestidura_asiento_delantero_derecho,
      valuation_car_vestidura_asientos_traseros,
      valuation_car_cierre_centralizado,
      valuation_car_elevador_delantero_izquierdo,
      valuation_car_elevador_trasero_izquierdo,
      valuation_car_elevador_trasero_derecho,
      valuation_car_elevador_delantero_derecho,
      valuation_car_tablero,
      valuation_car_volante,
      valuation_car_consola,
      valuation_car_guantera,
      valuation_car_cenicero,
      valuation_car_radio,
      valuation_car_navegador,
      valuation_car_aceite_motor_filtros,
      valuation_car_soportes_motor,
      valuation_car_bandas,
      valuation_car_banda_distribucion,
      valuation_car_testigos_averia,
      valuation_car_fugas_motor,
      valuation_car_turbo,
      valuation_car_caja_cambios,
      valuation_car_fugas_transmision,
      valuation_car_soportes_transmision,
      valuation_car_juntas_homocineticas,
      valuation_car_fugas_bomba_bombin,
      valuation_car_trepidacion,
      valuation_car_ruido_collarin,
      valuation_car_accionamiento_embrague,
      valuation_car_fugas_aceite_retenes,
      valuation_car_ruidos_internos,
      valuation_car_holguras,
      valuation_car_ruidos_bomba,
      valuation_car_estanqueidad,
      valuation_car_freno_nivel_liquido,
      valuation_car_testigo_abs,
      valuation_car_bomba_agua,
      valuation_car_motor_ventilador,
      valuation_car_testigo_regrigeracion,
      valuation_car_liquido_refrigeracion,
      valuation_car_funcionamiento_electrico,
      valuation_car_alternador,
      valuation_car_testigo_electrico,
      valuation_car_radio_electrico,
      valuation_car_motores_electricos,
      valuation_car_funcionamiento_aire,
      valuation_car_motor_ventilador_aire,
      valuation_car_compresor,
      valuation_car_testigo_airbag,
      assignation_warranty,
      assignation_circulation_card,
      assignation_assistance,
      assignation_insurance,
      assignation_key,
      assignation_control,
      assignation_alarm,
      assignation_cat,
      assignation_tools,
      assignation_triangles,
      assignation_secondary_tires,
      assignation_extintor,
      bdeo,
      exporting,
      agencies,
      closing,
      company,
      valuationId
    } = this.state
    if (redirect) {
      return <Redirect to="/valuaciones" />
    }
    return (
      <div className={"scene valuation" + (id ? " existing" : "")}>
        {!loading ?
          <div className="container">
            {employee ?
              <>
                <div className="content" ref={this.document}>
                  <div className="header top">
                    <button className="btn toggle" onClick={this.toggleSidebar}>
                      <FontAwesomeIcon icon="bars" />
                    </button>
                    <Link to={valuationId ? `/${company === "AUTOCOM Pool" ? 'asignacion' : 'valuacion'}/${valuationId}` : '/valuaciones'} className="btn toggle">
                      <FontAwesomeIcon icon="chevron-left" />
                    </Link>
                    <h2 className="uppercase title">Revisión {id ? id : ""}</h2>
                  </div>
                  {intervention.status === "En curso" && intervention.bdeo_agent_url ?
                    <div className="flexbox">
                      <div className="flex">
                        <a href={intervention.bdeo_agent_url} target="_blank" rel="noopener noreferrer" className="btn reference large mB-15">Ingresar a la intervención</a>
                      </div>
                    </div>
                  : null}
                  <div className={"expandible"} ref={this.interventionForm}>
                    <div className="header">
                      <h3 className="uppercase title">Formato revisión</h3>
                      <div className="toggle">
                        <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionForm)}>
                          <span className="collapsed"><FontAwesomeIcon icon="edit" />Editar</span>
                          <span className="showing"><FontAwesomeIcon icon="times" />Cancelar</span>
                        </button>
                      </div>
                    </div>
                    <div className="body">
                      <form className="mB-20">
                        <h4>Datos administrativos</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Compañia</td>
                              <td className="even">Pool</td>
                              <td className="odd">Fecha de asignación</td>
                              <td className="even uppercase">{moment(new Date()).format("DD/MMM/YYYY")}</td>
                            </tr>
                            <tr>
                              <td className="odd">Agencia</td>
                              <td className="even">
                                <div className="select-wrapper">
                                  <select id="place" value={place} onChange={this.handleChange}>
                                    <option value="DOMICILIO">DOMICILIO</option>
                                    {agencies ? agencies.map((item, key) =>
                                      <option value={item.name} key={key}>{item.name}</option>
                                    ) : null}
                                  </select>
                                  <FontAwesomeIcon icon="chevron-down" />
                                </div>
                              </td>
                              <td className="odd">Estatus de la unidad</td>
                              <td className="even">
                                <div className="select-wrapper">
                                  <select id="asignation_status" value={asignation_status} onChange={this.handleChange}>
                                    <option value="Asignado">Asignado</option>
                                    <option value="Entregado">Entregado</option>
                                  </select>
                                  <FontAwesomeIcon icon="chevron-down" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="odd">Estado</td>
                              <td className="even">
                                <div className="select-wrapper">
                                  <select id="agency_state" value={agency_state} onChange={this.handleChange}>
                                    <option value="Aguascalientes">Aguascalientes</option>
                                    <option value="Baja California">Baja California</option>
                                    <option value="Baja California Sur">Baja California Sur</option>
                                    <option value="Campeche">Campeche</option>
                                    <option value="Chihuahua">Chihuahua</option>
                                    <option value="Chiapas">Chiapas</option>
                                    <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                                    <option value="Colima">Colima</option>
                                    <option value="Durango">Durango</option>
                                    <option value="Guanajuato">Guanajuato</option>
                                    <option value="Guerrero">Guerrero</option>
                                    <option value="Hidalgo">Hidalgo</option>
                                    <option value="Jalisco">Jalisco</option>
                                    <option value="México">México</option>
                                    <option value="Michoacán">Michoacán</option>
                                    <option value="Morelos">Morelos</option>
                                    <option value="Nayarit">Nayarit</option>
                                    <option value="Nuevo León">Nuevo León</option>
                                    <option value="Oaxaca">Oaxaca</option>
                                    <option value="Puebla">Puebla</option>
                                    <option value="Querétaro">Querétaro</option>
                                    <option value="Quintana Roo">Quintana Roo</option>
                                    <option value="San Luis Potosí">San Luis Potosí</option>
                                    <option value="Sinaloa">Sinaloa</option>
                                    <option value="Sonora">Sonora</option>
                                    <option value="Tabasco">Tabasco</option>
                                    <option value="Tamaulipas">Tamaulipas</option>
                                    <option value="Tlaxcala">Tlaxcala</option>
                                    <option value="Veracruz">Veracruz</option>
                                    <option value="Yucatán">Yucatán</option>
                                    <option value="Zacatecas">Zacatecas</option>
                                  </select>
                                  <FontAwesomeIcon icon="chevron-down" />
                                </div>
                              </td>
                              <td className="odd">Municipio</td>
                              <td className="even">
                                <input id="agency_city" placeholder="" onChange={this.handleChange} type="text" value={agency_city} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {company === "AUTOCOM Pool" ?
                          <React.Fragment>
                            <h4>Carrocería: {this.calcValue([
                                valuation_car_cofre,
                                valuation_car_defensa_delantera,
                                valuation_car_salpicadera_delantera_izquierda,
                                valuation_car_puerta_delantera_izquierda,
                                valuation_car_puerta_trasera_izquierda,
                                valuation_car_estribo_izquierdo,
                                valuation_car_costado_trasero_izquierdo,
                                valuation_car_cajuela_tapa,
                                valuation_car_defensa_trasera,
                                valuation_car_costado_trasero_derecha,
                                valuation_car_puerta_trasera_derecha,
                                valuation_car_puerta_delantera_derecha,
                                valuation_car_estribo_derecho,
                                valuation_car_salpicadera_delantera_derecha,
                                valuation_car_techo,
                                valuation_car_parabrisas,
                                valuation_car_cristal_delantero_izquierdo,
                                valuation_car_cristal_trasero_izquierdo,
                                valuation_car_medallon_trasero,
                                valuation_car_cristal_trasero_derecho,
                                valuation_car_cristal_delantero_derecho,
                                valuation_car_retrovisores,
                                valuation_car_neumatico_delantero_izquierdo,
                                valuation_car_neumatico_trasero_izquierdo,
                                valuation_car_neumatico_trasero_derecho,
                                valuation_car_neumatico_delatero_derecho,
                                valuation_car_neumatico_repuesto,
                                valuation_car_rin_delantero_izquierdo,
                                valuation_car_rin_trasero_izquierdo,
                                valuation_car_rin_trasero_derecho,
                                valuation_car_rin_delantero_derecho,
                                valuation_car_rin_repuesto], 108)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Carrocería exterior" />
                                <Grader label="Cofré/Capo" value={valuation_car_cofre} maxValue={4} id="valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa delantera" value={valuation_car_defensa_delantera} maxValue={2} id="valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera izquierda" value={valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera izquierda" value={valuation_car_puerta_delantera_izquierda} maxValue={4} id="valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera izquierda" value={valuation_car_puerta_trasera_izquierda} maxValue={4} id="valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo izquierdo" value={valuation_car_estribo_izquierdo} maxValue={4} id="valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero izquierdo" value={valuation_car_costado_trasero_izquierdo} maxValue={4} id="valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa de Cajuela" value={valuation_car_cajuela_tapa} maxValue={2} id="valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa trasera" value={valuation_car_defensa_trasera} maxValue={2} id="valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero derecho" value={valuation_car_costado_trasero_derecha} maxValue={4} id="valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera derecha" value={valuation_car_puerta_trasera_derecha} maxValue={4} id="valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera derecha" value={valuation_car_puerta_delantera_derecha} maxValue={4} id="valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo derecho" value={valuation_car_estribo_derecho} maxValue={4} id="valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera derecha" value={valuation_car_salpicadera_delantera_derecha} maxValue={4} id="valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Techo" value={valuation_car_techo} maxValue={6} id="valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Cristales" />
                                <Grader label="Parabrisas" value={valuation_car_parabrisas} maxValue={6} id="valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero izquierdo" value={valuation_car_cristal_delantero_izquierdo} maxValue={4} id="valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero izquierdo" value={valuation_car_cristal_trasero_izquierdo} maxValue={4} id="valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Medallón trasero" value={valuation_car_medallon_trasero} maxValue={6} id="valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero derecho" value={valuation_car_cristal_trasero_derecho} maxValue={4} id="valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero derecho" value={valuation_car_cristal_delantero_derecho} maxValue={4} id="valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Espejos o Retrovisores" value={valuation_car_retrovisores} maxValue={4} id="valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Llantas y neumáticos" />
                                <Grader label="Neumático delantero izquierdo" value={valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero izquierdo" value={valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero derecho" value={valuation_car_neumatico_trasero_derecho} maxValue={2} id="valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático delantero derecho" value={valuation_car_neumatico_delatero_derecho} maxValue={2} id="valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático de repuesto" value={valuation_car_neumatico_repuesto} maxValue={2} id="valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero izquierdo" value={valuation_car_rin_delantero_izquierdo} maxValue={2} id="valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero izquierdo" value={valuation_car_rin_trasero_izquierdo} maxValue={2} id="valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero derecho" value={valuation_car_rin_trasero_derecho} maxValue={2} id="valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero derecho" value={valuation_car_rin_delantero_derecho} maxValue={2} id="valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin de repuesto" value={valuation_car_rin_repuesto} maxValue={2} id="valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <h4>Habitáculo: {this.calcValue([
                                valuation_car_asiento_delantero_izquierdo,
                                valuation_car_asiento_delantero_derecho,
                                valuation_car_asientos_traseros,
                                valuation_car_vestidura_asiento_delantero_izquierdo,
                                valuation_car_vestidura_asiento_delantero_derecho,
                                valuation_car_vestidura_asientos_traseros,
                                valuation_car_tablero,
                                valuation_car_volante,
                                valuation_car_consola,
                                valuation_car_guantera,
                                valuation_car_cenicero,
                                valuation_car_radio,
                                valuation_car_navegador], 50)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Interiores asientos" />
                                <Grader label="Asiento delantero izquierdo" value={valuation_car_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asiento delantero derecho" value={valuation_car_asiento_delantero_derecho} maxValue={2} id="valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asientos traseros" value={valuation_car_asientos_traseros} maxValue={4} id="valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero izquierdo" value={valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero derecho" value={valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asientos traseros" value={valuation_car_vestidura_asientos_traseros} maxValue={4} id="valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Tablero y consola central" />
                                <Grader label="Tablero" value={valuation_car_tablero} maxValue={6} id="valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Volante" value={valuation_car_volante} maxValue={6} id="valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Consola central" value={valuation_car_consola} maxValue={4} id="valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Guantera" value={valuation_car_guantera} maxValue={4} id="valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cenicero" value={valuation_car_cenicero} maxValue={2} id="valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Radio" value={valuation_car_radio} maxValue={6} id="valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Navegador" value={valuation_car_navegador} maxValue={6} id="valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item" />
                            </div>
                            <h4>Mecánica: {this.calcValue([
                              valuation_car_funcionamiento_electrico,
                              valuation_car_alternador,
                              valuation_car_testigo_electrico,
                              valuation_car_radio_electrico,
                              valuation_car_motores_electricos], 30)}</h4>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                <Grader label="Comprobación funcionamiento general" value={valuation_car_funcionamiento_electrico} maxValue={6} id="valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control carga alternador" value={valuation_car_alternador} maxValue={6} id="valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de testigos" value={valuation_car_testigo_electrico} maxValue={6} id="valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento de radio" value={valuation_car_radio_electrico} maxValue={6} id="valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Motores eléctricos (elevadores y actuadores)" value={valuation_car_motores_electricos} maxValue={6} id="valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item" />
                              <div className="item" />
                            </div>
                            <h4>Documentos y otros: {this.calcValue([
                              assignation_warranty,
                              assignation_circulation_card,
                              assignation_assistance,
                              assignation_insurance,
                              assignation_key,
                              assignation_control,
                              assignation_alarm,
                              assignation_cat,
                              assignation_tools,
                              assignation_triangles,
                              assignation_secondary_tires,
                              assignation_extintor], 48)}</h4>
                            <div className="form-group">
                              <div className="item">
                                <Grader label="Póliza de Garantía" value={assignation_warranty} maxValue={4} id="assignation_warranty" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tarjeta de circulación" value={assignation_circulation_card} maxValue={4} id="assignation_circulation_card" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asistencia Vial" value={assignation_assistance} maxValue={4} id="assignation_assistance" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Póliza de Seguro" value={assignation_insurance} maxValue={4} id="assignation_insurance" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="LLave" value={assignation_key} maxValue={4} id="assignation_key" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control" value={assignation_control} maxValue={4} id="assignation_control" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Alarma" value={assignation_alarm} maxValue={4} id="assignation_alarm" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Gato" value={assignation_cat} maxValue={4} id="assignation_cat" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Herramienta" value={assignation_tools} maxValue={4} id="assignation_tools" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Triángulos" value={assignation_triangles} maxValue={4} id="assignation_triangles" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Llanta de refacción" value={assignation_secondary_tires} maxValue={4} id="assignation_secondary_tires" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Extintor" value={assignation_extintor} maxValue={4} id="assignation_extintor" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item" />
                              <div className="item" />
                            </div>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <h4>Documentación: {this.calcValue([
                                valuation_docs_invoice,
                                valuation_docs_circulation_card,
                                valuation_docs_payments,
                                valuation_docs_verification,
                                valuation_docs_manual], 16)}</h4>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="I. Documentación" />
                                <Grader label="Factura" value={valuation_docs_invoice} maxValue={6} id="valuation_docs_invoice" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tarjeta de circulación" value={valuation_docs_circulation_card} maxValue={4} id="valuation_docs_circulation_card" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Pagos de refrendo completos" value={valuation_docs_payments} maxValue={2} id="valuation_docs_payments" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Verificación vigente" value={valuation_docs_verification} maxValue={2} id="valuation_docs_verification" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Manual de servicio y mantenimiento" value={valuation_docs_manual} maxValue={2} id="valuation_docs_manual" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                            <h4>Carrocería: {this.calcValue([
                                valuation_car_cofre,
                                valuation_car_defensa_delantera,
                                valuation_car_salpicadera_delantera_izquierda,
                                valuation_car_puerta_delantera_izquierda,
                                valuation_car_puerta_trasera_izquierda,
                                valuation_car_estribo_izquierdo,
                                valuation_car_costado_trasero_izquierdo,
                                valuation_car_cajuela_tapa,
                                valuation_car_defensa_trasera,
                                valuation_car_costado_trasero_derecha,
                                valuation_car_puerta_trasera_derecha,
                                valuation_car_puerta_delantera_derecha,
                                valuation_car_estribo_derecho,
                                valuation_car_salpicadera_delantera_derecha,
                                valuation_car_techo,
                                valuation_car_faro_delantero_derecho,
                                valuation_car_faro_antiniebla_derecho,
                                valuation_car_faro_delantero_izquierdo,
                                valuation_car_faro_antiniebla_izquierdo,
                                valuation_car_calavera_trasera_izquierda,
                                valuation_car_calavera_trasera_derecha,
                                valuation_car_stop_central_trasero,
                                valuation_car_intermitentes,
                                valuation_car_luz_reversa,
                                valuation_car_parabrisas,
                                valuation_car_cristal_delantero_izquierdo,
                                valuation_car_cristal_trasero_izquierdo,
                                valuation_car_medallon_trasero,
                                valuation_car_cristal_trasero_derecho,
                                valuation_car_cristal_delantero_derecho,
                                valuation_car_retrovisores,
                                valuation_car_neumatico_delantero_izquierdo,
                                valuation_car_neumatico_trasero_izquierdo,
                                valuation_car_neumatico_trasero_derecho,
                                valuation_car_neumatico_delatero_derecho,
                                valuation_car_neumatico_repuesto,
                                valuation_car_rin_delantero_izquierdo,
                                valuation_car_rin_trasero_izquierdo,
                                valuation_car_rin_trasero_derecho,
                                valuation_car_rin_delantero_derecho,
                                valuation_car_rin_repuesto], 126)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Carrocería exterior" />
                                <Grader label="Cofré/Capo" value={valuation_car_cofre} maxValue={4} id="valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa delantera" value={valuation_car_defensa_delantera} maxValue={2} id="valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera izquierda" value={valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera izquierda" value={valuation_car_puerta_delantera_izquierda} maxValue={4} id="valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera izquierda" value={valuation_car_puerta_trasera_izquierda} maxValue={4} id="valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo izquierdo" value={valuation_car_estribo_izquierdo} maxValue={4} id="valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero izquierdo" value={valuation_car_costado_trasero_izquierdo} maxValue={4} id="valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa de Cajuela" value={valuation_car_cajuela_tapa} maxValue={2} id="valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa trasera" value={valuation_car_defensa_trasera} maxValue={2} id="valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero derecho" value={valuation_car_costado_trasero_derecha} maxValue={4} id="valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera derecha" value={valuation_car_puerta_trasera_derecha} maxValue={4} id="valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera derecha" value={valuation_car_puerta_delantera_derecha} maxValue={4} id="valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo derecho" value={valuation_car_estribo_derecho} maxValue={4} id="valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera derecha" value={valuation_car_salpicadera_delantera_derecha} maxValue={4} id="valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Techo" value={valuation_car_techo} maxValue={6} id="valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Iluminación y señalización" />
                                  <Grader label="Faro delantero derecho" value={valuation_car_faro_delantero_derecho} maxValue={2} id="valuation_car_faro_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro antiniebla derecho" value={valuation_car_faro_antiniebla_derecho} maxValue={2} id="valuation_car_faro_antiniebla_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro delantero izquierdo" value={valuation_car_faro_delantero_izquierdo} maxValue={2} id="valuation_car_faro_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro antiniebla izquierdo" value={valuation_car_faro_antiniebla_izquierdo} maxValue={2} id="valuation_car_faro_antiniebla_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Calavera trasera izquierda" value={valuation_car_calavera_trasera_izquierda} maxValue={2} id="valuation_car_calavera_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Calavera trasera derecha" value={valuation_car_calavera_trasera_derecha} maxValue={2} id="valuation_car_calavera_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Stop central trasero" value={valuation_car_stop_central_trasero} maxValue={2} id="valuation_car_stop_central_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Intermitentes/Direccionales" value={valuation_car_intermitentes} maxValue={2} id="valuation_car_intermitentes" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Luz de reversa" value={valuation_car_luz_reversa} maxValue={2} id="valuation_car_luz_reversa" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Cristales" />
                                <Grader label="Parabrisas" value={valuation_car_parabrisas} maxValue={6} id="valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero izquierdo" value={valuation_car_cristal_delantero_izquierdo} maxValue={4} id="valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero izquierdo" value={valuation_car_cristal_trasero_izquierdo} maxValue={4} id="valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Medallón trasero" value={valuation_car_medallon_trasero} maxValue={6} id="valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero derecho" value={valuation_car_cristal_trasero_derecho} maxValue={4} id="valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero derecho" value={valuation_car_cristal_delantero_derecho} maxValue={4} id="valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Espejos o Retrovisores" value={valuation_car_retrovisores} maxValue={4} id="valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="IV. Llantas y neumáticos" />
                                <Grader label="Neumático delantero izquierdo" value={valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero izquierdo" value={valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero derecho" value={valuation_car_neumatico_trasero_derecho} maxValue={2} id="valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático delantero derecho" value={valuation_car_neumatico_delatero_derecho} maxValue={2} id="valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático de repuesto" value={valuation_car_neumatico_repuesto} maxValue={2} id="valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero izquierdo" value={valuation_car_rin_delantero_izquierdo} maxValue={2} id="valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero izquierdo" value={valuation_car_rin_trasero_izquierdo} maxValue={2} id="valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero derecho" value={valuation_car_rin_trasero_derecho} maxValue={2} id="valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero derecho" value={valuation_car_rin_delantero_derecho} maxValue={2} id="valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin de repuesto" value={valuation_car_rin_repuesto} maxValue={2} id="valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                            <h4>Habitáculo: {this.calcValue([
                                valuation_car_tapa_vestidura_puerta_delantera_izquierda,
                                valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
                                valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
                                valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
                                valuation_car_tapa_vestidura_cajuela,
                                valuation_car_vestidura_cielo,
                                valuation_car_alfombra,
                                valuation_car_asiento_delantero_izquierdo,
                                valuation_car_asiento_delantero_derecho,
                                valuation_car_asientos_traseros,
                                valuation_car_vestidura_asiento_delantero_izquierdo,
                                valuation_car_vestidura_asiento_delantero_derecho,
                                valuation_car_vestidura_asientos_traseros,
                                valuation_car_cierre_centralizado,
                                valuation_car_elevador_delantero_izquierdo,
                                valuation_car_elevador_trasero_izquierdo,
                                valuation_car_elevador_trasero_derecho,
                                valuation_car_elevador_delantero_derecho,
                                valuation_car_tablero,
                                valuation_car_volante,
                                valuation_car_consola,
                                valuation_car_guantera,
                                valuation_car_cenicero,
                                valuation_car_radio,
                                valuation_car_navegador], 104)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Interiores puertas" />
                                <Grader label="Tapa vestidura puerta delantera izquierda" value={valuation_car_tapa_vestidura_puerta_delantera_izquierda} maxValue={2} id="valuation_car_tapa_vestidura_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta trasera izquierda" value={valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta trasera derecha" value={valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta delantera derecha" value={valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura cajuela" value={valuation_car_tapa_vestidura_cajuela} maxValue={6} id="valuation_car_tapa_vestidura_cajuela" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura o cielo de techo" value={valuation_car_vestidura_cielo} maxValue={4} id="valuation_car_vestidura_cielo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Alfombra" value={valuation_car_alfombra} maxValue={6} id="valuation_car_alfombra" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Interiores asientos" />
                                <Grader label="Asiento delantero izquierdo" value={valuation_car_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asiento delantero derecho" value={valuation_car_asiento_delantero_derecho} maxValue={2} id="valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asientos traseros" value={valuation_car_asientos_traseros} maxValue={4} id="valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero izquierdo" value={valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero derecho" value={valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asientos traseros" value={valuation_car_vestidura_asientos_traseros} maxValue={4} id="valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Cierre centralizado/elevadores" />
                                <Grader label="Cierre Centralizado" value={valuation_car_cierre_centralizado} maxValue={6} id="valuation_car_cierre_centralizado" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador delantero izquierdo" value={valuation_car_elevador_delantero_izquierdo} maxValue={6} id="valuation_car_elevador_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador trasero izquierdo" value={valuation_car_elevador_trasero_izquierdo} maxValue={6} id="valuation_car_elevador_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador trasero derecho" value={valuation_car_elevador_trasero_derecho} maxValue={6} id="valuation_car_elevador_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador delantero derecho" value={valuation_car_elevador_delantero_derecho} maxValue={6} id="valuation_car_elevador_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="IV. Tablero y consola central" />
                                <Grader label="Tablero" value={valuation_car_tablero} maxValue={6} id="valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Volante" value={valuation_car_volante} maxValue={6} id="valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Consola central" value={valuation_car_consola} maxValue={4} id="valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Guantera" value={valuation_car_guantera} maxValue={4} id="valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cenicero" value={valuation_car_cenicero} maxValue={2} id="valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Radio" value={valuation_car_radio} maxValue={6} id="valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Navegador" value={valuation_car_navegador} maxValue={6} id="valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                            <h4>Mecánica: {this.calcValue([
                                valuation_car_aceite_motor_filtros,
                                valuation_car_soportes_motor,
                                valuation_car_bandas,
                                valuation_car_banda_distribucion,
                                valuation_car_testigos_averia,
                                valuation_car_fugas_motor,
                                valuation_car_turbo,
                                valuation_car_caja_cambios,
                                valuation_car_fugas_transmision,
                                valuation_car_soportes_transmision,
                                valuation_car_juntas_homocineticas,
                                valuation_car_fugas_bomba_bombin,
                                valuation_car_trepidacion,
                                valuation_car_ruido_collarin,
                                valuation_car_accionamiento_embrague,
                                valuation_car_fugas_aceite_retenes,
                                valuation_car_ruidos_internos,
                                valuation_car_holguras,
                                valuation_car_ruidos_bomba,
                                valuation_car_estanqueidad,
                                valuation_car_freno_nivel_liquido,
                                valuation_car_testigo_abs,
                                valuation_car_bomba_agua,
                                valuation_car_motor_ventilador,
                                valuation_car_testigo_regrigeracion,
                                valuation_car_liquido_refrigeracion,
                                valuation_car_funcionamiento_electrico,
                                valuation_car_alternador,
                                valuation_car_testigo_electrico,
                                valuation_car_radio_electrico,
                                valuation_car_motores_electricos,
                                valuation_car_funcionamiento_aire,
                                valuation_car_motor_ventilador_aire,
                                valuation_car_compresor,
                                valuation_car_testigo_airbag], 210)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Motor y sistema de alimentación" />
                                <Grader label="Sustitución de aceite motor y filtros" value={valuation_car_aceite_motor_filtros} maxValue={6} id="valuation_car_aceite_motor_filtros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de soportes de motor" value={valuation_car_soportes_motor} maxValue={6} id="valuation_car_soportes_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión y estado de bandas" value={valuation_car_bandas} maxValue={6} id="valuation_car_bandas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Sustitución banda de distribución" value={valuation_car_banda_distribucion} maxValue={6} id="valuation_car_banda_distribucion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación testigos de avería" value={valuation_car_testigos_averia} maxValue={6} id="valuation_car_testigos_averia" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de fugas" value={valuation_car_fugas_motor} maxValue={6} id="valuation_car_fugas_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento turbo" value={valuation_car_turbo} maxValue={6} id="valuation_car_turbo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Caja de cambios y transmisión" />
                                <Grader label="Prueba de caja de cambios (selección de velocidades)" value={valuation_car_caja_cambios} maxValue={6} id="valuation_car_caja_cambios" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de fugas" value={valuation_car_fugas_transmision} maxValue={6} id="valuation_car_fugas_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de soportes de transmisión" value={valuation_car_soportes_transmision} maxValue={6} id="valuation_car_soportes_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de juntas homocinéticas" value={valuation_car_juntas_homocineticas} maxValue={6} id="valuation_car_juntas_homocineticas" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Embrague y diferencial" />
                                <Grader label="Comprobación de fugas en bomba y bombín" value={valuation_car_fugas_bomba_bombin} maxValue={6} id="valuation_car_fugas_bomba_bombin" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de trepidación" value={valuation_car_trepidacion} maxValue={6} id="valuation_car_trepidacion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobar ruido en collarín" value={valuation_car_ruido_collarin} maxValue={6} id="valuation_car_ruido_collarin" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control del accionamiento de embrague" value={valuation_car_accionamiento_embrague} maxValue={6} id="valuation_car_accionamiento_embrague" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control de fugas de aceite por retenes" value={valuation_car_fugas_aceite_retenes} maxValue={6} id="valuation_car_fugas_aceite_retenes" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación posibles ruidos internos" value={valuation_car_ruidos_internos} maxValue={6} id="valuation_car_ruidos_internos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="IV. Dirección y suspensión" />
                                <Grader label="Comprobación de holguras" value={valuation_car_holguras} maxValue={6} id="valuation_car_holguras" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de ruidos en bomba o servo" value={valuation_car_ruidos_bomba} maxValue={6} id="valuation_car_ruidos_bomba" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="V. ABS - Frenos" />
                                <Grader label="Comprobación de estanqueidad" value={valuation_car_estanqueidad} maxValue={6} id="valuation_car_estanqueidad" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control del estado y nivel de líquido" value={valuation_car_freno_nivel_liquido} maxValue={6} id="valuation_car_freno_nivel_liquido" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Testigo ABS" value={valuation_car_testigo_abs} maxValue={6} id="valuation_car_testigo_abs" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="VI. Refrigeración" />
                                <Grader label="Bomba de agua" value={valuation_car_bomba_agua} maxValue={6} id="valuation_car_bomba_agua" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de motor de ventilador (si aplica)" value={valuation_car_motor_ventilador} maxValue={6} id="valuation_car_motor_ventilador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control de funcionamiento de los testigos" value={valuation_car_testigo_regrigeracion} maxValue={6} id="valuation_car_testigo_regrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control y nivel del líquido" value={valuation_car_liquido_refrigeracion} maxValue={6} id="valuation_car_liquido_refrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                <Grader label="Comprobación funcionamiento general" value={valuation_car_funcionamiento_electrico} maxValue={6} id="valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control carga alternador" value={valuation_car_alternador} maxValue={6} id="valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de testigos" value={valuation_car_testigo_electrico} maxValue={6} id="valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento de radio" value={valuation_car_radio_electrico} maxValue={6} id="valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Motores eléctricos (elevadores y actuadores)" value={valuation_car_motores_electricos} maxValue={6} id="valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="VIII. Aire acondicionado" />
                                <Grader label="Comprobación de funcionamiento interno" value={valuation_car_funcionamiento_aire} maxValue={6} id="valuation_car_funcionamiento_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación encendido de motor de ventilador" value={valuation_car_motor_ventilador_aire} maxValue={6} id="valuation_car_motor_ventilador_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de compresor" value={valuation_car_compresor} maxValue={6} id="valuation_car_compresor" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="IX. Airbag" />
                                <Grader label="Testigos encendidos" value={valuation_car_testigo_airbag} maxValue={6} id="valuation_car_testigo_airbag" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                          </React.Fragment>
                        }
                        {intervention.status === "En curso" || intervention.status === "En curso" ?
                          <div className="flexbox">
                            <button className="btn success" onClick={this.handleSubmit} disabled={saving || closing}>{saving ? "Guardando..." : (intervention.status === "En curso" ? "Actualizar" : "Completar")}{!saving ? " revisión" : ""}</button>
                            {intervention.status === "En curso" ?
                              <button className="btn danger mL-10" onClick={this.handleClose} disabled={saving || closing}>{closing ? "Cerrando..." : "Cerrar avalúo"}</button>
                            : null}
                          </div>
                        : null}
                      </form>
                    </div>
                  </div>
                  {intervention.status !== "Pendiente" ?
                    <div className="expandible" ref={this.interventionMedia}>
                      <div className="header">
                        <h3 className="uppercase title">Fotos de la revisión</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionMedia)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {bdeo || images ?
                          <div className="flexbox f-wrap">
                            {images ? images.map((item, key) =>
                              <form id={'image-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '33%', maxWidth: '33%'}}>
                                <img alt={item.id} src={item.uri} style={{width: "100%", height: "auto"}} />
                                <div className="form-row">
                                  <div className="select-wrapper">
                                    <select id={'image-category-' + key} defaultValue={item.category} onChange={(e) => this.handlePictureCategory(e, item.id)}>
                                      <option value="Carrocería">Carrocería</option>
                                      <option value="Habitáculo">Habitáculo</option>
                                      <option value="Mecánica">Mecánica</option>
                                      <option value="Documentos y otros">Documentos y otros</option>
                                    </select>
                                    <FontAwesomeIcon icon="chevron-down" />
                                  </div>
                                </div>
                              </form>
                            ) : null}
                            {bdeo.images ? bdeo.images.map((item, key) => {
                              if (images) {
                                if (images.filter((existing) => existing.bdeo_id === item.id ).length > 0) {
                                  return null
                                }
                              }
                              return <form id={'image-bdeo-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '33%', maxWidth: '33%'}}>
                                <img alt={item.id} src={item.image} style={{width: "100%", height: "auto"}} />
                                <div className="form-row">
                                  <div className="select-wrapper">
                                    <select id={'image-bdeo-category-' + key} onChange={(e) => this.handleBdeoPictureCategory(e, item)}>
                                      <option value="">Asignar categoría</option>
                                      <option value="Carrocería">Carrocería</option>
                                      <option value="Habitáculo">Habitáculo</option>
                                      <option value="Mecánica">Mecánica</option>
                                      <option value="Documentos y otros">Documentos y otros</option>
                                    </select>
                                    <FontAwesomeIcon icon="chevron-down" />
                                  </div>
                                </div>
                              </form>
                            }) : null}
                          </div>
                        : null}
                      </div>
                    </div>
                  : null }
                  {bdeo ? (bdeo.videos ?
                    <div className="expandible" ref={this.interventionVideos}>
                      <div className="header">
                        <h3 className="uppercase title">Videos avalúo</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionVideos)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        <div className="flexbox f-wrap">
                          {bdeo.videos.map((item, key) => {
                            return <div id={'image-bdeo-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '50%', maxWidth: '50%'}}>
                              <Player playsInline url={item.video} width="100%" height="auto" controls />
                            </div>
                          })}
                        </div>
                      </div>
                    </div>
                  : null) : null}
                  {bdeo ? (bdeo.notes || bdeo.comments ?
                    <div className="expandible" ref={this.interventionComments}>
                      <div className="header">
                        <h3 className="uppercase title">Notas y comentarios</h3>
                        <div className="toggle">
                          <button className="btn reference" onClick={() => this.toggleExpandible(this.interventionComments)}>
                            <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                            <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                          </button>
                        </div>
                      </div>
                      <div className="body">
                        {bdeo.notes ?
                          <>
                            <h4>Notas:</h4>
                            <p>{bdeo.notes}</p>
                          </>
                        : null}
                        {bdeo.comments ?
                          <>
                            <h4>Comentarios:</h4>
                            <p>{bdeo.comments}</p>
                          </>
                        :null}
                      </div>
                    </div>
                  : null) : null}
                </div>
                {employee && intervention.offer ?
                  <div className="expandible" ref={this.requestDocument}>
                    <div className="header">
                      <h3 className="uppercase title">Exportar</h3>
                      <div className="toggle">
                        <button className="btn reference" onClick={() => this.toggleExpandible(this.requestDocument)}>
                          <span className="collapsed"><FontAwesomeIcon icon="eye" />Mostrar</span>
                          <span className="showing"><FontAwesomeIcon icon="times" />Ocultar</span>
                        </button>
                      </div>
                      <div className="toggle only-open">
                        <button className="btn success" onClick={this.handleExport} disabled={exporting}>{exporting ? "Generando..." : "Generar pdf"}</button>
                      </div>
                    </div>
                    <form className="body">
                      <div ref={this.documentPage1}>
                        <h2>Formato</h2>
                        <h4>Datos administrativos</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Compañia</td>
                              <td className="even">Seminuevos</td>
                              <td className="odd">Fecha de verificación</td>
                              <td className="even uppercase">{moment(new Date()).format("DD/MMM/YYYY")}</td>
                            </tr>
                            <tr>
                              <td className="odd">Lugar de verificación</td>
                              <td className="even">{place}</td>
                              <td className="odd"></td>
                              <td className="even"></td>
                            </tr>
                            <tr>
                              <td className="odd">Estado</td>
                              <td className="even">{agency_state}</td>
                              <td className="odd">Municipio</td>
                              <td className="even">{agency_city}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Datos del cliente</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Nombre</td>
                              <td className="even">{employee.name}</td>
                              <td className="odd"></td>
                              <td className="even"></td>
                            </tr>
                            <tr>
                              <td className="odd">Dirección</td>
                              <td className="even">{client_address}</td>
                              <td className="odd">C.P.</td>
                              <td className="even">{client_postal_code}</td>
                            </tr>
                            <tr>
                              <td className="odd">Estado</td>
                              <td className="even">{client_state}</td>
                              <td className="odd">Municipio</td>
                              <td className="even">{client_city}</td>
                            </tr>
                            <tr>
                              <td className="odd">Teléfono</td>
                              <td className="even">{employee.phone}</td>
                              <td className="odd">Móvil</td>
                              <td className="even">{employee.mobile}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h4>Datos del vehículo</h4>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Marca/Tipo</td>
                              <td className="even">{vehicle.car_brand} {vehicle.car_line}</td>
                              <td className="odd">Modelo</td>
                              <td className="even">{vehicle.car_model}</td>
                              <td className="odd">Cilindrada</td>
                              <td className="even">{car_cilinders}</td>
                            </tr>
                            <tr>
                              <td className="odd">Placas</td>
                              <td className="even">{vehicle.car_plates ? vehicle.car_plates : 'N/A'}</td>
                              <td className="odd">VIN</td>
                              <td className="even">{vehicle.car_vin}</td>
                              <td className="odd">Color de interiores</td>
                              <td className="even">{vehicle.car_exterior_color}</td>
                            </tr>
                            <tr>
                              <td className="odd">Kilometraje</td>
                              <td className="even">{vehicle.car_kilometers}</td>
                              <td className="odd">Combustible</td>
                              <td className="even">{car_energy}</td>
                              <td className="odd">Fecha de factura</td>
                              <td className="even">{moment(car_invoice_date).format("DD/MMM/YYYY")}</td>
                            </tr>
                          </tbody>
                        </table>
                        <h2>Resultados</h2>
                        {company === "AUTOCOM Pool" ?
                          <React.Fragment>
                            <h4>Carrocería: {this.calcValue([
                                valuation_car_cofre,
                                valuation_car_defensa_delantera,
                                valuation_car_salpicadera_delantera_izquierda,
                                valuation_car_puerta_delantera_izquierda,
                                valuation_car_puerta_trasera_izquierda,
                                valuation_car_estribo_izquierdo,
                                valuation_car_costado_trasero_izquierdo,
                                valuation_car_cajuela_tapa,
                                valuation_car_defensa_trasera,
                                valuation_car_costado_trasero_derecha,
                                valuation_car_puerta_trasera_derecha,
                                valuation_car_puerta_delantera_derecha,
                                valuation_car_estribo_derecho,
                                valuation_car_salpicadera_delantera_derecha,
                                valuation_car_techo,
                                valuation_car_parabrisas,
                                valuation_car_cristal_delantero_izquierdo,
                                valuation_car_cristal_trasero_izquierdo,
                                valuation_car_medallon_trasero,
                                valuation_car_cristal_trasero_derecho,
                                valuation_car_cristal_delantero_derecho,
                                valuation_car_retrovisores,
                                valuation_car_neumatico_delantero_izquierdo,
                                valuation_car_neumatico_trasero_izquierdo,
                                valuation_car_neumatico_trasero_derecho,
                                valuation_car_neumatico_delatero_derecho,
                                valuation_car_neumatico_repuesto,
                                valuation_car_rin_delantero_izquierdo,
                                valuation_car_rin_trasero_izquierdo,
                                valuation_car_rin_trasero_derecho,
                                valuation_car_rin_delantero_derecho,
                                valuation_car_rin_repuesto], 108)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Carrocería exterior" />
                                <Grader label="Cofré/Capo" value={valuation_car_cofre} maxValue={4} id="valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa delantera" value={valuation_car_defensa_delantera} maxValue={2} id="valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera izquierda" value={valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera izquierda" value={valuation_car_puerta_delantera_izquierda} maxValue={4} id="valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera izquierda" value={valuation_car_puerta_trasera_izquierda} maxValue={4} id="valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo izquierdo" value={valuation_car_estribo_izquierdo} maxValue={4} id="valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero izquierdo" value={valuation_car_costado_trasero_izquierdo} maxValue={4} id="valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa de Cajuela" value={valuation_car_cajuela_tapa} maxValue={2} id="valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa trasera" value={valuation_car_defensa_trasera} maxValue={2} id="valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero derecho" value={valuation_car_costado_trasero_derecha} maxValue={4} id="valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera derecha" value={valuation_car_puerta_trasera_derecha} maxValue={4} id="valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera derecha" value={valuation_car_puerta_delantera_derecha} maxValue={4} id="valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo derecho" value={valuation_car_estribo_derecho} maxValue={4} id="valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera derecha" value={valuation_car_salpicadera_delantera_derecha} maxValue={4} id="valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Techo" value={valuation_car_techo} maxValue={6} id="valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Cristales" />
                                <Grader label="Parabrisas" value={valuation_car_parabrisas} maxValue={6} id="valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero izquierdo" value={valuation_car_cristal_delantero_izquierdo} maxValue={4} id="valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero izquierdo" value={valuation_car_cristal_trasero_izquierdo} maxValue={4} id="valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Medallón trasero" value={valuation_car_medallon_trasero} maxValue={6} id="valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero derecho" value={valuation_car_cristal_trasero_derecho} maxValue={4} id="valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero derecho" value={valuation_car_cristal_delantero_derecho} maxValue={4} id="valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Espejos o Retrovisores" value={valuation_car_retrovisores} maxValue={4} id="valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Llantas y neumáticos" />
                                <Grader label="Neumático delantero izquierdo" value={valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero izquierdo" value={valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero derecho" value={valuation_car_neumatico_trasero_derecho} maxValue={2} id="valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático delantero derecho" value={valuation_car_neumatico_delatero_derecho} maxValue={2} id="valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático de repuesto" value={valuation_car_neumatico_repuesto} maxValue={2} id="valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero izquierdo" value={valuation_car_rin_delantero_izquierdo} maxValue={2} id="valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero izquierdo" value={valuation_car_rin_trasero_izquierdo} maxValue={2} id="valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero derecho" value={valuation_car_rin_trasero_derecho} maxValue={2} id="valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero derecho" value={valuation_car_rin_delantero_derecho} maxValue={2} id="valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin de repuesto" value={valuation_car_rin_repuesto} maxValue={2} id="valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <h4>Documentación: {this.calcValue([
                                valuation_docs_invoice,
                                valuation_docs_circulation_card,
                                valuation_docs_payments,
                                valuation_docs_verification,
                                valuation_docs_manual], 16)}</h4>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="I. Documentación" />
                                <Grader label="Factura" value={valuation_docs_invoice} maxValue={6} id="valuation_docs_invoice" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tarjeta de circulación" value={valuation_docs_circulation_card} maxValue={4} id="valuation_docs_circulation_card" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Pagos de refrendo completos" value={valuation_docs_payments} maxValue={2} id="valuation_docs_payments" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Verificación vigente" value={valuation_docs_verification} maxValue={2} id="valuation_docs_verification" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Manual de servicio y mantenimiento" value={valuation_docs_manual} maxValue={2} id="valuation_docs_manual" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                            <h4>Carrocería: {this.calcValue([
                                valuation_car_cofre,
                                valuation_car_defensa_delantera,
                                valuation_car_salpicadera_delantera_izquierda,
                                valuation_car_puerta_delantera_izquierda,
                                valuation_car_puerta_trasera_izquierda,
                                valuation_car_estribo_izquierdo,
                                valuation_car_costado_trasero_izquierdo,
                                valuation_car_cajuela_tapa,
                                valuation_car_defensa_trasera,
                                valuation_car_costado_trasero_derecha,
                                valuation_car_puerta_trasera_derecha,
                                valuation_car_puerta_delantera_derecha,
                                valuation_car_estribo_derecho,
                                valuation_car_salpicadera_delantera_derecha,
                                valuation_car_techo,
                                valuation_car_faro_delantero_derecho,
                                valuation_car_faro_antiniebla_derecho,
                                valuation_car_faro_delantero_izquierdo,
                                valuation_car_faro_antiniebla_izquierdo,
                                valuation_car_calavera_trasera_izquierda,
                                valuation_car_calavera_trasera_derecha,
                                valuation_car_stop_central_trasero,
                                valuation_car_intermitentes,
                                valuation_car_luz_reversa,
                                valuation_car_parabrisas,
                                valuation_car_cristal_delantero_izquierdo,
                                valuation_car_cristal_trasero_izquierdo,
                                valuation_car_medallon_trasero,
                                valuation_car_cristal_trasero_derecho,
                                valuation_car_cristal_delantero_derecho,
                                valuation_car_retrovisores,
                                valuation_car_neumatico_delantero_izquierdo,
                                valuation_car_neumatico_trasero_izquierdo,
                                valuation_car_neumatico_trasero_derecho,
                                valuation_car_neumatico_delatero_derecho,
                                valuation_car_neumatico_repuesto,
                                valuation_car_rin_delantero_izquierdo,
                                valuation_car_rin_trasero_izquierdo,
                                valuation_car_rin_trasero_derecho,
                                valuation_car_rin_delantero_derecho,
                                valuation_car_rin_repuesto], 126)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Carrocería exterior" />
                                <Grader label="Cofré/Capo" value={valuation_car_cofre} maxValue={4} id="valuation_car_cofre" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa delantera" value={valuation_car_defensa_delantera} maxValue={2} id="valuation_car_defensa_delantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera izquierda" value={valuation_car_salpicadera_delantera_izquierda} maxValue={4} id="valuation_car_salpicadera_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera izquierda" value={valuation_car_puerta_delantera_izquierda} maxValue={4} id="valuation_car_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera izquierda" value={valuation_car_puerta_trasera_izquierda} maxValue={4} id="valuation_car_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo izquierdo" value={valuation_car_estribo_izquierdo} maxValue={4} id="valuation_car_estribo_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero izquierdo" value={valuation_car_costado_trasero_izquierdo} maxValue={4} id="valuation_car_costado_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa de Cajuela" value={valuation_car_cajuela_tapa} maxValue={2} id="valuation_car_cajuela_tapa" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Fascia/Defensa trasera" value={valuation_car_defensa_trasera} maxValue={2} id="valuation_car_defensa_trasera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Costado trasero derecho" value={valuation_car_costado_trasero_derecha} maxValue={4} id="valuation_car_costado_trasero_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta trasera derecha" value={valuation_car_puerta_trasera_derecha} maxValue={4} id="valuation_car_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Puerta delantera derecha" value={valuation_car_puerta_delantera_derecha} maxValue={4} id="valuation_car_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Estribo derecho" value={valuation_car_estribo_derecho} maxValue={4} id="valuation_car_estribo_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Salpicadera/Aleta delantera derecha" value={valuation_car_salpicadera_delantera_derecha} maxValue={4} id="valuation_car_salpicadera_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Techo" value={valuation_car_techo} maxValue={6} id="valuation_car_techo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Iluminación y señalización" />
                                  <Grader label="Faro delantero derecho" value={valuation_car_faro_delantero_derecho} maxValue={2} id="valuation_car_faro_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro antiniebla derecho" value={valuation_car_faro_antiniebla_derecho} maxValue={2} id="valuation_car_faro_antiniebla_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro delantero izquierdo" value={valuation_car_faro_delantero_izquierdo} maxValue={2} id="valuation_car_faro_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Faro antiniebla izquierdo" value={valuation_car_faro_antiniebla_izquierdo} maxValue={2} id="valuation_car_faro_antiniebla_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Calavera trasera izquierda" value={valuation_car_calavera_trasera_izquierda} maxValue={2} id="valuation_car_calavera_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Calavera trasera derecha" value={valuation_car_calavera_trasera_derecha} maxValue={2} id="valuation_car_calavera_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Stop central trasero" value={valuation_car_stop_central_trasero} maxValue={2} id="valuation_car_stop_central_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Intermitentes/Direccionales" value={valuation_car_intermitentes} maxValue={2} id="valuation_car_intermitentes" onChange={(id, value) => this.setState({[id]: value})} />
                                  <Grader label="Luz de reversa" value={valuation_car_luz_reversa} maxValue={2} id="valuation_car_luz_reversa" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Cristales" />
                                <Grader label="Parabrisas" value={valuation_car_parabrisas} maxValue={6} id="valuation_car_parabrisas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero izquierdo" value={valuation_car_cristal_delantero_izquierdo} maxValue={4} id="valuation_car_cristal_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero izquierdo" value={valuation_car_cristal_trasero_izquierdo} maxValue={4} id="valuation_car_cristal_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Medallón trasero" value={valuation_car_medallon_trasero} maxValue={6} id="valuation_car_medallon_trasero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal trasero derecho" value={valuation_car_cristal_trasero_derecho} maxValue={4} id="valuation_car_cristal_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cristal delantero derecho" value={valuation_car_cristal_delantero_derecho} maxValue={4} id="valuation_car_cristal_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Espejos o Retrovisores" value={valuation_car_retrovisores} maxValue={4} id="valuation_car_retrovisores" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="IV. Llantas y neumáticos" />
                                <Grader label="Neumático delantero izquierdo" value={valuation_car_neumatico_delantero_izquierdo} maxValue={2} id="valuation_car_neumatico_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero izquierdo" value={valuation_car_neumatico_trasero_izquierdo} maxValue={2} id="valuation_car_neumatico_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático trasero derecho" value={valuation_car_neumatico_trasero_derecho} maxValue={2} id="valuation_car_neumatico_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático delantero derecho" value={valuation_car_neumatico_delatero_derecho} maxValue={2} id="valuation_car_neumatico_delatero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Neumático de repuesto" value={valuation_car_neumatico_repuesto} maxValue={2} id="valuation_car_neumatico_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero izquierdo" value={valuation_car_rin_delantero_izquierdo} maxValue={2} id="valuation_car_rin_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero izquierdo" value={valuation_car_rin_trasero_izquierdo} maxValue={2} id="valuation_car_rin_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin trasero derecho" value={valuation_car_rin_trasero_derecho} maxValue={2} id="valuation_car_rin_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin delantero derecho" value={valuation_car_rin_delantero_derecho} maxValue={2} id="valuation_car_rin_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Rin de repuesto" value={valuation_car_rin_repuesto} maxValue={2} id="valuation_car_rin_repuesto" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                          </React.Fragment>
                        }
                      </div>
                      <div ref={this.documentPage2}>
                        <h2>Resultados</h2>
                        {company === "AUTOCOM Pool" ?
                          <React.Fragment>
                            <h4>Habitáculo: {this.calcValue([
                                valuation_car_asiento_delantero_izquierdo,
                                valuation_car_asiento_delantero_derecho,
                                valuation_car_asientos_traseros,
                                valuation_car_vestidura_asiento_delantero_izquierdo,
                                valuation_car_vestidura_asiento_delantero_derecho,
                                valuation_car_vestidura_asientos_traseros,
                                valuation_car_tablero,
                                valuation_car_volante,
                                valuation_car_consola,
                                valuation_car_guantera,
                                valuation_car_cenicero,
                                valuation_car_radio,
                                valuation_car_navegador], 50)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Interiores asientos" />
                                <Grader label="Asiento delantero izquierdo" value={valuation_car_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asiento delantero derecho" value={valuation_car_asiento_delantero_derecho} maxValue={2} id="valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asientos traseros" value={valuation_car_asientos_traseros} maxValue={4} id="valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero izquierdo" value={valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero derecho" value={valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asientos traseros" value={valuation_car_vestidura_asientos_traseros} maxValue={4} id="valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Tablero y consola central" />
                                <Grader label="Tablero" value={valuation_car_tablero} maxValue={6} id="valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Volante" value={valuation_car_volante} maxValue={6} id="valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Consola central" value={valuation_car_consola} maxValue={4} id="valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Guantera" value={valuation_car_guantera} maxValue={4} id="valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cenicero" value={valuation_car_cenicero} maxValue={2} id="valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Radio" value={valuation_car_radio} maxValue={6} id="valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Navegador" value={valuation_car_navegador} maxValue={6} id="valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item" />
                            </div>
                            <h4>Mecánica: {this.calcValue([
                              valuation_car_funcionamiento_electrico,
                              valuation_car_alternador,
                              valuation_car_testigo_electrico,
                              valuation_car_radio_electrico,
                              valuation_car_motores_electricos], 30)}</h4>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                <Grader label="Comprobación funcionamiento general" value={valuation_car_funcionamiento_electrico} maxValue={6} id="valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control carga alternador" value={valuation_car_alternador} maxValue={6} id="valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de testigos" value={valuation_car_testigo_electrico} maxValue={6} id="valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento de radio" value={valuation_car_radio_electrico} maxValue={6} id="valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Motores eléctricos (elevadores y actuadores)" value={valuation_car_motores_electricos} maxValue={6} id="valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item" />
                              <div className="item" />
                            </div>
                          </React.Fragment>
                        :
                          <React.Fragment>
                            <h4>Habitáculo: {this.calcValue([
                                valuation_car_tapa_vestidura_puerta_delantera_izquierda,
                                valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda,
                                valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha,
                                valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha,
                                valuation_car_tapa_vestidura_cajuela,
                                valuation_car_vestidura_cielo,
                                valuation_car_alfombra,
                                valuation_car_asiento_delantero_izquierdo,
                                valuation_car_asiento_delantero_derecho,
                                valuation_car_asientos_traseros,
                                valuation_car_vestidura_asiento_delantero_izquierdo,
                                valuation_car_vestidura_asiento_delantero_derecho,
                                valuation_car_vestidura_asientos_traseros,
                                valuation_car_cierre_centralizado,
                                valuation_car_elevador_delantero_izquierdo,
                                valuation_car_elevador_trasero_izquierdo,
                                valuation_car_elevador_trasero_derecho,
                                valuation_car_elevador_delantero_derecho,
                                valuation_car_tablero,
                                valuation_car_volante,
                                valuation_car_consola,
                                valuation_car_guantera,
                                valuation_car_cenicero,
                                valuation_car_radio,
                                valuation_car_navegador], 104)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Interiores puertas" />
                                <Grader label="Tapa vestidura puerta delantera izquierda" value={valuation_car_tapa_vestidura_puerta_delantera_izquierda} maxValue={2} id="valuation_car_tapa_vestidura_puerta_delantera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta trasera izquierda" value={valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta trasera derecha" value={valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura puerta delantera derecha" value={valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha} maxValue={2} id="valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Tapa vestidura cajuela" value={valuation_car_tapa_vestidura_cajuela} maxValue={6} id="valuation_car_tapa_vestidura_cajuela" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura o cielo de techo" value={valuation_car_vestidura_cielo} maxValue={4} id="valuation_car_vestidura_cielo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Alfombra" value={valuation_car_alfombra} maxValue={6} id="valuation_car_alfombra" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Interiores asientos" />
                                <Grader label="Asiento delantero izquierdo" value={valuation_car_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asiento delantero derecho" value={valuation_car_asiento_delantero_derecho} maxValue={2} id="valuation_car_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Asientos traseros" value={valuation_car_asientos_traseros} maxValue={4} id="valuation_car_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero izquierdo" value={valuation_car_vestidura_asiento_delantero_izquierdo} maxValue={2} id="valuation_car_vestidura_asiento_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asiento delantero derecho" value={valuation_car_vestidura_asiento_delantero_derecho} maxValue={2} id="valuation_car_vestidura_asiento_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Vestidura asientos traseros" value={valuation_car_vestidura_asientos_traseros} maxValue={4} id="valuation_car_vestidura_asientos_traseros" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Cierre centralizado/elevadores" />
                                <Grader label="Cierre Centralizado" value={valuation_car_cierre_centralizado} maxValue={6} id="valuation_car_cierre_centralizado" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador delantero izquierdo" value={valuation_car_elevador_delantero_izquierdo} maxValue={6} id="valuation_car_elevador_delantero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador trasero izquierdo" value={valuation_car_elevador_trasero_izquierdo} maxValue={6} id="valuation_car_elevador_trasero_izquierdo" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador trasero derecho" value={valuation_car_elevador_trasero_derecho} maxValue={6} id="valuation_car_elevador_trasero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Elevador delantero derecho" value={valuation_car_elevador_delantero_derecho} maxValue={6} id="valuation_car_elevador_delantero_derecho" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="item">
                                <GraderHeader label="IV. Tablero y consola central" />
                                <Grader label="Tablero" value={valuation_car_tablero} maxValue={6} id="valuation_car_tablero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Volante" value={valuation_car_volante} maxValue={6} id="valuation_car_volante" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Consola central" value={valuation_car_consola} maxValue={4} id="valuation_car_consola" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Guantera" value={valuation_car_guantera} maxValue={4} id="valuation_car_guantera" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Cenicero" value={valuation_car_cenicero} maxValue={2} id="valuation_car_cenicero" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Radio" value={valuation_car_radio} maxValue={6} id="valuation_car_radio" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Navegador" value={valuation_car_navegador} maxValue={6} id="valuation_car_navegador" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item"></div>
                              <div className="item"></div>
                            </div>
                            <h4>Mecánica: {this.calcValue([
                                valuation_car_aceite_motor_filtros,
                                valuation_car_soportes_motor,
                                valuation_car_bandas,
                                valuation_car_banda_distribucion,
                                valuation_car_testigos_averia,
                                valuation_car_fugas_motor,
                                valuation_car_turbo,
                                valuation_car_caja_cambios,
                                valuation_car_fugas_transmision,
                                valuation_car_soportes_transmision,
                                valuation_car_juntas_homocineticas,
                                valuation_car_fugas_bomba_bombin,
                                valuation_car_trepidacion,
                                valuation_car_ruido_collarin,
                                valuation_car_accionamiento_embrague,
                                valuation_car_fugas_aceite_retenes,
                                valuation_car_ruidos_internos,
                                valuation_car_holguras,
                                valuation_car_ruidos_bomba,
                                valuation_car_estanqueidad,
                                valuation_car_freno_nivel_liquido,
                                valuation_car_testigo_abs,
                                valuation_car_bomba_agua,
                                valuation_car_motor_ventilador,
                                valuation_car_testigo_regrigeracion,
                                valuation_car_liquido_refrigeracion,
                                valuation_car_funcionamiento_electrico,
                                valuation_car_alternador,
                                valuation_car_testigo_electrico,
                                valuation_car_radio_electrico,
                                valuation_car_motores_electricos,
                                valuation_car_funcionamiento_aire,
                                valuation_car_motor_ventilador_aire,
                                valuation_car_compresor,
                                valuation_car_testigo_airbag], 210)}</h4>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="I. Motor y sistema de alimentación" />
                                <Grader label="Sustitución de aceite motor y filtros" value={valuation_car_aceite_motor_filtros} maxValue={6} id="valuation_car_aceite_motor_filtros" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de soportes de motor" value={valuation_car_soportes_motor} maxValue={6} id="valuation_car_soportes_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión y estado de bandas" value={valuation_car_bandas} maxValue={6} id="valuation_car_bandas" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Sustitución banda de distribución" value={valuation_car_banda_distribucion} maxValue={6} id="valuation_car_banda_distribucion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación testigos de avería" value={valuation_car_testigos_averia} maxValue={6} id="valuation_car_testigos_averia" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de fugas" value={valuation_car_fugas_motor} maxValue={6} id="valuation_car_fugas_motor" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento turbo" value={valuation_car_turbo} maxValue={6} id="valuation_car_turbo" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="II. Caja de cambios y transmisión" />
                                <Grader label="Prueba de caja de cambios (selección de velocidades)" value={valuation_car_caja_cambios} maxValue={6} id="valuation_car_caja_cambios" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de fugas" value={valuation_car_fugas_transmision} maxValue={6} id="valuation_car_fugas_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de soportes de transmisión" value={valuation_car_soportes_transmision} maxValue={6} id="valuation_car_soportes_transmision" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Revisión de juntas homocinéticas" value={valuation_car_juntas_homocineticas} maxValue={6} id="valuation_car_juntas_homocineticas" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="III. Embrague y diferencial" />
                                <Grader label="Comprobación de fugas en bomba y bombín" value={valuation_car_fugas_bomba_bombin} maxValue={6} id="valuation_car_fugas_bomba_bombin" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de trepidación" value={valuation_car_trepidacion} maxValue={6} id="valuation_car_trepidacion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobar ruido en collarín" value={valuation_car_ruido_collarin} maxValue={6} id="valuation_car_ruido_collarin" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control del accionamiento de embrague" value={valuation_car_accionamiento_embrague} maxValue={6} id="valuation_car_accionamiento_embrague" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control de fugas de aceite por retenes" value={valuation_car_fugas_aceite_retenes} maxValue={6} id="valuation_car_fugas_aceite_retenes" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación posibles ruidos internos" value={valuation_car_ruidos_internos} maxValue={6} id="valuation_car_ruidos_internos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="IV. Dirección y suspensión" />
                                <Grader label="Comprobación de holguras" value={valuation_car_holguras} maxValue={6} id="valuation_car_holguras" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de ruidos en bomba o servo" value={valuation_car_ruidos_bomba} maxValue={6} id="valuation_car_ruidos_bomba" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="V. ABS - Frenos" />
                                <Grader label="Comprobación de estanqueidad" value={valuation_car_estanqueidad} maxValue={6} id="valuation_car_estanqueidad" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control del estado y nivel de líquido" value={valuation_car_freno_nivel_liquido} maxValue={6} id="valuation_car_freno_nivel_liquido" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Testigo ABS" value={valuation_car_testigo_abs} maxValue={6} id="valuation_car_testigo_abs" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="VI. Refrigeración" />
                                <Grader label="Bomba de agua" value={valuation_car_bomba_agua} maxValue={6} id="valuation_car_bomba_agua" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de motor de ventilador (si aplica)" value={valuation_car_motor_ventilador} maxValue={6} id="valuation_car_motor_ventilador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control de funcionamiento de los testigos" value={valuation_car_testigo_regrigeracion} maxValue={6} id="valuation_car_testigo_regrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control y nivel del líquido" value={valuation_car_liquido_refrigeracion} maxValue={6} id="valuation_car_liquido_refrigeracion" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                            <div className="form-group border-r">
                              <div className="item">
                                <GraderHeader label="VII. Sist. eléctrico, radio e instrumentación" />
                                <Grader label="Comprobación funcionamiento general" value={valuation_car_funcionamiento_electrico} maxValue={6} id="valuation_car_funcionamiento_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Control carga alternador" value={valuation_car_alternador} maxValue={6} id="valuation_car_alternador" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de testigos" value={valuation_car_testigo_electrico} maxValue={6} id="valuation_car_testigo_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Funcionamiento de radio" value={valuation_car_radio_electrico} maxValue={6} id="valuation_car_radio_electrico" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Motores eléctricos (elevadores y actuadores)" value={valuation_car_motores_electricos} maxValue={6} id="valuation_car_motores_electricos" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="VIII. Aire acondicionado" />
                                <Grader label="Comprobación de funcionamiento interno" value={valuation_car_funcionamiento_aire} maxValue={6} id="valuation_car_funcionamiento_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación encendido de motor de ventilador" value={valuation_car_motor_ventilador_aire} maxValue={6} id="valuation_car_motor_ventilador_aire" onChange={(id, value) => this.setState({[id]: value})} />
                                <Grader label="Comprobación de compresor" value={valuation_car_compresor} maxValue={6} id="valuation_car_compresor" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                              <div className="item">
                                <GraderHeader label="IX. Airbag" />
                                <Grader label="Testigos encendidos" value={valuation_car_testigo_airbag} maxValue={6} id="valuation_car_testigo_airbag" onChange={(id, value) => this.setState({[id]: value})} />
                              </div>
                            </div>
                          </React.Fragment>
                        }
                      </div>
                      <div ref={this.documentPage3}>
                        {images ?
                          <>
                            <h2>Archivos</h2>
                            <div className="flexbox f-wrap">
                              {images.map((item, key) =>
                                <div id={'image-' + key} className="flex pL-10 pR-10 mB-20" key={key} style={{position: 'relative', minWidth: '33%', maxWidth: '33%'}}>
                                  <img alt={item.id} src={item.uri} style={{width: "100%", height: "auto"}} />
                                  <div className="form-row">
                                    <div className="select-wrapper">
                                      <select id={'image-category-' + key} defaultValue={item.category} onChange={(e) => this.handlePictureCategory(e, item.id)}>
                                        <option value="Documentación">Documentación</option>
                                        <option value="Carrocería">Carrocería</option>
                                        <option value="Habitáculo">Habitáculo</option>
                                        <option value="Mecánica">Mecánica</option>
                                      </select>
                                      <FontAwesomeIcon icon="chevron-down" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        : null}
                        <h2>Oferta</h2>
                        <table className="bordered mB-15">
                          <tbody>
                            <tr>
                              <td className="odd">Valor sugerido</td>
                              <td className="even">
                                <Cleave id="intervention_offer" onChange={this.handleCleaveChange} disabled value={intervention_offer} options={{numeral: true}} />
                              </td>
                              <td className="odd">Valor comercial</td>
                              <td className="even">
                                <Cleave id="intervention_offer" onChange={this.handleCleaveChange} disabled value={intervention_offer} options={{numeral: true}} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                : null}
              </>
            : null}
          </div>
        :
          <Loading />
        }
      </div>
    )
  }
}
