import moment from "moment"
import {jsPDF} from 'jspdf'
import AccountStore from '../src/store/user'
import Garantiplus from '../src/assets/images/garantiplus.jpg'
import Invarat from '../src/assets/images/logo.png'
import Seminuevos from '../src/assets/images/seminuevos.jpg'
import swal from 'sweetalert'

export function validateJson(value, desired) {
  try {
    if (isNaN(value)) {
      let result = JSON.parse(value)
      return result[desired]
    } else {
      return value
    }
  } catch (e) {
    return value
  }
}
export function isOdd(num) { return num % 2 }

export function isBetweenHours(hour, startHour, endHour) {
  const start = moment(startHour, 'HH:mm')
  const end = moment(endHour, 'HH:mm')
  return moment(hour).isBetween(start, end)
}

export function checklistValue(valor) { 
  let resultado;
  if (valor===2){
    resultado='Bien'
  }else if (valor===1){
    resultado='Regular'
  }else if (valor===0){
    resultado='Mal'  
  }  
  return resultado
}

/* export async function exportPdfValuation(id, intervention, client) {
  try {
    let intervantio = 0;
    const pdf = new jsPDF('p', 'mm', 'a4')

    // Dimensiones del documento
    let documentWidth = pdf.internal.pageSize.getWidth()
    let documentHeight = pdf.internal.pageSize.getHeight()

    // Logo de compañia
    const company = await AccountStore.getCompany()
    const logo = company === "Garantiplus" ? Garantiplus : (company === "Seminuevos" ? Seminuevos : Invarat)
    pdf.addImage(logo, 'JPEG', documentWidth - 13 - 56, 15, 56, 23)

    console.log("intervention")
    console.log(intervention)

    let lastY = 37
    pdf.text(13, lastY + 10, 'Datos del cliente')
    pdf.autoTable({
      startY: lastY + 15,
      styles: { fillColor: [255, 255, 255] },
      columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
      body: [
        ['Nombre', client.name ? client.name : 'S/D', 'Tarjeta de circulación', intervention.valuation.id_name ? intervention.valuation.id_name : 'S/D'],
        ['Teléfono fijo', client.phone ? client.phone : 'S/D', 'Teléfono celular', client.mobile ? client.mobile : 'S/D'],
        ['Correo electrónico', client.email ? client.email : 'S/D'],
        ['Regimen fiscal', client.regimen ? client.regimen : 'S/D', 'Precio solicitado', intervention.valuation.requested_price ? intervention.valuation.requested_price : 'S/D']
      ]
    })


    // Datos carro
    lastY = pdf.lastAutoTable.finalY
    pdf.text(13, lastY + 10, 'Datos del carro')
    pdf.autoTable({
      startY: lastY + 15,
      styles: { fillColor: [255, 255, 255] },
      columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
      body: [
        ['Marca', intervention.valuation.vehicle.car_brand ? intervention.valuation.vehicle.car_brand : 'S/D', 'Linea', intervention.valuation.vehicle.car_line ? intervention.valuation.vehicle.car_line : 'S/D'],
        ['Año/Modelo', intervention.valuation.vehicle.car_model ? intervention.valuation.vehicle.car_model : 'S/D', 'Color Exterior', intervention.valuation.vehicle.car_exterior_color ? intervention.valuation.vehicle.car_exterior_color : 'S/D'],
        ['Versión', intervention.valuation.vehicle.car_version ? intervention.valuation.vehicle.car_version : 'S/D', 'Color Interior', intervention.valuation.vehicle.car_interior_color ? intervention.valuation.vehicle.car_interior_color : 'S/D'],
        ['Número de serie', intervention.valuation.vehicle.car_vin ? intervention.valuation.vehicle.car_vin : 'S/D', 'Número de placas', intervention.valuation.vehicle.car_plates ? intervention.valuation.vehicle.car_plates : 'S/D'],
        ['Transmisión', intervention.valuation.vehicle.car_transmission ? intervention.valuation.vehicle.car_transmission : 'S/D', 'KM', intervention.valuation.vehicle.car_kilometers ? intervention.valuation.vehicle.car_kilometers : 'S/D']
      ],
    })
    // GENERALIDADES Y OBSERVACIONES

    // Reportes
    const totalcheck = intervention.valuation
    //console.log("totalcheck")
    //console.log(totalcheck)

    lastY = pdf.lastAutoTable.finalY
    pdf.text(13, lastY + 10, 'Reportes')
    if (totalcheck.reports) {
      let report
      for (var i = 0; i < totalcheck.reports.data.length; i++) {
        lastY = pdf.lastAutoTable.finalY
        report = totalcheck.reports.data[i]
        pdf.autoTable({
          startY: lastY + (i === 0 ? 15 : 5),
          styles: { fillColor: [255, 255, 255] },
          columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
          body: [
            ['Sistema', report.sistema ? report.sistema : 'S/D', 'Reporte', report.reporte ? report.reporte : 'S/D'],
            ['Estatus', report.status ? report.status : 'S/D', 'Descripción', report.descripcion ? report.descripcion : 'S/D'],
            ['Entidad', report.entidad ? report.entidad : 'S/D', 'Entidad de recuperación', report.entidad_recuperacion ? report.entidad_recuperacion : 'S/D'],
            ['Fecha de robo', report.fecha_robo ? report.fecha_robo : 'S/D', 'Fecha de recuperación', report.fecha_recuperacion ? report.fecha_recuperacion : 'S/D'],
            ['Fecha de averiguación', report.fecha_averiguacion ? report.fecha_averiguacion : 'S/D', 'Fecha de actualización', report.fecha_actualizacion ? report.fecha_actualizacion : 'S/D']
          ],
        })
      }
    } else {
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255], halign: 'left' },
        body: [
          ['No hay reportes para este vehículo.']
        ]
      })
    }

    
    // Multas
    lastY = pdf.lastAutoTable.finalY
    pdf.text(13, lastY + 10, 'Multas')
    if (totalcheck.tickets) {
      let ticket
      for (var i = 0; i < totalcheck.tickets.data.length; i++) {
        lastY = pdf.lastAutoTable.finalY
        ticket = totalcheck.tickets.data[i]
        pdf.autoTable({
          startY: lastY + (i === 0 ? 15 : 5),
          styles: { fillColor: [255, 255, 255] },
          columnStyles: { 0: { halign: 'center', fillColor: [227, 227, 227] }, 2: { halign: 'center', fillColor: [227, 227, 227] } },
          body: [
            ['Folio', ticket.folio ? ticket.folio : 'S/D', 'Fecha', ticket.fecha ? ticket.fecha : 'S/D'],
            ['Monto', ticket.importe ? ticket.importe : 'S/D', 'Motivo', ticket.motivo ? ticket.motivo : 'S/D'],
            ['Fundamento', ticket.fundamento ? ticket.fundamento : 'S/D', 'Sanción', ticket.sancion ? ticket.sancion : 'S/D'],
            ['situacion', ticket.situacion ? ticket.situacion : 'S/D']
          ]
        })
      }
    } else {
      pdf.autoTable({
        startY: lastY + 15,
        styles: { fillColor: [255, 255, 255], halign: 'left' },
        body: [
          ['No hay multas para este vehículo.']
        ]
      })
    }


    //Tenencias
    lastY = pdf.lastAutoTable.finalY
    pdf.text(13, lastY + 10, 'Tenencias')
    pdf.autoTable({
      startY: lastY + 15,
      styles: { fillColor: [255, 255, 255], halign: 'left' },
      body: [
        [totalcheck.tenencias ? totalcheck.tenencias.data.map((item, key) =>
          item + (key < (totalcheck.tenencias.data.length - 1) ? ', ' : '')
        ) : 'No tiene adeudos de tenencias.']
      ]
    })

    let interventions  = intervention
    let intervencion_id_asignado = interventions.valuation.intervencion_id_asignado
    //console.log("interventions")
    //console.log(interventions)
    //console.log("intervencion_id_asignado="+intervencion_id_asignado)
    
    if (intervencion_id_asignado==0){
      swal("Ups!", "No se ha terminado ninguna intervención, favor de completar.", "warning")
      return
    } 
    
    //return;
    if (interventions) {
      console.log("interventions=");
      console.log("intervencion_id_asignado="+intervencion_id_asignado);
      console.log(interventions);
      console.log("interventions end");
      let intervention, lastY, docImages, bodyImages, habImages, mechImages, imageObject, image64, imageUrl, parsedImageUrl, checklist
        intervention = interventions
        if (intervencion_id_asignado == intervention.id) {
          
          if (intervention.status === 'Completada') {
            if (intervention.company ==="Clicar"){
              intervantioId = intervention.id;
              pdf.addPage()
              pdf.text(13, 20, intervention.category.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + intervention.id)
              lastY = 30
              
              //checklist clicar
              if (intervention.form) {
                checklist = JSON.parse(intervention.form)
                console.log("checklist");
                console.log(checklist);

                
                
                pdf.text(13, lastY, 'Documentación:')
                pdf.autoTable({
                  startY: lastY + 10,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Documentación', ' '],
                    ['Cedula identificador:', checklistValue(checklist.valuation_car_cedula)],
                    ['Factura:', checklistValue(checklist.valuation_car_factura)],
                    ['Manual de servicio:', checklistValue(checklist.valuation_car_manual_servicio)],
                    ['Manual del propietario:', checklistValue(checklist.valuation_car_manual_propietario)],
                    ['Permiso de circulación:', checklistValue(checklist.valuation_car_permiso_circulacion)],
                    ['Revisión técnica y homologación de gases:', checklistValue(checklist.valuation_car_revision_tecnica)],
                    ['Seguro obligatorio de accidentes:', checklistValue(checklist.valuation_car_seguro_obligatorio)],
                   
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })

                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Interior:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['Air-bags frontales:', checklistValue(checklist.valuation_car_airbags_front)],
                    ['Air-bags laterales:', checklistValue(checklist.valuation_car_airbags_lat)],
                    ['Air-bag copiloto:', checklistValue(checklist.valuation_car_airbag_copiloto)],
                    ['Air-bag volante:', checklistValue(checklist.valuation_car_airbag_volante)],
                    ['Aire acondicionado:', checklistValue(checklist.valuation_car_aire_acondicionado)],
                    ['Alzavidrios eléctricos:', checklistValue(checklist.valuation_car_alzavidrios_elect)],
                    ['Asiento del cópiloto:', checklistValue(checklist.valuation_car_asiento_copiloto)],
                    ['Cabina general:', checklistValue(checklist.valuation_car_cabina_general)],
                    ['Cierre central:', checklistValue(checklist.valuation_car_cierre_central)],
                    ['Cinturón de seguridad:', checklistValue(checklist.valuation_car_cinturon_seguridad)],
                    ['Climatizador:', checklistValue(checklist.valuation_car_climatizador)],
                    ['Comandos:', checklistValue(checklist.valuation_car_comandos)],
                    ['Consola central:', checklistValue(checklist.valuation_car_consola_central)],
                    ['Control crucero:', checklistValue(checklist.valuation_car_control_crucero)],
                    ['Espejo retrovisor interior:', checklistValue(checklist.valuation_car_espejo_ret_int)],
                    ['Motor:', checklistValue(checklist.valuation_car_motor)],
                    ['Navegador GPS:', checklistValue(checklist.valuation_car_navegador_gps)],
                    ['Palanca de cambios:', checklistValue(checklist.valuation_car_palanca_cambios)],
                    ['Piso maleta y panel trasero:', checklistValue(checklist.valuation_car_piso_maleta)],
                    ['Radio original:', checklistValue(checklist.valuation_car_radio_original)],
                    ['Rueda de repuesto:', checklistValue(checklist.valuation_car_rueda_respuesto)],
                    ['Sunroof:', checklistValue(checklist.valuation_car_sunroof)],
                    ['Tablero completo:', checklistValue(checklist.valuation_car_tablero_completo)],
                    ['Tablero encendido:', checklistValue(checklist.data)],
                    ['Tapamaleta o portalon:', checklistValue(checklist.valuation_car_tapamaleta)],
                    ['Tapiz techo:', checklistValue(checklist.valuation_car_tapiz_techo)],
                    ['Techo panóramico:', checklistValue(checklist.valuation_car_techo_pan)],
                    ['Vin:', checklistValue(checklist.valuation_car_vin)],
                    ['Volante:', checklistValue(checklist.valuation_car_volante_c)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })

                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Exterior:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['Capot:', checklistValue(checklist.valuation_car_capot)],
                    ['Carcaza espejo lat. der.:', checklistValue(checklist.valuation_car_carcaza_espejo_lat_der)],
                    ['Carcaza espejo lat. izq.:', checklistValue(checklist.valuation_car_carcaza_espejo_lat_izq)],
                    ['Costado tras. der.:', checklistValue(checklist.valuation_car_costado_tras_der)],
                    ['Costado tras. izq.:', checklistValue(checklist.valuation_car_costado_tras_izq)],
                    ['Foco tras. der.:', checklistValue(checklist.valuation_car_foco_tras_der)],
                    ['Foco tras. izq.:', checklistValue(checklist.valuation_car_foco_tras_izq)],
                    ['Frontal lateral der.:', checklistValue(checklist.valuation_car_frontal_lat_der)],
                    ['Frontal lateral izq.:', checklistValue(checklist.valuation_car_frontal_lat_izq)],
                    ['Llanta del. der.:', checklistValue(checklist.valuation_car_llanta_del_der)],
                    ['Llanta del. izq.:', checklistValue(checklist.valuation_car_llanta_del_izq)],
                    ['Llanta tras. der.:', checklistValue(checklist.valuation_car_llanta_tras_der)],
                    ['Llanta tras. izq.:', checklistValue(checklist.valuation_car_llanta_tras_izq)],
                    ['Llanta espejo lat. der.:', checklistValue(checklist.valuation_car_llanta_espejo_lat_der)],
                    ['Llanta espejo lat. izq.:', checklistValue(checklist.valuation_car_llanta_espejo_lat_izq)],
                    ['Luneta:', checklistValue(checklist.valuation_car_luneta)],
                    ['Mascara:', checklistValue(checklist.valuation_car_mascara)],
                    ['Neblibero der.:', checklistValue(checklist.valuation_car_neblibero_der)],
                    ['Neblibero izq.:', checklistValue(checklist.valuation_car_neblibero_izq)],
                    ['Optico der.:', checklistValue(checklist.valuation_car_optico_der)],
                    ['Optico izq.:', checklistValue(checklist.valuation_car_optico_izq)],
                    ['Parabrisas:', checklistValue(checklist.valuation_car_parabrisas_ext)],
                    ['Parachoque del.:', checklistValue(checklist.valuation_car_parachoque_del)],
                    ['Parachoque tras.:', checklistValue(checklist.valuation_car_parachoque_tras)],
                    ['Pisadera izquierda:', checklistValue(checklist.valuation_car_pisadera_izq)],
                    ['Pisadera derecha:', checklistValue(checklist.valuation_car_pisadera_der)],
                    ['Portalón o tapamaleta:', checklistValue(checklist.valuation_car_portalon)],
                    ['Puerta del. der.:', checklistValue(checklist.valuation_car_puerta_del_der)],
                    ['Puerta del. izq.:', checklistValue(checklist.valuation_car_puerta_del_izq)],
                    ['Puerta tras. der.:', checklistValue(checklist.valuation_car_puerta_tras_der)],
                    ['Puerta tras. izq.:', checklistValue(checklist.valuation_car_puerta_tras_izq)],
                    ['Rejilla parach. del.:', checklistValue(checklist.valuation_car_rejilla_parach_del)],
                    ['Tapiz puerta del. der.:', checklistValue(checklist.valuation_car_tapiz_puerta_del_der)],
                    ['Tapiz puerta del. izq.:', checklistValue(checklist.valuation_car_tapiz_puerta_del_izq)],
                    ['Tapiz puerta tras. der.:', checklistValue(checklist.valuation_car_tapiz_puerta_tras_der)],
                    ['Tapiz puerta tras. izq.:', checklistValue(checklist.valuation_car_tapiz_puerta_tras_izq)],
                    ['Taparrabo del. der.:', checklistValue(checklist.valuation_car_taparrabo_del_der)],
                    ['Taparrabo del. izq.:', checklistValue(checklist.valuation_car_taparrabo_del_izq)],
                    ['Techo:', checklistValue(checklist.valuation_car_techo_ext)],
                    ['Trasera lat. der.:', checklistValue(checklist.valuation_car_trasera_lat_der)],
                    ['Trasera lat. izq.:', checklistValue(checklist.valuation_car_trasera_lat_izq)],
                    ['Vidrio aleta tras. der.:', checklistValue(checklist.valuation_car_vidrio_aleta_tras_der)],
                    ['Vidrio aleta tras. izq.:', checklistValue(checklist.valuation_car_vidrio_aleta_tras_izq)],
                    ['Vidrio puerta del. der.:', checklistValue(checklist.valuation_car_vidrio_puerta_del_der)],
                    ['Vidrio puerta del. izq.:', checklistValue(checklist.valuation_car_vidrio_puerta_del_izq)],
                    ['Vidrio puerta tras. der.:', checklistValue(checklist.valuation_car_vidrio_puerta_tras_der)],
                    ['Vidrio puerta tras. izq.:', checklistValue(checklist.valuation_car_vidrio_puerta_tras_izq)],
                    ['Zócalo der.:', checklistValue(checklist.valuation_car_zocalo_der)],
                    ['Zócalo izq.:', checklistValue(checklist.valuation_car_zocalo_izq)]
                    
                    
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                

              }  
              //end checklist clicar
            }else{
              intervantioId = intervention.id;
              pdf.addPage()
              pdf.text(13, 20, intervention.category.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + intervention.id)
              lastY = 30
              //checklist general
              if (intervention.form) {
                checklist = JSON.parse(intervention.form)
                pdf.text(13, lastY, 'Documentación:')
                pdf.autoTable({
                  startY: lastY + 10,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Documentación', ' '],
                    ['Factura:', checklistValue(checklist.valuation_docs_invoice)],
                    ['Tarjeta De Circulación:', checklistValue(checklist.valuation_docs_circulation_card)],
                    ['Pagos De Refrendo Completos:', checklistValue(checklist.valuation_docs_payments)],
                    ['Verificación Vigente:', checklistValue(checklist.valuation_docs_verification)],
                    ['Manual De Servicio Y Mantenimiento:', checklistValue(checklist.valuation_docs_manual)],
                    ['INE Del Titular De La Operación:', checklistValue(checklist.valuation_docs_titular_id)],
                    ['Duplicado De La Llave:', checklistValue(checklist.valuation_docs_keys_duplicate)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Carrocería:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Carrocería exterior', ' ', 'II. Iluminación y señalización', ' '],
                    ['Cofré/Capo:', checklistValue(checklist.valuation_car_cofre), 'Faro Delantero Derecho:', checklistValue(checklist.valuation_car_faro_delantero_derecho)],
                    ['Fascia/Defensa Delantera:', checklistValue(checklist.valuation_car_defensa_delantera), 'Faro Antiniebla Derecho:', checklistValue(checklist.valuation_car_faro_antiniebla_derecho)],
                    ['Salpicadera/Aleta Delantera Izquierda:', checklistValue(checklist.valuation_car_salpicadera_delantera_izquierda), 'Faro Delantero Izquierdo:', checklistValue(checklist.valuation_car_faro_delantero_izquierdo)],
                    ['Puerta Delantera Izquierda:', checklistValue(checklist.valuation_car_puerta_delantera_izquierda), 'Faro Antiniebla Izquierdo:', checklistValue(checklist.valuation_car_faro_antiniebla_izquierdo)],
                    ['Puerta Trasera Izquierda:', checklistValue(checklist.valuation_car_puerta_trasera_izquierda), 'Calavera Trasera Izquierda:', checklistValue(checklist.valuation_car_calavera_trasera_izquierda)],
                    ['Estribo Izquierdo:', checklistValue(checklist.valuation_car_estribo_izquierdo), 'Calavera Trasera Derecha:', checklistValue(checklist.valuation_car_calavera_trasera_derecha)],
                    ['Costado Trasero Izquierdo:', checklistValue(checklist.valuation_car_costado_trasero_izquierdo), 'Stop Central Trasero:', checklistValue(checklist.valuation_car_stop_central_trasero)],
                    ['Tapa De Cajuela:', checklistValue(checklist.valuation_car_cajuela_tapa), 'Intermitentes/Direccionales:', checklistValue(checklist.valuation_car_intermitentes)],
                    ['Fascia/Defensa Trasera:', checklistValue(checklist.valuation_car_defensa_trasera), 'Luz De Reversa:', checklistValue(checklist.valuation_car_luz_reversa)],
                    ['Costado Trasero Derecho:', checklistValue(checklist.valuation_car_costado_trasero_derecha)],
                    ['Puerta Trasera Derecha:', checklistValue(checklist.valuation_car_puerta_trasera_derecha)],
                    ['Puerta Delantera Derecha:', checklistValue(checklist.valuation_car_puerta_delantera_derecha)],
                    ['Estribo Derecho:', checklistValue(checklist.valuation_car_estribo_derecho)],
                    ['Salpicadera/Aleta Delantera Derecha:', checklistValue(checklist.valuation_car_salpicadera_delantera_derecha)],
                    ['Techo:', checklistValue(checklist.valuation_car_techo)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Cristales', ' ', 'IV. Llantas y neumáticos', ' '],
                    ['Parabrisas:', checklistValue(checklist.valuation_car_parabrisas), 'Neumático Delantero Izquierdo:', checklistValue(checklist.valuation_car_neumatico_delantero_izquierdo)],
                    ['Cristal Delantero Izquierdo:', checklistValue(checklist.valuation_car_cristal_delantero_izquierdo), 'Neumático Trasero Izquierdo:', checklistValue(checklist.valuation_car_neumatico_trasero_izquierdo)],
                    ['Cristal Trasero Izquierdo:', checklistValue(checklist.valuation_car_cristal_trasero_izquierdo), 'Neumático Trasero Derecho:', checklistValue(checklist.valuation_car_neumatico_trasero_derecho)],
                    ['Medallón Trasero:', checklistValue(checklist.valuation_car_medallon_trasero), 'Neumático Delantero Derecho:', checklistValue(checklist.valuation_car_neumatico_delatero_derecho)],
                    ['Cristal Trasero Derecho:', checklistValue(checklist.valuation_car_cristal_trasero_derecho), 'Neumático De Repuesto:', checklistValue(checklist.valuation_car_neumatico_repuesto)],
                    ['Cristal Delantero Derecho:', checklistValue(checklist.valuation_car_cristal_delantero_derecho), 'Rin Delantero Izquierdo:', checklistValue(checklist.valuation_car_rin_delantero_izquierdo)],
                    ['Espejos O Retrovisores:', checklistValue(checklist.valuation_car_retrovisores), 'Rin Trasero Izquierdo:', checklistValue(checklist.valuation_car_rin_trasero_izquierdo)],
                    ['Funcionamiento QC (Quemacocos):', checklistValue(checklist.valuation_car_cristal_qc), 'Rin Trasero Derecho:', checklistValue(checklist.valuation_car_rin_trasero_derecho)],
                    ['', '', 'Rin Delantero Derecho:', checklistValue(checklist.valuation_car_rin_delantero_derecho)],
                    ['', '', 'Rin De Repuesto:', checklistValue(checklist.valuation_car_rin_repuesto)],
                    ['', '', 'Herramientas:', checklistValue(checklist.valuation_car_tools)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Habitáculo:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Interiores puertas', ' ', 'II. Interiores asientos', ' '],
                    ['Tapa Vestidura Puerta Delantera Izquierda:', checklistValue(checklist.valuation_car_tapa_vestidura_puerta_delantera_izquierda), 'Asiento Delantero Izquierdo:', checklistValue(checklist.valuation_car_asiento_delantero_izquierdo)],
                    ['Tapa Vestidura Puerta Trasera Izquierda:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_izquierda), 'Asiento Delantero Derecho:', checklistValue(checklist.valuation_car_asiento_delantero_derecho)],
                    ['Tapa Vestidura Puerta Trasera Derecha:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_trasera_derecha), 'Asientos Traseros:', checklistValue(checklist.valuation_car_asientos_traseros)],
                    ['Tapa Vestidura Puerta Delantera Derecha:', checklistValue(checklist.valuation_car_valuation_car_tapa_vestidura_puerta_delantera_derecha), 'Vestidura Asiento Delantero Izquierdo:', checklistValue(checklist.valuation_car_vestidura_asiento_delantero_izquierdo)],
                    ['Tapa Vestidura Cajuela:', checklistValue(checklist.valuation_car_tapa_vestidura_cajuela), 'Vestidura Asiento Delantero Derecho:', checklistValue(checklist.valuation_car_vestidura_asiento_delantero_derecho)],
                    ['Vestidura O Cielo De Techo:', checklistValue(checklist.valuation_car_vestidura_cielo), 'Vestidura Asientos Traseros:', checklistValue(checklist.valuation_car_vestidura_asientos_traseros)],
                    ['Alfombra:', checklistValue(checklist.valuation_car_alfombra)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Cierre centralizado/elevadores', ' ', 'IV. Tablero y consola central', ' '],
                    ['Cierre Centralizado:', checklistValue(checklist.valuation_car_cierre_centralizado), 'Tablero:', checklistValue(checklist.valuation_car_tablero)],
                    ['Elevador Delantero Izquierdo:', checklistValue(checklist.valuation_car_elevador_delantero_izquierdo), 'Volante:', checklistValue(checklist.valuation_car_volante)],
                    ['Elevador Trasero Izquierdo:', checklistValue(checklist.valuation_car_elevador_trasero_izquierdo), 'Consola Central:', checklistValue(checklist.valuation_car_consola)],
                    ['Elevador Trasero Derecho:', checklistValue(checklist.valuation_car_elevador_trasero_derecho), 'Guantera:', checklistValue(checklist.valuation_car_guantera)],
                    ['Elevador Delantero Derecho:', checklistValue(checklist.valuation_car_elevador_delantero_derecho), 'Cenicero:', checklistValue(checklist.valuation_car_cenicero)],
                    ['', '', 'Radio:', checklistValue(checklist.valuation_car_radio)],
                    ['', '', 'Navegador:', checklistValue(checklist.valuation_car_navegador)],
                    ['', '', 'Tarjeta SD:', checklistValue(checklist.valuation_car_sd_card)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.text(13, lastY + 10, 'Mecánica:')
                pdf.autoTable({
                  startY: lastY + 15,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['I. Motor y sistema de alimentación', ' ', 'II. Caja de cambios y transmisión', ' '],
                    ['Sustitución De Aceite Motor Y Filtros:', checklistValue(checklist.valuation_car_aceite_motor_filtros), 'Prueba De Caja De Cambios (Selección De Velocidades):', checklistValue(checklist.valuation_car_caja_cambios)],
                    ['Revisión De Soportes De Motor:', checklistValue(checklist.valuation_car_soportes_motor), 'Revisión De Fugas:', checklistValue(checklist.valuation_car_fugas_transmision)],
                    ['Revisión Y Estado De Bandas:', checklistValue(checklist.valuation_car_bandas), 'Revisión De Soportes De Transmisión:', checklistValue(checklist.valuation_car_soportes_transmision)],
                    ['Sustitución Banda De Distribución:', checklistValue(checklist.valuation_car_banda_distribucion), 'Revisión De Juntas Homocinéticas:', checklistValue(checklist.valuation_car_juntas_homocineticas)],
                    ['Comprobación Testigos De Avería:', checklistValue(checklist.valuation_car_testigos_averia)],
                    ['Revisión De Fugas:', checklistValue(checklist.valuation_car_fugas_motor)],
                    ['Funcionamiento Turbo:', checklistValue(checklist.valuation_car_turbo)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['III. Embrague y diferencial', ' ', 'IV. Dirección y suspensión', ' '],
                    ['Comprobación De Fugas En Bomba Y Bombín:', checklistValue(checklist.valuation_car_fugas_bomba_bombin), 'Comprobación De Holguras:', checklistValue(checklist.valuation_car_holguras)],
                    ['Comprobación De Trepidación:', checklistValue(checklist.valuation_car_trepidacion), 'Comprobación De Ruidos En Bomba O Servo:', checklistValue(checklist.valuation_car_ruidos_bomba)],
                    ['Comprobar Ruido En Collarín:', checklistValue(checklist.valuation_car_ruido_collarin)],
                    ['Control Del Accionamiento De Embrague:', checklistValue(checklist.valuation_car_accionamiento_embrague)],
                    ['Control De Fugas De Aceite Por Retenes:', checklistValue(checklist.valuation_car_fugas_aceite_retenes)],
                    ['Comprobación Posibles Ruidos Internos:', checklistValue(checklist.valuation_car_ruidos_internos)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['V. ABS - Frenos', ' ', 'VI. Refrigeración', ' '],
                    ['Comprobación De Estanqueidad:', checklistValue(checklist.valuation_car_estanqueidad), 'Bomba De Agua:', checklistValue(checklist.valuation_car_bomba_agua)],
                    ['Control Del Estado Y Nivel De Líquido:', checklistValue(checklist.valuation_car_freno_nivel_liquido), 'Comprobación De Motor De Ventilador (Si Aplica):', checklistValue(checklist.valuation_car_motor_ventilador)],
                    ['Testigo ABS:', checklistValue(checklist.valuation_car_testigo_abs), 'Control De Funcionamiento De Los Testigos:', checklistValue(checklist.valuation_car_testigo_regrigeracion)],
                    [' ', ' ', 'Control Y Nivel Del Líquido:', checklistValue(checklist.valuation_car_liquido_refrigeracion)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['VII. Sist. eléctrico, radio e instrumentación', ' ', 'VIII. Aire acondicionado', ' '],
                    ['Comprobación Funcionamiento General:', checklistValue(checklist.valuation_car_funcionamiento_electrico), 'Comprobación De Funcionamiento Interno:', checklistValue(checklist.valuation_car_funcionamiento_aire)],
                    ['Control Carga Alternador:', checklistValue(checklist.valuation_car_alternador), 'Comprobación Encendido De Motor De Ventilador:', checklistValue(checklist.valuation_car_motor_ventilador_aire)],
                    ['Comprobación De Testigos:', checklistValue(checklist.valuation_car_testigo_electrico), 'Comprobación De Compresor:', checklistValue(checklist.valuation_car_compresor)],
                    ['Funcionamiento De Radio:', checklistValue(checklist.valuation_car_radio_electrico)],
                    ['Motores Eléctricos (Elevadores Y Actuadores):', checklistValue(checklist.valuation_car_motores_electricos)]
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
                lastY = pdf.lastAutoTable.finalY
                pdf.autoTable({
                  startY: lastY + 5,
                  styles: { fillColor: [255, 255, 255], halign: 'left' },
                  body: [
                    ['IX. Airbag', ' '],
                    ['Testigos Encendidos:', checklist.valuation_car_testigo_airbag],
                  ],
                  didParseCell: (item) => {
                    const { cells } = item.row
                    const keys = Object.keys(cells)
                    let cell
                    for (var i = 0; i < keys.length; i++) {
                      cell = cells[keys[i]]
                      if (cell.raw === 'Bien') {
                        cell.styles.fillColor = [39, 174, 96];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Regular') {
                        cell.styles.fillColor = [243, 156, 18];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      } else if (cell.raw === 'Mal') {
                        cell.styles.fillColor = [231, 76, 60];
                        cell.styles.textColor = [255, 255, 255];
                        cell.styles.halign = 'center';
                      }
                    }
                  }
                })
              }
              //end checklist general
            }

            //imagenes 
            console.log("// images=");
            console.log("// images length=" + intervention.imagesObj.length);
            console.log(intervention.imagesObj);

            if (intervention.imagesObj.length > 0) {
              docImages = intervention.imagesObj.filter(item => item.category === 'Documentación')
              bodyImages = intervention.imagesObj.filter(item => item.category === 'Carrocería')
              habImages = intervention.imagesObj.filter(item => item.category === 'Habitáculo')
              mechImages = intervention.imagesObj.filter(item => item.category === 'Mecánica')

              let pdfImgWidthIni = 70
              console.log('PDF images', docImages, bodyImages, habImages, mechImages);
              if (docImages.length > 0) {
                lastY = 30
                pdf.addPage()
                pdf.text(13, lastY, 'Documentación')
                for (var imageIndex = 0; imageIndex < docImages.length; imageIndex++) {
                  imageObject = docImages[imageIndex]
                  //tamano de la imagen 
                  let imgProps= pdf.getImageProperties(imageObject.uri)
                  let pdfImgWidth = imgProps.width 
                  let pdfImgHeight =  imgProps.height 
                  let pdfImgWidthR=0
                  let pdfImgHeightR=0
                  if (pdfImgWidth > pdfImgWidthIni) {
                    pdfImgWidthR = pdfImgWidthIni
                    pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                    pdfImgHeightR = Math.round(pdfImgHeightR);
                  }else{
                    pdfImgWidthR=pdfImgWidth
                    pdfImgHeightR=pdfImgHeight
                  } 
                  console.log('image original size:', pdfImgWidth, pdfImgHeight, 'image NEW size: ', pdfImgWidthR, pdfImgHeightR);
                  //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                  if (lastY < (297-pdfImgHeightR)) {
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  } else {
                    pdf.addPage()
                    lastY = 30
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  }
                  //lastY = (documentHeight / 2)
                  lastY += pdfImgHeightR + 10
                  //end tamano de la imagen 
                }
              }
              if (bodyImages.length > 0) {
                lastY = 30
                pdf.addPage()
                pdf.text(13, lastY, 'Carrocería')
                for (var imageIndex = 0; imageIndex < bodyImages.length; imageIndex++) {
                  imageObject = bodyImages[imageIndex]
                  //tamano de la imagen 
                  let imgProps= pdf.getImageProperties(imageObject.uri)
                  let pdfImgWidth = imgProps.width 
                  let pdfImgHeight =  imgProps.height 
                  let pdfImgWidthR=0
                  let pdfImgHeightR=0
                  if (pdfImgWidth > pdfImgWidthIni) {
                    pdfImgWidthR = pdfImgWidthIni
                    pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                    pdfImgHeightR = Math.round(pdfImgHeightR);
                  }else{
                    pdfImgWidthR=pdfImgWidth
                    pdfImgHeightR=pdfImgHeight
                  }
                  console.log('image original size:', pdfImgWidth, pdfImgHeight, 'image NEW size: ', pdfImgWidthR, pdfImgHeightR);
                  //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                  console.log('index img: ', imageIndex, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR);
                  if (lastY < (297-pdfImgHeightR)) {
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  } else {
                    pdf.addPage()
                    lastY = 30
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  }
                  
                  lastY += pdfImgHeightR + 10
                  //end tamano de la imagen 
                }
              }
              if (habImages.length > 0) {
                lastY = 30
                pdf.addPage()
                pdf.text(13, lastY, 'Habitáculo')
                for (var imageIndex = 0; imageIndex < habImages.length; imageIndex++) {
                  imageObject = habImages[imageIndex]
                  //tamano de la imagen 
                  let imgProps= pdf.getImageProperties(imageObject.uri)
                  let pdfImgWidth = imgProps.width 
                  let pdfImgHeight =  imgProps.height 
                  let pdfImgWidthR=0
                  let pdfImgHeightR=0
                  if (pdfImgWidth > pdfImgWidthIni) {
                    pdfImgWidthR = pdfImgWidthIni
                    pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                    pdfImgHeightR = Math.round(pdfImgHeightR);
                  }else{
                    pdfImgWidthR=pdfImgWidth
                    pdfImgHeightR=pdfImgHeight
                  } 
                  console.log('image original size:', pdfImgWidth, pdfImgHeight, 'image NEW size: ', pdfImgWidthR, pdfImgHeightR);
                  //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                  if (lastY < (297-pdfImgHeightR)) {
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  } else {
                    pdf.addPage()
                    lastY = 30
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  }
                  //lastY = (documentHeight / 2)
                  lastY += pdfImgHeightR + 10
                  //end tamano de la imagen 
                }
              }
              if (mechImages.length > 0) {
                lastY = 30
                pdf.addPage()
                pdf.text(13, lastY, 'Mecánica')
                for (var imageIndex = 0; imageIndex < mechImages.length; imageIndex++) {
                  imageObject = mechImages[imageIndex]
                  //tamano de la imagen 
                  let imgProps= pdf.getImageProperties(imageObject.uri)
                  let pdfImgWidth = imgProps.width 
                  let pdfImgHeight =  imgProps.height 
                  let pdfImgWidthR=0
                  let pdfImgHeightR=0
                  if (pdfImgWidth > pdfImgWidthIni) {
                    pdfImgWidthR = pdfImgWidthIni
                    pdfImgHeightR = (pdfImgHeight * pdfImgWidthR) / pdfImgWidth;
                    pdfImgHeightR = Math.round(pdfImgHeightR);
                  }else{
                    pdfImgWidthR=pdfImgWidth
                    pdfImgHeightR=pdfImgHeight
                  } 
                  console.log('image original size:', pdfImgWidth, pdfImgHeight, 'image NEW size: ', pdfImgWidthR, pdfImgHeightR);
                  //pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, documentWidth - 30, (documentHeight / 2) - 60)
                  if (lastY < (297-pdfImgHeightR)) {
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  } else {
                    pdf.addPage()
                    lastY = 30
                    pdf.addImage(imageObject.uri, 'JPEG', 15, lastY + 10, pdfImgWidthR, pdfImgHeightR)
                  }
                  //lastY = (documentHeight / 2)
                  lastY += pdfImgHeightR + 10
                  //end tamano de la imagen 
                }
              }
            }
          }
        }
    }

    // Exportar
    pdf.save(`solicitud_${id}_${moment().format('DD/MM/y')}.pdf`)
    return true
  } catch (e) {
    return false
  }  
} */
