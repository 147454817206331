import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountStore from '../../../store/user';
import UsersController from '../../../services/employees';
import { Loading, Empty } from '../../../components/splash';
export default class Employees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: null,
			page: 1,
			maxPages: 1,
			search: '',
			list: [],
			sortedKey: '',
			loading: false,
			redirect: false
		};
	}
	componentDidMount() {
		this.loadUsers(1);
		AccountStore.on('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	componentWillUnmount() {
		AccountStore.removeListener('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	toggleSidebar = () => {
		this.props.sceneRef.current.classList.toggle('open');
	};
	loadUsers = async (page) => {
		this.setState({ loading: true });
		try {
			const users = await UsersController.getAll(page);
			const list = users.result.data ? users.result.data : [];
			this.setState({
				loading: false,
				page: users.result.current_page,
				users: users.result.data,
				list: list,
				maxPages: users.result.last_page
			});
		} catch (e) {
			this.setState({ loading: false });
		}
	};
	handlePrevPage = async () => {
		let { page, search } = this.state;
		if (page > 1) {
			if (search.length > 0) {
				await this.handleSearch(null, page - 1);
			} else {
				await this.loadUsers(page - 1);
			}
		}
	};
	handleNextPage = async () => {
		let { page, maxPages, search } = this.state;
		if (page < maxPages) {
			if (search.length > 0) {
				await this.handleSearch(null, page + 1);
			} else {
				await this.loadUsers(page + 1);
			}
		}
	};
	handleSearch = async (e, page) => {
		if (e) {
			e.preventDefault();
		}
		this.setState({ searching: true });
		const { search } = this.state;
		try {
			if (search.length > 0) {
				const users = await UsersController.search(
					search,
					page ? page : 1
				);
				const list = users.result.data ? users.result.data : [];
				this.setState({
					list: list,
					page: users.result.current_page,
					maxPages: users.result.last_page,
					searching: false
				});
			} else {
				this.setState({ searching: false });
				this.loadUsers(1);
			}
		} catch (e) {
			this.setState({ searching: false, search: search });
		}
	};
	handleSort = (key) => {
		const { list, sortedKey } = this.state;
		if (list.length > 0) {
			let sorted = list.sort((a, b) => {
				if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
				if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
				return 0;
			});
			let newKey = key;
			if (sortedKey === key) {
				newKey = '';
				sorted = sorted.reverse();
			}
			this.setState({ list: sorted, sortedKey: newKey });
		} else {
			return;
		}
	};
	render() {
		const { redirect, list, loading, page, maxPages, searching } =
			this.state;
		if (redirect) {
			return <Redirect to="/" />;
		}
		return (
			<div className="scene contact">
				{!loading && list ? (
					<div className="container">
						<div className="content">
							<div className="header top">
								<button
									className="btn toggle"
									onClick={this.toggleSidebar}
								>
									<FontAwesomeIcon icon="bars" />
								</button>
								<div className="title-w-search">
									<form
										onSubmit={this.handleSearch}
										className="searchbar"
									>
										<input
											id="search"
											type="text"
											placeholder="Buscar por nombre, correo electrónico, celular, rfc, curp, empresa, área o puesto"
											onChange={this.handleChange}
										/>
										<button
											className="btn success"
											type="submit"
										>
											{searching ? (
												<FontAwesomeIcon
													icon="spinner"
													spin
												/>
											) : (
												<FontAwesomeIcon icon="search" />
											)}
										</button>
									</form>
									<h2 className="uppercase title">
										{'Personal'}
									</h2>
								</div>
							</div>
							{list.length > 0 ? (
								<div className="pagination flexbox mB-20">
									<div className="flex flexbox align-end">
										{`Página ${page} de ${maxPages}`}
									</div>
									<div className="flex flexbox justify-end">
										<button
											className="btn"
											disabled={page === 1}
											onClick={this.handlePrevPage}
										>
											<FontAwesomeIcon icon="chevron-left" />
										</button>
										<button
											className="btn"
											disabled={
												page === maxPages ||
												maxPages === 1
											}
											onClick={this.handleNextPage}
										>
											<FontAwesomeIcon icon="chevron-right" />
										</button>
									</div>
								</div>
							) : null}
							{list.length > 0 ? (
								<table className="bordered">
									<thead>
										<tr>
											<th>
												{'Nombre'}{' '}
												<button
													className="btn sort"
													onClick={() =>
														this.handleSort('name')
													}
												>
													<FontAwesomeIcon icon="sort" />
												</button>
											</th>
											<th>
												{'Apellidos'}{' '}
												<button
													className="btn sort"
													onClick={() =>
														this.handleSort(
															'familyname'
														)
													}
												>
													<FontAwesomeIcon icon="sort" />
												</button>
											</th>
											<th>
												{'Correo'}{' '}
												<button
													className="btn sort"
													onClick={() =>
														this.handleSort('email')
													}
												>
													<FontAwesomeIcon icon="sort" />
												</button>
											</th>
											<th>{'Empresa'}</th>
											<th>{'Área'}</th>
											<th>{'Puesto'}</th>
											<th>{'F. Alta'}</th>
											<th>
												<Link
													to={'/empleado/'}
													className="btn"
												>
													{'Agregar'}
												</Link>
											</th>
										</tr>
									</thead>
									<tbody>
										{list.map((item, key) => (
											<tr key={key}>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.name}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.familyname}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.email}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.company.name}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.area.name}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{item.position.name}
													</Link>
												</td>
												<td className="uppercase">
													<Link
														to={
															'/empleado/' +
															item.id
														}
													>
														{moment(
															item.created_at
														).format('DD/MMM/YY')}
													</Link>
												</td>
												<td className="action">
													<Link
														to={
															'/empleado/' +
															item.id
														}
														className="btn reference"
													>
														{'Ver más'}
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : null}
						</div>
					</div>
				) : loading ? (
					<Loading />
				) : (
					<Empty
						title="valuaciones"
						toggleSidebar={this.toggleSidebar}
						uri="/usuario/"
						label="Agregar usuario"
					/>
				)}
			</div>
		);
	}
}
