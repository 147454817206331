import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import AccountStore from '../../../store/user';
import DestinantionsServiceController from '../../../services/destinations';
export default class DestinationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ciudad: '',
      estado: '',
      loading: false,
      saving: false,
      redirect: false
    };
  }
  componentDidMount() {
    AccountStore.on('update_company', () => this.setState({ redirect: true }));
    const { params } = this.props.match;
    if (params) {
      if (params.id) {
        this.init(params.id);
        return;
      }
    }
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () =>
      this.setState({ redirect: true })
    );
  }
  init = async (id) => {
    this.setState({ loading: true });
    console.log(id);
    try {
      const destination = await DestinantionsServiceController.read(id);
      this.setState({
        id: id,
        ciudad: destination.ciudad,
        estado: destination.estado
      });
      console.log(destination);
      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle('open');
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.id) {
      this.handleUpdate(this.state.id);
    } else {
      this.handleCreate();
    }
  };
  handleCreate = async () => {
    try {
      this.setState({ saving: true });
      const { ciudad, estado } = this.state;
      const data = {
        ciudad: ciudad,
        estado: estado
      };
      const result = await DestinantionsServiceController.create(data);
      alert('¡Listo! El destino se creo correctamente.'); //no utilizar alerts
      this.setState({ id: result.id, saving: false });
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
      this.setState({ saving: false });
    }
  };
  handleUpdate = async (id) => {
    try {
      this.setState({ saving: true });
      const { id, ciudad, estado } = this.state;
      const data = {
        id: id.toString(),
        ciudad: ciudad,
        estado: estado
      };
      await DestinantionsServiceController.update(data);
      alert('¡Listo! El destino se actualizó correctamente.');
      this.setState({ saving: false });
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
      this.setState({ saving: false });
    }
  };
  render() {
    const { redirect, ciudad, estado, saving } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <Link to="/destinations" className="btn toggle">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <h2 className="uppercase title">Destino</h2>
            </div>
            <form>
              <div className="form-group">
                <div className="item">
                  <h3>Datos del destino</h3>
                  <div className="form-group">
                    <div className="item">
                      <label>Ciudad:</label>
                      <input
                        id="ciudad"
                        onChange={this.handleChange}
                        type="text"
                        value={ciudad}
                      />
                    </div>
                    <div className="item">
                      <label>Estado:</label>
                      <input
                        id="estado"
                        onChange={this.handleChange}
                        type="text"
                        value={estado}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flexbox mT-30">
              <button
                type="button"
                className="btn success"
                onClick={this.handleSubmit}
                disabled={saving}
              >
                {saving ? 'Guardando' : 'Guardar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
