import axios from 'axios'
import Session from '../store/session'
import * as actions from '../actions/auth'
import api from './api'
axios.interceptors.request.use(async (config) => {
  try {
    let token = Session.getToken()
    if (token) config.headers.Authorization = token
    return config
  } catch (error) {
    throw new Error(error)
  }
})
axios.interceptors.response.use((response) => {return response}, async (error) => {
  try {
    let errorObj = error.response.data
    let originalRequest = error.config
    if (error.response.status !== 500) {
      if (error.response.status === 401 && Session.authenticated) {
        if (errorObj.code === 'ExpiredToken') {
          try {
            let newToken = await api.request('get', '/token/refresh')
            Session.setToken(newToken.data.data)
            axios.defaults.headers.common['Authorization'] = newToken.data.data
            return axios(originalRequest)
          } catch (error) {
            actions.doLogin('LOGOUT', null)
            throw new Error(JSON.stringify(error.response.data))
          }
        } else {
          actions.doLogin('LOGOUT', null)
          throw new Error(JSON.stringify(error.response.data))
        }
      }
      if (errorObj.code && errorObj.message) {
        alert(errorObj.code + ' ' + errorObj.message)
        throw new Error(JSON.stringify(error.response.data))
      } else {
        console.log(error.response)
        alert('¡Vaya! No pudimos establecer conexión con el servidor.')
        throw new Error(JSON.stringify(error.response))
      }
    } else {
      console.log(error.response)
      alert('¡Vaya! No pudimos establecer conexión con el servidor.')
      throw new Error(JSON.stringify(error.response))
    }
  } catch (error) {
    throw new Error(error.message)
  }
})
