import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountStore from '../../../store/user';
import UsersController from '../../../services/vehicles';
import { Loading, Empty } from '../../../components/splash';
export default class Employees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: null,
			page: 1,
			search: '',
			filtered: [],
			sortedKey: '',
			loading: false,
			redirect: false
		};
	}
	componentDidMount() {
		this.loadUsers(1);
		AccountStore.on('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	componentWillUnmount() {
		AccountStore.removeListener('update_company', () =>
			this.setState({ redirect: true })
		);
	}
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	toggleSidebar = () => {
		this.props.sceneRef.current.classList.toggle('open');
	};
	loadUsers = async (page) => {
		this.setState({ loading: true });
		try {
			const users = await UsersController.getAll(page);
			this.setState({ loading: false, users: users.result.data });
		} catch (e) {
			this.setState({ loading: false });
		}
	};
	handleSearch = async (e) => {
		e.preventDefault();
		this.setState({ searching: true });
		const search = e.target.value;
		try {
			const { users } = this.state;
			if (!users) return;
			let filtered;
			if (search.length > 0) {
				filtered = users.filter((item) => {
					return (
						item.car_vin
							.toLowerCase()
							.indexOf(search.toLowerCase()) > -1 ||
						item.company.name
							.toLowerCase()
							.indexOf(search.toLowerCase()) > -1
					);
				});
			} else {
				filtered = [];
			}
			this.setState({
				filtered: filtered,
				search: search,
				searching: false
			});
		} catch (e) {
			this.setState({ searching: false, search: search });
		}
	};
	render() {
		const { users, redirect, loading, search } = this.state;
		if (redirect) {
			return <Redirect to="/" />;
		}
		const list = users ? users : [];
		return (
			<div className="scene contact">
				{!loading && list ? (
					<div className="container">
						<div className="content">
							<div className="header top">
								<button
									className="btn toggle"
									onClick={this.toggleSidebar}
								>
									<FontAwesomeIcon icon="bars" />
								</button>
								<h2 className="uppercase title">
									{'Vehículos'}
								</h2>
							</div>
							{list.length > 0 ? (
								<table className="bordered">
									<thead>
										<tr>
											<th>{'VIN'}</th>
											<th>{'Marca'}</th>
											<th>{'Modelo'}</th>
											<th>{'Año'}</th>
											<th>{'Agencia'}</th>
											<th>{'F. Alta'}</th>
											<th>
												<Link
													to={'/vehiculo/'}
													className="btn"
												>
													{'Agregar'}
												</Link>
											</th>
										</tr>
									</thead>
									<tbody>
										{list.map((item, key) => (
											<tr key={key}>
												<td>
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{item.car_vin}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{item.car_brand}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{item.car_line}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{item.car_model}
													</Link>
												</td>
												<td>
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{item.company.name}
													</Link>
												</td>
												<td className="uppercase">
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
													>
														{moment(
															item.created_at
														).format('DD/MMM/YY')}
													</Link>
												</td>
												<td className="action">
													<Link
														to={
															'/vehiculo/' +
															item.id
														}
														className="btn reference"
													>
														{'Ver más'}
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : search.length > 0 ? (
								<p style={{ textAlign: 'center' }}>
									No hay resultados para: {search}
								</p>
							) : null}
						</div>
					</div>
				) : loading ? (
					<Loading />
				) : (
					<Empty
						title="Veículos"
						toggleSidebar={this.toggleSidebar}
						uri="/vehiculo/"
						label="Agregar veículo"
					/>
				)}
			</div>
		);
	}
}
