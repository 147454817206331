import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UsersController from '../../../services/users'
import CheckButton from '../../../components/checkbutton'
import AccountStore from '../../../store/user'
export default class UserSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      familyname: "",
      email: "",
      mobile: "",
      company: "Invarat",
      role: 0,
      active: true,
      loading: false,
      saving: false,
      companies: ["Invarat"], // Listado de compañias a las que pertenece
      redirect: false,
      adjusters: [],
      adjustersList: []
    }
  }
  componentDidMount() {
    const { params } = this.props.match
    if (params) {
      if (params.id) this.init(params.id)
    }
    AccountStore.on('update_company', () => this.setState({ redirect: true }))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({ redirect: true }))
  }
  init = async (id) => {
    this.setState({ loading: true })
    try {
      const user = await UsersController.getById(id)
      /**
       * Si user.role == a 4 pedir listado de ajustadores
       */
      let adjusters = []
      if (parseInt(user.role) === 4) {
        await this.loadAdjusters(id)
        adjusters = user.adjusters.map(i => i.id)
      }
      const companies = user.companies.map(item => item.company.name)
      this.setState({
        id: id,
        name: user.name,
        familyname: user.familyname,
        email: user.email,
        mobile: user.phone,
        role: user.role,
        company: user.company,
        active: user.active,
        companies: companies,
        adjusters,
        loading: false
      })
    } catch (e) {
      console.log(e)
      this.setState({ loading: false })
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
    // console.log(e.target.value)
    console.log(e.target.id)
    if (e.target.id === 'role') {
      if (parseInt(e.target.value) === 4) {
        this.loadAdjusters(this.state.id)
      } else {
        this.clearAdjusters()
      }
    }
  }
  toggleCompany = (value) => {
    let array = [...this.state.companies]
    if (array.indexOf(value) !== -1) {
      if (array.length === 1) return
      array.splice(array.indexOf(value), 1)
    } else {
      array.push(value)
    }
    if (!array.includes('Siniestros') && [3, 4].includes(parseInt(this.state.role))) {
      // Si se quita siniestros y se tenia seteado el rol ajustador o coordinador setear rol a administrador
      this.setState({ role: 0 })
      // Limpiar listado de ajustadores
      this.clearAdjusters()
    }
    this.setState({ companies: array })
  }
  toggleAdjuster = (value) => {
    const array = [...this.state.adjusters]
    if (array.indexOf(value) !== -1) {
      // if (array.length === 1) return
      array.splice(array.indexOf(value), 1)
    } else {
      array.push(value)
    }
    this.setState({ adjusters: array })
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  loadAdjusters = async (id) => {
    try {
      /**
       * Enviar id del usuario actual
       * si es create traera solo usuarios no asignados a otro cordinador
       * si es update traera usuarios no asignados a otro cordinador + los asignados a el
       */
      const { result } = await UsersController.getAdjusters(id)
      this.setState({ adjustersList: result })
    } catch (e) {
      console.log(e)
    }
  }
  clearAdjusters = () => {
    this.setState({ adjustersList: [], adjusters: [] })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.id) {
      this.handleUpdate(this.state.id)
    } else {
      this.handleCreate()
    }
  }
  handleCreate = async () => {
    try {
      this.setState({ saving: true })
      const { name, familyname, email, mobile, role, company, companies, adjusters } = this.state
      const data = { name: name, familyname: familyname, email: email, phone: mobile, role: role, company: company, companies: companies, adjusters: adjusters }
      const result = await UsersController.create(data)
      alert("¡Listo! El usuario se creo correctamente.")
      this.setState({ id: result.id, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleUpdate = async (id) => {
    try {
      this.setState({ saving: true })
      const { id, name, familyname, email, mobile, role, company, companies, adjusters } = this.state
      const data = { id: id, name: name, familyname: familyname, email: email, phone: mobile, role: role, company: company, companies: companies, adjusters: adjusters }
      const result = await UsersController.update(data)
      console.log(result)
      alert("¡Listo! El usuario se actualizó correctamente.")
      this.setState({ saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleBlock = async () => {
    try {
      this.setState({ saving: true })
      const result = await UsersController.block(this.state.id)
      console.log(result)
      alert("¡Listo! El usuario se bloqueo correctamente.")
      this.setState({ active: false, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  handleUnblock = async () => {
    try {
      this.setState({ saving: true })
      const result = await UsersController.unblock(this.state.id)
      console.log(result)
      alert("¡Listo! El usuario se actualizó correctamente.")
      this.setState({ active: true, saving: false })
    } catch (e) {
      console.log(e)
      this.setState({ saving: false })
    }
  }
  render() {
    const { name, familyname, email, mobile, role, saving, companies, adjusters, adjustersList, redirect } = this.state
    // const adjustersSelectHeight = (adjustersList.length * 19 + 20) < 160 ? (adjustersList.length * 19 + 22) : 160
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button className="btn toggle" onClick={this.toggleSidebar}>
                <FontAwesomeIcon icon="bars" />
              </button>
              <Link to="/usuarios" className="btn toggle">
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
              <h2 className="uppercase title">{"Usuario"}</h2>
            </div>
            <form>
              <div className="form-group">
                <div className="item">
                  <h3>Datos del usuario</h3>
                  <div className="form-group">
                    <div className="item">
                      <label>Nombre:</label>
                      <input id="name" onChange={this.handleChange} type="text" value={name} />
                    </div>
                    <div className="item">
                      <label>Apellidos:</label>
                      <input id="familyname" onChange={this.handleChange} type="text" value={familyname} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Correo electrónico:</label>
                      <input id="email" onChange={this.handleChange} type="text" value={email} />
                    </div>
                    <div className="item">
                      <label>Teléfono celular:</label>
                      <input id="mobile" onChange={this.handleChange} type="text" value={mobile} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="item">
                      <label>Rol:</label>
                      <div className="select-wrapper">
                        <select id="role" value={role} onChange={this.handleChange}>
                          <option value={0}>Administrador</option>
                          <option value={1}>Supervisor</option>
                          <option value={2}>Técnico/Perito</option>
                          {
                            companies.includes('Siniestros') ?
                              <>
                                <option value={3}>Ajustador</option>
                                <option value={4}>Coordinador</option>
                              </> : null
                          }
                        </select>
                        <FontAwesomeIcon icon="chevron-down" />
                      </div>
                      {parseInt(role) === 4 ?
                        <div className="item mT-20 pY-8 item--bg-bordered">
                          <label><strong>Asignar ajustadores:</strong></label>
                          <div className="checkgroup checkgroup--capitalize checkgroup--justified flexbox f-wrap">
                            {
                              adjustersList.map(i => <CheckButton key={i.id} label={i.name.toLowerCase()} action={() => this.toggleAdjuster(i.id)} active={adjusters.indexOf(i.id) !== -1} />)
                            }
                          </div>
                        </div> : null
                      }
                    </div>
                    <div className="item">
                      <label>Empresas:</label>
                      <div className="checkgroup flexbox f-wrap col-4">
                        <CheckButton label="Invarat" action={() => this.toggleCompany("Invarat")} active={companies.indexOf("Invarat") !== -1} />
                        <CheckButton label="Garantiplus" action={() => this.toggleCompany("Garantiplus")} active={companies.indexOf("Garantiplus") !== -1} />
                        <CheckButton label="Seminuevos" action={() => this.toggleCompany("Seminuevos")} active={companies.indexOf("Seminuevos") !== -1} />
                        <CheckButton label="AUTOCOM Pool" action={() => this.toggleCompany("AUTOCOM Pool")} active={companies.indexOf("AUTOCOM Pool") !== -1} />
                        <CheckButton label="Siniestros" action={() => this.toggleCompany("Siniestros")} active={companies.indexOf("Siniestros") !== -1} />
                        <CheckButton label="Clicar" action={() => this.toggleCompany("Clicar")} active={companies.indexOf("Clicar") !== -1} />
                        <CheckButton label="Aseguradora 1" action={() => this.toggleCompany("Aseguradora 1")} active={companies.indexOf("Aseguradora 1") !== -1} />
                        <CheckButton label="Distribuidor 1" action={() => this.toggleCompany("Distribuidor 1")} active={companies.indexOf("Distribuidor 1") !== -1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flexbox">
              <button className="btn success" onClick={this.handleSubmit} disabled={saving}>{saving ? "Guardando" : "Guardar"}</button>
              {this.state.id && this.state.active ?
                <button className="btn danger" onClick={this.handleBlock} disabled={saving}>Bloquear</button>
                : null}
              {this.state.id && !this.state.active ?
                <button className="btn success" onClick={this.handleUnblock} disabled={saving}>Desbloquear</button>
                : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
