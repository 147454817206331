import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
export default function Grader({id, label, value, maxValue, onChange}) {
  function toggleChange(e, value) {
    e.preventDefault()
    onChange(id, value)
  }
  return (
    <div className="grader-wrapper">
      <div className="grade-item">
        <label>{label}:</label>
        <div className="grade-options">
          <button className={"option" + (value === maxValue ? " active" : "")} onClick={(e) => toggleChange(e, maxValue)}><FontAwesomeIcon icon="check" /></button>
          <button className={"option" + (value === 0 ? " active" : "")} onClick={(e) => toggleChange(e, 0)}><FontAwesomeIcon icon="times" /></button>
          <button className={"option" + (value === maxValue/2 ? " active" : "")} onClick={(e) => toggleChange(e, maxValue/2)}><FontAwesomeIcon icon="exclamation" /></button>
        </div>
      </div>
    </div>
  )
}
export function GraderHeader({label}) {
  function toggleChange(e) {
    e.preventDefault()
  }
  return (
    <div className="grader-wrapper">
      <div className="grade-item">
        <h5>{label}</h5>
        <div className="grade-options">
          <button className={"option active"} onClick={(e) => toggleChange(e)}><FontAwesomeIcon icon="check" /></button>
          <button className={"option active"} onClick={(e) => toggleChange(e)}><FontAwesomeIcon icon="times" /></button>
          <button className={"option active"} onClick={(e) => toggleChange(e)}><FontAwesomeIcon icon="exclamation" /></button>
        </div>
      </div>
    </div>
  )
}
