import React, {useState} from 'react'
import {isMobile} from "react-device-detect"
import Resizer from 'react-image-file-resizer'
import Camera, {FACING_MODES} from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import './ocr-box.scss'
import OCRServiceController from '../../services/ocr-service'
export default function OCRBox({active, onChange, toggle}) {
  const [cameraStarted, setCameraStarted] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [preview, setPreview] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = React.createRef();
  const handleReset = () => {
    setCameraStarted(false)
    setCameraError(false)
    setPreview(null)
    setPhoto(null)
    setShowCamera(false)
    setCompleted(false)
    setLoading(false)
  }
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 720, 720, 'JPEG', 72, 0,
      uri => {
        resolve(uri);
      }, 'base64'
    );
  })
  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  }
  const handleTakePhoto = (dataUri) => {
    setPreview(dataUri);
    setPhoto(dataURItoBlob(dataUri));
    setCompleted(true);
  }
  const handleFileInput = async (e) => {
    //alert("handleFileInput");
    const image = await resizeFile(e.target.files[0]);
    setPreview(image);
    setPhoto(dataURItoBlob(image));
    setCompleted(true);
  }
  const handleSubmit = async (e) => {
    //console.log(e);
    setLoading(true)
    var data = new FormData()
    //console.log("photo");
    //console.log(photo);
    data.append('file', photo)
    try {
      const result = await OCRServiceController.getVinFromCard(data)
      console.log('ocr result', result)
      onChange(result)
      toggle(e)
      handleReset()
    } catch (e) {
      alert(e.message)
      setLoading(false)
    }
  }
  return (
    <div className={`ocr-box ${active ? ' open' : ''}`}>
      <div className="container">
        <button className="toggle" onClick={toggle}>
          <span className="glyphicon glyphicon-remove"></span>
        </button>
        {showCamera && !completed ?
          <React.Fragment>
            {cameraError ?
              <div className="error">
                <h2>Ha ocurrido un error al cargar la cámara de tu dispositivo.</h2>
              </div>
            : null}
            {preview ?
              <div className="preview">
                <img src={preview} alt="Previsualización" />
              </div>
            : null}
            <Camera
              isFullscreen
              isImageMirror={false}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              onCameraStart={() => setCameraStarted(true)}
              onCameraStop={() => setCameraStarted(false)}
              onCameraError={() => setCameraError(true)}
              isDisplayStartCameraError={false}
              onTakePhoto = { (dataUri) => handleTakePhoto(dataUri) }
              />
          </React.Fragment>
        : (!completed ?
          <div className="start">
            <h2>Toma una foto clara y legible de tu tarjeta de circulación donde se encuentra el número de serie.</h2>
            {!isMobile ?
              <button className="btn primary" onClick={() => setShowCamera(true)}>
                <span className="glyphicon glyphicon-camera"></span> Tomar foto
              </button>
            :
              <div className="btn primary input">
                <span className="glyphicon glyphicon-camera"></span> Tomar foto
                <input type="file" accept="image/*" capture="environment" onChange={handleFileInput} />
              </div>
            }
            <div className="btn primary input">
              <span className="glyphicon glyphicon-folder-open"></span> Buscar foto
              <input ref={fileInputRef} type="file" onChange={handleFileInput} />
            </div>
            <button onClick={toggle} className="btn cancel">
              Cancelar
            </button>
          </div>
        :
          <div className="result">
            <h2>Toma una foto clara y legible de tu tarjeta de circulación donde se encuentra el número de serie.</h2>
            {preview ? <img src={preview} alt="Preview" /> : null}
            <button disabled={loading} className="btn primary mB-15" onClick={handleReset}>
              <span className="glyphicon glyphicon-repeat"></span> Cambiar foto
            </button>
            <button disabled={loading} className="btn primary mB-15" onClick={handleSubmit}>
              <span className={`glyphicon ${loading ? 'glyphicon-refresh gly-spin' : 'glyphicon-ok'}`}></span> {loading ? 'Procesando' : 'Procesar foto'}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
