import React from 'react'
import { Link } from 'react-router-dom'
import { validateJson } from '../../../helpers'
import moment from 'moment-timezone'

export default function collapsibleContent({ item, company, userRole, toggleStatus }) {
  const linkTo = company === 'Siniestros'
    ? "/tomadedanos/folio/" + item.id
    : "/valuacion/" + item.id
  return (
    <div>
      <ul className="Collapsible-table__list">
        <li>
          <span>ID:</span>
          <div>{item.id}</div>
        </li>
        {item.party ?
          <li>
            <span>Autor / Origen:</span>
            <div>{item.party.name}</div>
          </li>
          :
          <li>
            <span>Autor / Origen:</span>
            <div>
              {item.author && item.origin !== "website" ? `${item.author.name} ${item.author.familyname}` : <p className="tag">
              {item.origin === "website" ? "Sitio web" : "N/A"}</p>}
            </div>
          </li>
        }
        <li>
          <span>Agente:</span>
          <div>
            {item.responsable ? `${item.responsable.name} ${item.responsable.familyname}` : <p className="tag">{'S/A'}</p>}
          </div>
        </li>
        <li>
          <span>Cliente:</span>
          <div>{item.client.name}</div>
        </li>
        <li>
          <span>VIN:</span>
          <div>{item.vehicle ? item.vehicle.car_vin : <p className="tag">{'S/D'}</p>}</div>
        </li>
        <li>
          <span>Marca:</span>
          <div>{item.vehicle ? validateJson(item.vehicle.car_brand, 'name') : <p className="tag">{'S/D'}</p>}</div>
        </li>
        <li>
          <span>Modelo:</span>
          <div>{item.vehicle ? validateJson(item.vehicle.car_model, 0) : <p className="tag">{'S/D'}</p>}</div>
        </li>
        <li>
          <span>Estatus:</span>
          <div>{item.status_text}</div>
        </li>
        {
          company === "Invarat" ?
          <li>
            <span>Estado de pago:</span>
            {
              item.paid_at ?
                <div>
                  <p className="tag success">Pagado</p>
                </div>
                : (item.origin === "website" ?
                  <div>
                    <p className="tag">Pendiente</p>
                  </div>
                    : <div>N/A</div> )
            }
          </li>
          : null
        }
        <li>
          <span>F. Creación:</span>
          <div>{moment(item.created_at).format('DD/MMM/YY')}</div>
        </li>
      </ul>
      <Link to={linkTo} className="btn reference">{"Ver más"}</Link>
      {
        userRole === 2 ?
          <button className={item.status ? 'btn block mT-6 success' : 'btn block mT-6'} onClick={toggleStatus}>
            {
              !item.status
                ? 'Cerrar expediente'
                : 'Abrir expediente'
            }
          </button> : null
      }
    </div>
  )
}
