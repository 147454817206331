import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from 'moment';
import 'moment/locale/es';
import AccountStore from '../../../store/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssignationsController from '../../../services/assignations';
import { Loading, Empty } from '../../../components/splash';
export default class Assignations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      maxPages: 1,
      search: '',
      loading: false,
      filtering: false,
      searching: false,
      destroyingFilters: false,
      start_at_date: '',
      end_at_date: '',
      desired_status: '',
      company: AccountStore.getCompany()
    };
  }
  componentDidMount() {
    this.loadValuations(1);
    AccountStore.on('update_company', () =>
      this.setCompany(AccountStore.getCompany())
    );
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () =>
      this.setCompany(AccountStore.getCompany())
    );
  }
  setCompany = (company) => {
    this.setState({ data: [], page: 1, company: company });
    this.loadValuations(1);
  };
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle('open');
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleApplyFilters = (e) => {
    e.preventDefault();
    this.loadValuations(1);
  };
  loadValuations = async (page, query = null, ignore = false) => {
    let company = AccountStore.getCompany();
    if (!company) return;
    this.setState({ loading: true });
    try {
      let filters = {
        company: company
      };
      if (!ignore) {
        filters.query = query
          ? query
          : this.state.search
            ? this.state.search
            : null;
        filters.min_date = this.state.start_at_date
          ? this.state.start_at_date
          : null;
        filters.max_date = this.state.end_at_date
          ? this.state.end_at_date
          : null;
        filters.status = this.state.desired_status
          ? this.state.desired_status === 'Cerrada'
          : null;
      } else {
        filters.query = null;
        filters.min_date = null;
        filters.max_date = null;
        filters.status = null;
      }
      const request = await AssignationsController.getAll(page, filters);
      // console.log(request.result.data);
      this.setState({
        loading: false,
        data: request.result.data ? request.result.data : [],
        page: request.result.current_page,
        maxPages: request.result.last_page
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  handleNextPage = () => {
    const { page, maxPages } = this.state;
    if (page < maxPages) {
      this.loadValuations(page + 1);
    }
  };
  handlePrevPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.loadValuations(page - 1);
    }
  };
  handleSearch = async () => {
    this.setState({ searching: true });
    try {
      let query = this.state.search;
      await this.loadValuations(1, query);
      this.setState({ searching: false });
    } catch (e) {
      this.setState({ searching: false });
    }
  };
  handleSearchEnter = async (e) => {

    try {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.setState({ searching: true })
        let query = this.state.search
        await this.loadValuations(1, query)
        this.setState({ searching: false })
      }
    } catch (e) {
      this.setState({ searching: false })
    }
  }
  handleFilters = async () => {
    this.setState({ filtering: true });
    try {
      await this.loadValuations(1);
      this.setState({ filtering: false });
    } catch (e) {
      this.setState({ filtering: false });
    }
  };
  destroyFilters = async () => {
    this.setState({
      search: '',
      start_at_date: '',
      end_at_date: '',
      desired_status: '',
      destroyingFilters: true
    });
    try {
      await this.loadValuations(1, null, true);
      this.setState({ destroyingFilters: false });
    } catch (e) {
      this.setState({ destroyingFilters: false });
    }
  };
  render() {
    const {
      search,
      data,
      loading,
      company,
      searching,
      start_at_date,
      end_at_date,
      desired_status,
      filtering,
      destroyingFilters
    } = this.state;
    if (company !== 'AUTOCOM Pool' && company !== '') {
      return <Redirect to="/valuaciones" />;
    }
    return (
      <div className="scene contact">
        <div className="container">
          <div className="content">
            <div className="header top">
              <button
                className="btn toggle"
                onClick={this.toggleSidebar}
              >
                <FontAwesomeIcon icon="bars" />
              </button>
              <div className="title-w-search">
                <form className="searchbar">
                  <input
                    id="search"
                    type="text"
                    placeholder="Buscar por autor, agente, empleado, vin, marca, modelo, año..."
                    onKeyDown={this.handleSearchEnter}
                    onChange={this.handleChange}
                    value={search}
                  />
                  <button
                    className="btn success"
                    type="button"
                    onClick={this.handleSearch}
                  >
                    {searching ? (
                      <FontAwesomeIcon
                        icon="spinner"
                        spin
                      />
                    ) : (
                      <FontAwesomeIcon icon="search" />
                    )}
                  </button>
                </form>
                <h2 className="uppercase title">
                  {'Asignaciones'}
                </h2>
              </div>
            </div>
            <div className="flexbox">
              <form className="filters">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  className="uppercase"
                  selected={start_at_date}
                  placeholderText="Desde la fecha"
                  onChange={(date) =>
                    this.setState({
                      start_at_date: date
                    })
                  }
                  dateFormat={'dd/MMM/yyyy'}
                />
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  className="uppercase"
                  selected={end_at_date}
                  placeholderText="Hasta la fecha"
                  onChange={(date) =>
                    this.setState({ end_at_date: date })
                  }
                  dateFormat={'dd/MMM/yyyy'}
                />
                <div className="select-wrapper">
                  <select
                    id="desired_status"
                    value={desired_status}
                    onChange={this.handleChange}
                    placeholder="Estatus"
                  >
                    <option value="">Estatus</option>
                    <option value="Asignado">
                      Asignado
                    </option>
                    <option value="Cerrada">
                      Cerrada
                    </option>
                  </select>
                  <FontAwesomeIcon icon="chevron-down" />
                </div>
                <button
                  type="button"
                  onClick={this.handleFilters}
                  className="btn reference"
                >
                  {filtering ? (
                    <FontAwesomeIcon
                      icon="spinner"
                      spin
                    />
                  ) : (
                    <FontAwesomeIcon icon="filter" />
                  )}
                </button>
                <button
                  type="button"
                  onClick={this.destroyFilters}
                  className="btn danger"
                >
                  {destroyingFilters ? (
                    <FontAwesomeIcon
                      icon="spinner"
                      spin
                    />
                  ) : (
                    <FontAwesomeIcon icon="sync" />
                  )}
                </button>
              </form>
              {!loading && data ? (
                <div className="flex pagination">
                  <button
                    onClick={this.handlePrevPage}
                    className="btn"
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                  </button>
                  <div className="pages">
                    {`${this.state.page} / ${this.state.maxPages}`}
                  </div>
                  <button
                    onClick={this.handleNextPage}
                    className="btn"
                  >
                    <FontAwesomeIcon icon="chevron-right" />
                  </button>
                </div>
              ) : null}
            </div>
            {!loading && data ? (
              <React.Fragment>
                <table className="bordered">
                  <thead>
                    <tr>
                      <th>{'ID'}</th>
                      <th>{'Autor'}</th>
                      <th>{'Empleado'}</th>
                      <th>{'VIN'}</th>
                      <th>{'Marca'}</th>
                      <th>{'Línea'}</th>
                      <th>{'Modelo'}</th>
                      <th>{'Estado'}</th>
                      <th>{'F. Creación'}</th>
                      <th>
                        <Link
                          to={'/asignacion/'}
                          className="btn"
                        >
                          {'Crear'}
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ? data.map((item, key) => (
                        <tr key={key}>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {item?.id}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {item?.author ? (
                                `${item.author.name} ${item.author.familyname}`
                              ) : (
                                <p className="tag">
                                  {item.origin ===
                                    'website'
                                    ? 'Sitio web'
                                    : 'N/A'}
                                </p>
                              )}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {item
                                ?.employee
                                ?.name +
                                ' ' +
                                item
                                  ?.employee
                                  ?.familyname}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item.id
                              }
                            >
                              {
                                item
                                  ?.vehicle
                                  ?.car_vin
                              }
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {
                                item
                                  ?.vehicle
                                  ?.car_brand
                              }
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {
                                item
                                  ?.vehicle
                                  ?.car_line
                              }
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {
                                item
                                  ?.vehicle
                                  ?.car_model
                              }
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {
                                item?.status
                              }
                            </Link>
                          </td>
                          <td className="uppercase">
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                            >
                              {moment(
                                item?.created_at
                              ).format(
                                'DD/MMM/YY'
                              )}
                            </Link>
                          </td>
                          <td className="action">
                            <Link
                              to={
                                '/asignacion/' +
                                item?.id
                              }
                              className="btn reference"
                            >
                              Ver más
                            </Link>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </React.Fragment>
            ) : (loading ?
              <Loading />
              :
              <Empty
                title="asignaciones"
                toggleSidebar={this.toggleSidebar}
                uri="/asignacion/"
                label="Crear nueva"
                hideHeader
              />
            )}
          </div>
        </div>

      </div>
    );
  }
}
