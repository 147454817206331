import axios from 'axios'
import './interceptor'
import {api} from '../config'

const apiWrapper = {
  request(method, uri, data = null, options, type, newHeaders) {
    if (!method) {
      console.error('API function call requires method argument')
      return
    }
    if (!uri) {
      console.error('API function call requires uri argument')
      return
    }
    var headers = {
      'content-type': 'application/json',
      'x-requested-with': 'XMLHttpRequest'
    }
    if (newHeaders) {
      Object.assign(headers, newHeaders)
    }
    var url = uri.indexOf('http') > -1 ? uri : api + uri
    var req = {method, url, data, options, headers}
    if (type === 'blob') {
      req = {method, url, data, options, responseType: 'blob', headers}
    }
    return axios(req)
  },
  requestPdf(method, uri, data = null, options, type, newHeaders) {
    console.log("requestPdf");
    if (!method) {
      console.error('API function call requires method argument')
      return
    }
    if (!uri) {
      console.error('API function call requires uri argument')
      return
    }
    var headers = {
      'content-type': 'application/json',
      'x-requested-with': 'XMLHttpRequest'
    }
    if (newHeaders) {
      Object.assign(headers, newHeaders)
    }
    var url = uri.indexOf('http') > -1 ? uri : api + uri
    var req = {method, url, data, options, responseType: 'arraybuffer', headers}
    if (type === 'blob') {
      req = {method, url, data, options, responseType: 'blob', headers}
    }
    return axios(req)
  }
}
export default apiWrapper
