import React, { createRef, Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/sidebar';
import SessionController from '../../services/session';
import AccountStore from '../../store/user';
import Home from './scenes/home';
import User from './scenes/user';
import Users from './scenes/users';
import Assignations from './scenes/assignations';
import Assignation from './scenes/assignation';
import Valuations from './scenes/valuations';
import Valuation from './scenes/valuation';
import Damages from './scenes/damages';
import Damage from './scenes/damage';
import Intervention from './scenes/intervention';
import SelfIntervention from './scenes/selfIntervention';
import Employees from './scenes/employees';
import Employee from './scenes/employee';
import Vehicles from './scenes/vehicles';
import Vehicle from './scenes/vehicle';
import Coupons from './scenes/coupons';
import Coupon from './scenes/coupon';
import Destinations from './scenes/destinations';
import DestinationsModal from './scenes/destinationsModal';
export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			account: null
		};
		this.sceneRef = createRef();
	}
	componentDidMount() {
		AccountStore.on('update', () => this.updateUser());
		this.init();
	}
	componentWillUnmount() {
		AccountStore.removeListener('update', () => this.updateUser());
	}
	init = async () => {
		try {
			await SessionController.getDetails();
		} catch (e) {
			console.log(e);
		}
	};
	updateUser = () => {
		this.setState({ account: AccountStore.getUser() });
	};
	render() {
		const { account } = this.state;
		return (
			<Switch>
				<React.Fragment>
					<div ref={this.sceneRef} className="scene-wrapper">
						{account ? (
							<>
								<Sidebar
									account={account}
									sceneRef={this.sceneRef}
								/>
								<Route
									exact
									path={'/'}
									render={(props) => (
										<Home
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								{account.is_admin ? (
									<>
										<Route
											exact
											path={'/usuarios'}
											render={(props) => (
												<Users
													{...props}
													sceneRef={this.sceneRef}
												/>
											)}
										/>
										<Route
											exact
											path={'/usuario/:id?'}
											render={(props) => (
												<User
													{...props}
													sceneRef={this.sceneRef}
												/>
											)}
										/>
									</>
								) : null}
								<Route
									exact
									path={'/asignaciones'}
									render={(props) => (
										<Assignations
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/asignacion/:id?'}
									render={(props) => (
										<Assignation
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/valuaciones'}
									render={(props) => (
										<Valuations
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/valuacion/:id?'}
									render={(props) => (
										<Valuation
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/tomadedanos'}
									render={(props) => (
										<Damages
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/tomadedanos/folio/:id?'}
									render={(props) => (
										<Damage
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>

								{/* se agrega el parametro de cada empresa */}
								<Route
									exact
									path={
										'/intervencion/:category(revision|peritaje|acondicionamiento|tomadanos)/:id?'
									}
									render={(props) => (
										<Intervention
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/autoajuste/:id?'}
									render={(props) => (
										<SelfIntervention
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/vehiculos'}
									render={(props) => (
										<Vehicles
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/vehiculo/:id?'}
									render={(props) => (
										<Vehicle
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/empleados'}
									render={(props) => (
										<Employees
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/empleado/:id?'}
									render={(props) => (
										<Employee
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/cupones'}
									render={(props) => (
										<Coupons
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/cupon/:id?'}
									render={(props) => (
										<Coupon
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/destinations'}
									render={(props) => (
										<Destinations
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
								<Route
									exact
									path={'/destination/:id?'}
									render={(props) => (
										<DestinationsModal
											{...props}
											sceneRef={this.sceneRef}
										/>
									)}
								/>
							</>
						) : null}
					</div>
				</React.Fragment>
			</Switch>
		);
	}
}
