import React, {createRef, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Invarat from '../assets/images/logo.png'
import Pool from '../assets/images/autocom_pool.png'
import * as html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
export default function PDFGenerator({children, title, action, actionLabel, active, cancelAction}) {
  const document = createRef()
  const [creating, setCreating] = useState(false)
  async function handleCreate() {
    setCreating(true)
    const file = await generatePDF()
    setCreating(false)
    action(file)
  }
  async function generatePDF() {
    try {
      let inputOne = document.current
      var inputOneHeight = inputOne.clientHeight
      var inputOneWidth = inputOne.clientWidth
      const pdf = new jsPDF('p', 'px', 'a4')
      var documentWidth = pdf.internal.pageSize.getWidth()
      let pageOne = await html2canvas(inputOne)
      var newOneHeight = inputOneHeight / (inputOneWidth / (documentWidth - 65))
      pdf.addImage(Invarat, 'JPEG', 15, 15, 112, 35)
      pdf.addImage(Pool, 'JPEG', documentWidth - 112 - 15, 15, 112, 35)
      pdf.addImage(pageOne.toDataURL('image/png'), 'JPEG', 15, 65, documentWidth - 30, newOneHeight, 'one', 'FAST')
      var formData = new FormData();
      var blob = pdf.output('blob')
      formData.append('file', new File([blob], 'document.pdf'), {type: 'application/pdf'})
      return formData
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div className={`pdf-generator ${active ? 'active' : ''}`}>
      <div className="overlay" />
      <div className="pdf-generator-container">
        <div className="pdf-generator-header flexbox">
          <h2 className="flex mB-0">{title}</h2>
          <div className="flex flexbox justify-end align-center">
            <button className="btn danger" onClick={cancelAction}>
              <FontAwesomeIcon icon="times" /> Cancelar
            </button>
            <button className="btn success" onClick={handleCreate}>
              {creating ?
                <FontAwesomeIcon icon="spinner" spin />
              : actionLabel}
            </button>
          </div>
        </div>
        <div className="pdf-generator-document" ref={document}>
          {children}
        </div>
      </div>
    </div>
  )
}
